/* test */
@import url("https://respframework.verizon.com/fonts/fonts.css");
/* line 1, ../../scss/app/widgets/_header.scss */
.w_header_devices {
  display: none;
}

/* line 5, ../../scss/app/widgets/_header.scss */
.w_header_desktop {
  width: 100%;
  margin: auto;
  position: relative;
  background-color: #fff;
  /*text-align: center; responsive header*/
  /*border-bottom: 1px solid #BBBBBB;
  min-height: 124px; Responsive Header changes*/
}
/* line 14, ../../scss/app/widgets/_header.scss */
.w_header_desktop > img {
  cursor: pointer;
  height: 100%;
  width: auto;
}

/* line 21, ../../scss/app/widgets/_header.scss */
.main {
  max-width: 80rem;
  overflow-x: visible;
}
n
/* line 26, ../../scss/app/widgets/_header.scss */
.row {
  max-width: 80rem;
}

/* line 31, ../../scss/app/widgets/_header.scss */
.pagefooter img {
  width: 100%;
  height: 100%;
}

/* line 37, ../../scss/app/widgets/_header.scss */
.w_footer_desktop {
  width: 100%;
  margin: auto;
  height: auto;
  /*position: relative;*/
  background-color: #fff;
  text-align: center;
  border-top: 1px solid #BBB;
}

/* @media screen and (max-width: 638px) {
  line 48, ../../scss/app/widgets/_header.scss
  .w_header_desktop {
    display: none;
  } */

  /* line 52, ../../scss/app/widgets/_header.scss */
  .w_header_devices {
    display: block;
  }

  /* line 56, ../../scss/app/widgets/_header.scss */
  .w_footer_desktop {
    height: 12.5rem;
  }

/* line 3, ../../scss/app/widgets/_toolbar.scss */
.begincheckout .w_toolbar, .checkout .w_toolbar, .order-page .w_toolbar {
  border-left: 0px;
  border-right: 0px;
}

/* line 8, ../../scss/app/widgets/_toolbar.scss */
.w_toolbar {   /*updated the class for toolbar issue by dev v407346*/
  background-color: #fff;
 border-bottom:1px solid #ccc;
  width: 100%;
  height: 3.75rem;
} 

a.disable-href { /* to disable the href redirection of anchor tag*/
  pointer-events: none;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  /* line 8, ../../scss/app/widgets/_toolbar.scss */
  .w_toolbar {
    min-height: 2.75rem;
  }
}

/* line 20, ../../scss/app/widgets/_toolbar.scss */
.toolbar_fixed {
  max-width: 1280px !important;
  margin-left: 16.4%;
  top: 0px;
  position: fixed;
  z-index: 650;
  margin: 0 auto;
  left: 0;
  right: 0;
}
/* line 30, ../../scss/app/widgets/_toolbar.scss */
.toolbar_fixed .toolbar_count {
  left: 0.0625rem;
}

/* line 35, ../../scss/app/widgets/_toolbar.scss */
.w_header_devices {
  padding: 0 !important;
}
/* line 38, ../../scss/app/widgets/_toolbar.scss */
/* .w_header_devices .w_toolbar { */
  /*padding:0 !important;*/
 /* margin-top: 1.8125rem !important; Responsive header issue */
/* } */

.w_toolbar { height: 50px;}  /* Responsive header issue */

/* line 44, ../../scss/app/widgets/_toolbar.scss 
removed for responsive headers
.page-top {
  margin-top: 6.875rem !important;
}
*/
/* line 49, ../../scss/app/widgets/_toolbar.scss */
.toolbar_fixed .w_cart-box {
  position: absolute !important;
  /*right: 16.4%;*/
  /* -webkit-backface-visibility: hidden;
  -webkit-transform: translate3d(0); */
}
@media screen and (min-width: 1024px) {
  /* line 49, ../../scss/app/widgets/_toolbar.scss */
  .toolbar_fixed .w_cart-box {
    right: 0;
  }
}
/* line 59, ../../scss/app/widgets/_toolbar.scss */
.toolbar_fixed .w_cart-box .cart-box_button {
  /* padding-left: 0 !important; Responsive headers fix */
  padding-right: 1.75rem !important;
}
/* line 64, ../../scss/app/widgets/_toolbar.scss */
.toolbar_fixed .w_cart-box .cart-box_product-title, .toolbar_fixed .w_cart-box .cart-box_quantity, .toolbar_fixed .w_cart-box .cart-box_price {
  text-align: left;
}

/* line 71, ../../scss/app/widgets/_toolbar.scss */
.toolbar_fixed .toolbar_sub-item {
  position: fixed !important;
  text-align: left !important;
  display: none;
}
/* line 77, ../../scss/app/widgets/_toolbar.scss */
.toolbar_fixed .cart-box_empty {
  text-align: left;
}

/* line 83, ../../scss/app/widgets/_toolbar.scss */
.toolbar_filter {
  float: left;
  margin-left: 0.9375rem;
}

/* line 88, ../../scss/app/widgets/_toolbar.scss */
.toolbar_items {
  margin: 0;
  padding: 0;
}
/* line 92, ../../scss/app/widgets/_toolbar.scss */
.toolbar_items > li {
  list-style: none;
  float: left;
  font-size: 0.75rem;
  text-transform: uppercase;
  line-height: 2.3125rem;
  cursor: pointer !important;
  height: 3.125rem;
  /*&:hover {
      @media only screen and (min-width:48rem) {
          border-bottom: rem-calc(4) solid $brand-1;
      }
  }*/
}
@media only screen and (max-width: 638px) {
  /* line 92, ../../scss/app/widgets/_toolbar.scss */
  .toolbar_items > li {
    height: 3.75rem;
    line-height: 2rem;
  }
}
@media only screen and (min-width: 48rem) {
  /* line 92, ../../scss/app/widgets/_toolbar.scss */
  .toolbar_items > li {
    line-height: 2.8125rem;
  }
}
/* line 110, ../../scss/app/widgets/_toolbar.scss */
.toolbar_items > li a {
  color: #000;
}
/* line 114, ../../scss/app/widgets/_toolbar.scss */
.toolbar_items > li.active {
  border-bottom: 0.25rem solid #cd040b;
}
/* line 126, ../../scss/app/widgets/_toolbar.scss */
.toolbar_items .toolbar_home-link {
  font-size: 0.875rem;
  text-transform: none;
  line-height: 2.75rem;
}
/* line 132, ../../scss/app/widgets/_toolbar.scss */
.toolbar_items .toolbar_home-icon {
  cursor: pointer !important;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  padding-top: 0.625rem;
}
@media only screen and (max-width: 638px) {
  /* line 132, ../../scss/app/widgets/_toolbar.scss */
  .toolbar_items .toolbar_home-icon {
    padding-left: 0 !important;
  }
}
/* line 142, ../../scss/app/widgets/_toolbar.scss */
/* .toolbar_items .toolbar_link {
  /*line-height: rem-calc(42);*/
/* } */
@media only screen and (min-width: 48rem) {
  /* line 142, ../../scss/app/widgets/_toolbar.scss */
  .toolbar_items .toolbar_link {
    margin-left: 0.9375rem;
  }
}
/* line 148, ../../scss/app/widgets/_toolbar.scss */
.toolbar_items .toolbar_link .select-box_selected {
  line-height: inherit;
  border-bottom: 0 !important;
}
/* line 152, ../../scss/app/widgets/_toolbar.scss */
.toolbar_items .toolbar_link .select-box_selected + .select-box_options {
  width: 12.5rem !important;
}
@media only screen and (max-width: 638px) {
  /* line 157, ../../scss/app/widgets/_toolbar.scss */
  .toolbar_items .toolbar_link .w_select-box {
    margin-left: 0;
    padding-left: 0;
    padding-right:0;
    margin-top: 0;
    width: 8rem;
  }
}
@media only screen and (max-width: 638px) {
  /* line 166, ../../scss/app/widgets/_toolbar.scss */
  .toolbar_items .toolbar_link .w_select-box .select-box_selected span {
    width: 6.625rem !important;
  }
}
/* line 181, ../../scss/app/widgets/_toolbar.scss */
.toolbar_items .toolbar_link .toolbar_sub-item {
  position: relative;
  padding: 10px;
  top: 3.375rem;
  display: none;
  position: absolute;
  z-index: 2;
  background-color: #FFF;
  box-shadow: 1px 3px 8px 1px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 1px 3px 8px 1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 1px 3px 8px 1px rgba(0, 0, 0, 0.2);
  z-index: 10002 !important;
  margin-top: -2px;
}
/* line 196, ../../scss/app/widgets/_toolbar.scss */
.toolbar_items .toolbar_link .toolbar_sub-item ul {
  position: relative;
  min-width: 235px;
  float: left;
  list-style-type: none;
  background-color: #C3C3C3;
  margin: 0px;
}
/* line 205, ../../scss/app/widgets/_toolbar.scss */
.toolbar_items .toolbar_link .toolbar_sub-item li {
  float: none;
  margin: 0;
}
/* line 210, ../../scss/app/widgets/_toolbar.scss */
.toolbar_items .toolbar_link .toolbar_sub-item li a {
  display: block;
  margin-bottom: 1px;
  padding: 0px 12px;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 0.625rem;
}
/* line 219, ../../scss/app/widgets/_toolbar.scss */
.toolbar_items .toolbar_link .toolbar_sub-item li:hover {
  background-color: #595A5D;
}
/* line 223, ../../scss/app/widgets/_toolbar.scss */
.toolbar_items .toolbar_link .toolbar_sub-item li:hover a {
  color: #fff !important;
}
/* line 227, ../../scss/app/widgets/_toolbar.scss */
.toolbar_items .toolbar_link a span {
  position: relative;
  padding-left: 0.9375rem;
  top: 0.125rem;
}

/* line 236, ../../scss/app/widgets/_toolbar.scss */
.toolbar_support {
  float: right;
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  /* line 236, ../../scss/app/widgets/_toolbar.scss */
  .toolbar_support {
    margin-right: 0.9375rem;
  }
}
/* line 241, ../../scss/app/widgets/_toolbar.scss */
.toolbar_support .toolbar_support-items {
  margin: 0;
  padding: 0;
}
/* line 245, ../../scss/app/widgets/_toolbar.scss */
.toolbar_support .toolbar_support-items li {
  list-style: none;
  float: left;
  font-size: 0.75rem;
  padding-left: 0.625rem;
  line-height: 2.75rem;
}
/* line 252, ../../scss/app/widgets/_toolbar.scss */
.toolbar_support .toolbar_support-items li a {
  color: #cd040b;
}
/* line 256, ../../scss/app/widgets/_toolbar.scss */
.toolbar_support .toolbar_support-items li .toolbar_support-items-divider {
  /*padding-left:rem-calc(3);
  padding-right:rem-calc(3);*/
  border-left: 0.0625rem solid #bcbec0;
  height: 1.25rem;
  position: relative;
  top: 0.5rem;
  display: inline-block;
}
/* line 267, ../../scss/app/widgets/_toolbar.scss */
.toolbar_support .toolbar_support-items li:nth-child(2) {
  text-transform: none !important;
}
/* line 271, ../../scss/app/widgets/_toolbar.scss */
.toolbar_support .toolbar_support-items li:nth-child(3) {
  padding-left: 0.625rem;
}
/* line 276, ../../scss/app/widgets/_toolbar.scss */
.toolbar_support .toolbar_shipping-item {
  color: #cd040b;
  font-size: 0.875rem !important;
  line-height: 2.75rem !important;
  text-transform: none !important;
  padding-right: 3.125rem;
}

/* line 286, ../../scss/app/widgets/_toolbar.scss */
.toolbar_cart {
  float: right;
  margin-right: 0.9375rem;
  position: relative;
}
@media screen and (max-width: 767px) {
  /* line 286, ../../scss/app/widgets/_toolbar.scss */
  .toolbar_cart {
    margin-right: 0.9375rem;
  }
}
/* line 295, ../../scss/app/widgets/_toolbar.scss */
.toolbar_cart span {
  font-size: 0.75rem;
}
/* line 299, ../../scss/app/widgets/_toolbar.scss */
.toolbar_cart .cartbutton {
  margin-top: 0;
  cursor: pointer;
}
/* line 304, ../../scss/app/widgets/_toolbar.scss */
.toolbar_cart .icon-cart {
  position: relative;
  top: 0.5rem;
}

/* line 310, ../../scss/app/widgets/_toolbar.scss */
.toolbar_count-placeholder {
  width: 1.25rem;
  height: 1.25rem;
  background: #fff;
  display: inline-block;
  border: 0.1875rem solid #cd040b;
  border-radius: 0.625rem;
  position: absolute;
  top: -1.5rem;
  right: -0.5rem;
}

/* line 322, ../../scss/app/widgets/_toolbar.scss */
.toolbar_count-icon {
  position: absolute;
  top: 0;
  margin-left: -0.5rem;
  margin-top: -0.3125rem;
  float: right;
  right: 0px;
}

/* line 331, ../../scss/app/widgets/_toolbar.scss */
.toolbar_count {
  position: relative;
  color: #cd040b;
  font-weight: normal;
  font-size: 0.5625rem !important;
  top: 0.0625rem;
  left: 0.3125rem;
  /*@media screen and (max-width: 1024px) {
  left: rem-calc(-10);
  top:rem-calc(-3);
  }*/
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  /* line 331, ../../scss/app/widgets/_toolbar.scss */
  .toolbar_count {
    top: -0.125rem;
    left: -0.625rem;
  }
}
@media (max-width: 638px) {
  /* line 331, ../../scss/app/widgets/_toolbar.scss */
  .toolbar_count {
    left: 0;
  }
}

@media screen\0 {
  /* line 354, ../../scss/app/widgets/_toolbar.scss */
  .toolbar_count-icon {
    margin-left: -0.75rem;
  }
}
/* line 360, ../../scss/app/widgets/_toolbar.scss */
.toolbar_product {
  float: left;
  margin-left: 0.9375rem;
  font-size: 1.1875rem;
  line-height: 2.625rem;
}

/* line 371, ../../scss/app/widgets/_toolbar.scss */
.toolbar_sort-product {
  float: right;
  margin-right: 0.9375rem;
}
/* line 375, ../../scss/app/widgets/_toolbar.scss */
.toolbar_sort-product ul {
  margin: 0;
  padding: 0;
}
/* line 379, ../../scss/app/widgets/_toolbar.scss */
.toolbar_sort-product ul li {
  float: left;
  list-style: none;
  text-transform: uppercase;
  font-size: 0.75rem;
  padding-left: 1.875rem;
  line-height: 2.6875rem;
}
/* line 387, ../../scss/app/widgets/_toolbar.scss */
.toolbar_sort-product ul li a {
  color: #333;
  position: relative;
}
/* line 391, ../../scss/app/widgets/_toolbar.scss */
.toolbar_sort-product ul li a span {
  padding-left: 0.125rem;
  position: relative;
  top: 0.0625rem;
}
/* line 398, ../../scss/app/widgets/_toolbar.scss */
.toolbar_sort-product ul li a.selectlist {
  color: #cd040b;
  border-bottom: 2px solid #cd040b;
}
/* line 403, ../../scss/app/widgets/_toolbar.scss */
.toolbar_sort-product ul li a:after {
  display: block;
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 0;
  height: 2px;
  background-color: #cd040b;
  content: "";
  transition: width 0.2s;
}
/* line 415, ../../scss/app/widgets/_toolbar.scss */
.toolbar_sort-product ul li a:hover {
  color: #cd040b;
}
/* line 419, ../../scss/app/widgets/_toolbar.scss */
.toolbar_sort-product ul li a:hover:after {
  width: 2.8125rem;
}
/* line 425, ../../scss/app/widgets/_toolbar.scss */
.toolbar_sort-product .toolbar_select-label {
  position: absolute;
  margin-left: -3.875rem;
  font-size: 0.75rem;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 2.75rem;
  /*margin-top: rem-calc(32);*/
  margin-top: 1.5625rem;
  display: none;
}

/* line 438, ../../scss/app/widgets/_toolbar.scss */
.toolbar_select-box {
  /*background: url("../../images/select-singleDDarrow.png") no-repeat scroll right 10px bottom 10px #EDF1F2 !important;*/
  /*box-shadow: 0px 5px 4px 1px rgba(255, 255, 255, 0.5) inset, 0px -1px 1px 1px rgba(60, 60, 60, 0.1) inset;
      border: 1px solid #A7A9AB !important;*/
  /*background: url("../../images/downArrow.png") no-repeat scroll right 10px bottom 10px #EDF1F2 !important;*/
  /* background: url("../../images/dropdown.png") no-repeat scroll right 10px top 20px #fff !important; */
  box-shadow: 0px 5px 4px 1px rgba(255, 255, 255, 0.5) inset, 0px -1px 1px 1px rgba(60, 60, 60, 0.1) inset;
  border: 1px solid #CCC !important;
  border-radius: 3px;
  margin-top: 0 !important;
  padding-left: 0.3125rem !important;
  margin-bottom: 0.3125rem;
  /*padding-top: rem-calc(21);*/
  text-align: left;
  color: #333 !important;
  /*font-weight: bold;*/
  font-size: 0.875rem;
  cursor: pointer;
  display: none;
}

@media screen and (max-width: 768px) {
  /* line 461, ../../scss/app/widgets/_toolbar.scss */
  .toolbar_shipping-item {
    display: none;
  }
}
@media screen and (max-width: 638px) {
  /* line 467, ../../scss/app/widgets/_toolbar.scss */
  .w_toolbar {
    height: 3.75rem;
  }
  /* line 470, ../../scss/app/widgets/_toolbar.scss */
  .w_toolbar .toolbar_categories {
    width: 50%;
    padding-right: 0px;
  }
}
@media only screen and (max-width: 638px) and (max-width: 638px) {
  /* line 470, ../../scss/app/widgets/_toolbar.scss */
  .w_toolbar .toolbar_categories {
    width: 55%;
  }
}
@media screen and (max-width: 638px) {
  /* line 478, ../../scss/app/widgets/_toolbar.scss */
  .w_toolbar .toolbar_categories select.value {
    text-overflow: ellipsis;
  }
}
@media only screen and (max-width: 638px) and (max-width: 638px) {
  /* line 478, ../../scss/app/widgets/_toolbar.scss */
  .w_toolbar .toolbar_categories select.value {
    font-size: 0.875rem;
  }
}

@media screen and (max-width: 638px) {
  /* line 488, ../../scss/app/widgets/_toolbar.scss */
  .toolbar_custom-box {
    background-color: transparent !important;
    font-size: 0.75rem;
    border: none;
    background-image: none;
    padding: 0 0;
    position: relative;
    float: left;
  }

  /* line 498, ../../scss/app/widgets/_toolbar.scss */
  .toolbar_custom-boxicon::before {
    font-size: 13px;
    color: #000 !important;
    position: absolute;
    margin-top: 5px;
    margin-left: -8px;
  }

  /* line 506, ../../scss/app/widgets/_toolbar.scss */
  .toolbar_filter {
    margin-top: 0.9375rem;
  }

  /* line 510, ../../scss/app/widgets/_toolbar.scss */
  .toolbar_home-link, .toolbar_support {
    display: none;
  }

  /* line 514, ../../scss/app/widgets/_toolbar.scss */
  .toolbar_home-icon {
    display: block !important;
    padding-right: 0.3125rem !important;
    /*line-height: rem-calc(46) !important;*/
  }

  /* line 520, ../../scss/app/widgets/_toolbar.scss */
  .toolbar_product {
    margin-top: 0.8125rem;
  }

  /* line 524, ../../scss/app/widgets/_toolbar.scss */
  .animateborder {
    display: none;
  }

  /* line 530, ../../scss/app/widgets/_toolbar.scss */
  .toolbar_select-box {
    display: block !important;
  }

  /* line 534, ../../scss/app/widgets/_toolbar.scss */
  .toolbar_select-label {
    display: block !important;
  }

  /* line 538, ../../scss/app/widgets/_toolbar.scss */
  .toolbar_sub-item {
    top: 6.4375rem !important;
  }
  /* line 541, ../../scss/app/widgets/_toolbar.scss */
  .toolbar_sub-item li {
    text-align: left !important;
  }

  /* line 546, ../../scss/app/widgets/_toolbar.scss */
  .newheader_devices {
    max-height: 6.5rem !important;
    height: 6.5rem !important;
    padding: 0 !important;
  }
  /* line 551, ../../scss/app/widgets/_toolbar.scss */
  .newheader_devices .w_toolbar {
    /*padding:0 !important;*/
    margin-top: 1.8125rem !important;
  }

  /* line 559, ../../scss/app/widgets/_toolbar.scss */
  .page .m_toolbar {
    display: none !important;
  }
  /* line 564, ../../scss/app/widgets/_toolbar.scss */
  .page .w_toolbar .toolbar_select-box {
    margin-top: 0.4375rem !important;
  }
  /* line 572, ../../scss/app/widgets/_toolbar.scss */
  .page .custom-toolbar .toolbar_sub-item li {
    text-align: left !important;
  }
}
/* line 580, ../../scss/app/widgets/_toolbar.scss */
.toolbar_home-image {
  /* background: url(../../images/category-listitings.png) 0 -206px no-repeat; */
  width: 1.4375rem;
  height: 1.1875rem;
  display: inline-block;
  position: relative;
  top: 0.1875rem;
}
@media only screen and (min-width: 48rem) {
  /* line 580, ../../scss/app/widgets/_toolbar.scss */
  .toolbar_home-image {
    top: 0.4375rem;
  }
}

/* line 593, ../../scss/app/widgets/_toolbar.scss */
.toolbar_chat-icon {
  /* background: url(../../images/chat.png) no-repeat; */
  width: 1.5625rem;
  height: 1.25rem;
  display: inline-block;
}

/* line 600, ../../scss/app/widgets/_toolbar.scss */
.m_toolbar-chat {
  /*position: relative;
  top: rem-calc(8);
  margin-right: rem-calc(5);*/
  color: #cd040b !important;
  /*color: $hyperlink-active !important;*/
}

/* line 609, ../../scss/app/widgets/_toolbar.scss */
.toolbar_call-icon {
  /* background: url(../../images/call.png) no-repeat; */
  width: 1rem;
  height: 1.5rem;
  display: inline-block;
}

/* line 616, ../../scss/app/widgets/_toolbar.scss */
.m_toolbar-call {
  position: relative;
  top: -0.4375rem !important;
  color: #cd040b !important;
  /*color: $hyperlink-active !important;*/
}

/* line 623, ../../scss/app/widgets/_toolbar.scss */
.toolbar_custom-box {
  background-color: transparent !important;
  font-size: 0.75rem;
  border: none;
  background-image: none;
  padding-right: 0;
  padding-bottom: 0;
  position: relative;
  float: left;
  color: #333 !important;
}

/* line 635, ../../scss/app/widgets/_toolbar.scss */
.toolbar_custom-boxicon::before {
  font-size: 13px;
  color: #000 !important;
  position: absolute;
  margin-top: 4px;
  margin-left: -2px;
}

/*.toolbar_support-icons {
    position: relative;
    top: rem-calc(-2);
}*/
/* line 1, ../../scss/app/widgets/_TPbutton.scss */
.secondary-cancel {
  background: #9B9B9B !important;
  color: #FFF !important;
  height: 2.75rem;
  line-height: 1.3125rem;
  font-size: 1.1875rem;
  border-radius: 3px;
}
/* line 9, ../../scss/app/widgets/_TPbutton.scss */
.secondary-cancel:hover {
  background: #9B9B9B !important;
}

/* line 14, ../../scss/app/widgets/_TPbutton.scss */
.button.outofstock {
  cursor: default !important;
  background-color:#767676

}

/* line 18, ../../scss/app/widgets/_TPbutton.scss */
.cartbutton {
  height: 2.375rem;
  margin-top: 0.375rem;
  font-size: 0.875rem;
  font-weight: bold;
  padding-right: 0.875rem;
  margin-bottom: 0;
  padding-top:0.325rem;
}
@media only screen and (max-width: 47.9375rem) {
  /* line 18, ../../scss/app/widgets/_TPbutton.scss */
  .cartbutton {
    padding-right: 0.625rem;
  }
}

/* line 31, ../../scss/app/widgets/_TPbutton.scss */
.addcartbutton {
  width: 8.125rem;
  float: right;
  font-size: 1.0625rem;
  padding: 0.75rem 0px;
}

/* line 38, ../../scss/app/widgets/_TPbutton.scss */
.place-cart-button {
  width: 14.375rem;
  height: 2.75rem;
  clear: both;
  margin-bottom: 0 !important;
}

@media screen and (max-width: 638px) {
  /* line 51, ../../scss/app/widgets/_TPbutton.scss */
  .toolbar_count-icon {
    margin-top: 0.625rem !important;
  }
}
/* line 57, ../../scss/app/widgets/_TPbutton.scss */
.support button {
  height: 2.75rem;
}

@media (max-width: 638px) {
  /* line 64, ../../scss/app/widgets/_TPbutton.scss */
  .support_buttons .call-now {
    width: 100% !important;
  }
}
@media (max-width: 638px) {
  /* line 70, ../../scss/app/widgets/_TPbutton.scss */
  .support_buttons .chat-now {
    margin-bottom: 1.25rem !important;
    width: 100% !important;
  }
}
@media (max-width: 638px) {
  /* line 77, ../../scss/app/widgets/_TPbutton.scss */
  .support_buttons .view-cart-continue {
    width: 100% !important;
  }
}

/* line 85, ../../scss/app/widgets/_TPbutton.scss */
.support_ok .red-gradient {
  height: 2.75rem;
  font-size: 1.0625rem;
}

/* line 91, ../../scss/app/widgets/_TPbutton.scss */
.secondary-inactive {
  background: #F7F7F7;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  border: solid 1px #d2d3d4;
  width: auto;
  color: #d2d3d4;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  height: 2.75rem;
  font-size: 1.0625rem;
}
/* line 103, ../../scss/app/widgets/_TPbutton.scss */
.secondary-inactive:hover {
  background: #F7F7F7;
  color: #d2d3d4;
}
/* line 108, ../../scss/app/widgets/_TPbutton.scss */
.secondary-inactive:active {
  /* moz-box-shadow: none !important; */
  -webkit-box-shadow: none !important;
  -o-box-shadow: none !important;
  box-shadow: none !important;
}
/* line 115, ../../scss/app/widgets/_TPbutton.scss */
.secondary-inactive:focus {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  -o-box-shadow: none !important;
  box-shadow: none !important;
}

/* line 123, ../../scss/app/widgets/_TPbutton.scss */
.secondary-active {
  background: #fff !important;
  color: #cd040b !important;
  border: 0.0625rem solid #e4e5e3 !important;
  border-radius: 0.1875rem;
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
  width: auto !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  height: 2.75rem;
  line-height: 2.5rem !important;
}
/* line 136, ../../scss/app/widgets/_TPbutton.scss */
.secondary-active:hover {
  background: #fff !important;
  color: #cd040b !important;
}
/* line 141, ../../scss/app/widgets/_TPbutton.scss */
.secondary-active:active {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  -o-box-shadow: none !important;
  box-shadow: none !important;
}
/* line 148, ../../scss/app/widgets/_TPbutton.scss */
.secondary-active:focus {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  -o-box-shadow: none !important;
  box-shadow: none !important;
}

/*.inactivebtn[disabled]{ // Branding updates
    background-color:#D2D3D4 !important;
    color:#F6F4F4 !important;
}*/
/* Styles for button class in input[type='submit'] -  Added Based on Dev Request*/
/* line 162, ../../scss/app/widgets/_TPbutton.scss */
input.button {
  /* background-image: url(../../images/button-arrow.png); */
  background-position: 85% center;
  background-size: 13px 18px;
  background-color: #333333;
  color: #fff;
}
@media only screen and (max-width: 600px) {
  /* line 162, ../../scss/app/widgets/_TPbutton.scss */
  input.button {
    background-position: 88% center;
  }
}
@media only screen and (min-width: 601px) and (max-width: 637px) {
  /* line 162, ../../scss/app/widgets/_TPbutton.scss */
  input.button {
    background-position: 93% center;
  }
}
/* line 176, ../../scss/app/widgets/_TPbutton.scss */
input.button:focus:not([readonly]) {
  background-color: #cd040b;
  color: #fff;
}
/* line 181, ../../scss/app/widgets/_TPbutton.scss */
input.button.secondary {
  background-color: #333333;
}
/* line 184, ../../scss/app/widgets/_TPbutton.scss */
input.button.secondary:focus:not([readonly]) {
  background-color: #333333;
  color: #fff;
}

/* line 192, ../../scss/app/widgets/_TPbutton.scss */
.button:focus:not(.no-focus-effect), a.button:focus:not(.no-focus-effect) {
  box-shadow: none !important;
}

/* Styles for button class in input[type='submit'] -  Added Based on Dev Request*/
/* line 197, ../../scss/app/widgets/_TPbutton.scss */
.button.cta-text[disabled]:focus::after, .button.cta-text.disabled:focus::after {
  right: inherit !important;
}

/* line 200, ../../scss/app/widgets/_TPbutton.scss */
.button:focus:not(.no-focus-effect).link-text::after, .button:focus:not(.no-focus-effect).cta-text::after,
a.button:focus:not(.no-focus-effect).link-text::after,
a.button:focus:not(.no-focus-effect).cta-text::after {
  box-shadow: none !important;
}

/* line 2, ../../scss/app/widgets/_herobanner.scss */
.w_hero-banner {
  /*height: rem-calc(150);*/
  background-color: #e4e5e3;
  border-left: #cccccc;
  border-right: #cccccc;
  padding: 1.5625rem 1.25rem 1.5625rem 1rem;
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  /* line 2, ../../scss/app/widgets/_herobanner.scss */
  .w_hero-banner {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
  }
}

/* line 17, ../../scss/app/widgets/_herobanner.scss */
.hero-banner_title {
  font-size: 3.625rem;
  /*margin-left: rem-calc(34);*/
  float: left;
  color: #4b4b4b;
  /*padding-top:rem-calc(63);*/
}
@media only screen and (max-width: 638px) {
  /* line 17, ../../scss/app/widgets/_herobanner.scss */
  .hero-banner_title {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem;
  }
}

/* line 29, ../../scss/app/widgets/_herobanner.scss */
.hero-banner_categories-title {
  padding-top: 2.6875rem;
}

/* line 33, ../../scss/app/widgets/_herobanner.scss */
.hero-banner_details {
  float: right;
  margin-right: 1.5625rem;
  width: 26.375rem;
}

/* line 39, ../../scss/app/widgets/_herobanner.scss */
.hero-banner_shipping {
  display: block;
  float: right;
  font-size: 0.875rem;
  font-weight: bold;
  margin-bottom: 0.9375rem;
  clear: both;
  margin-top: 0.625rem;
}
/* line 48, ../../scss/app/widgets/_herobanner.scss */
.hero-banner_shipping .vzicon {
  position: relative;
  top: 0.3125rem;
  padding-right: 0.625rem;
}

/* line 55, ../../scss/app/widgets/_herobanner.scss */
.hero-banner_summary {
  display: block;
  float: left;
  font-size: 0.875rem;
  line-height: 1.1875rem;
}

@media screen and (max-width: 1024px) {
  /* line 64, ../../scss/app/widgets/_herobanner.scss */
  .hero-banner_title {
    font-size: 2.5rem;
    /*margin-left: rem-calc(15);*/
  }
}
@media screen and (max-width: 945px) {
  /* line 72, ../../scss/app/widgets/_herobanner.scss */
  .hero-banner_title {
    font-size: 1.875rem;
    /*margin-left: rem-calc(15);*/
  }
}
@media screen and (max-width: 725px) {
  /* line 82, ../../scss/app/widgets/_herobanner.scss */
  .hero-banner_title {
    font-size: 1.25rem;
    /* padding-top: 7.1875rem;  responsive headers issue fix by v407346*/
  }

  /* line 87, ../../scss/app/widgets/_herobanner.scss */
  .hero-banner_categories-title {
    padding-top: 5rem;
  }

  /* line 91, ../../scss/app/widgets/_herobanner.scss */
  .hero-banner_standard-title {
    font-size: 1rem;
  }
}
@media screen and (max-width: 638px) {
  /* line 97, ../../scss/app/widgets/_herobanner.scss */
  .w_hero-banner {
    height: 7.5rem;
    position: relative;
    padding:0 .5rem 1.5625rem .5rem;
    margin-top: 0;
  }

  /* line 102, ../../scss/app/widgets/_herobanner.scss */
  .hero-banner_title {
    /*position:absolute;
    bottom:rem-calc(15);
    margin-left: rem-calc(20) !important;*/
    font-size: 2rem;
    padding-top: 0;
  }

  /* line 110, ../../scss/app/widgets/_herobanner.scss */
  .hero-banner_summary {
    display: none;
  }

  /* line 114, ../../scss/app/widgets/_herobanner.scss */
  .hero-banner_details {
    position: absolute;
    width: auto;
    left: 0.9375rem;
    top: 0;
    margin-right: 2.875rem !important;
  }
  /* line 121, ../../scss/app/widgets/_herobanner.scss */
  .hero-banner_details span:nth-child(1) {
    position: absolute;
  }
}
/* line 127, ../../scss/app/widgets/_herobanner.scss */
.hero-banner_shipping-icon {
  /* background: url(../../images/category-listitings.png) 0 -120px no-repeat; */
  width: 1.625rem;
  height: 1.375rem;
  display: inline-block;
  margin-right: 0.625rem;
  /* float: left; */
}

/* line 136, ../../scss/app/widgets/_herobanner.scss */
.hero-banner_faq-icon, .faq-icon {
  /* background: url(../../images/category-listitings.png) 0 -295px no-repeat; */
  width: 1.875rem;
  height: 1.875rem;
  display: inline-block;
  margin-right: 0.625rem;
  /* float: left; */
}

/* line 145, ../../scss/app/widgets/_herobanner.scss */
.hero-banner_text {
  position: relative;
}
@media screen and (max-width: 360px) {
  /* line 145, ../../scss/app/widgets/_herobanner.scss */
  .hero-banner_text {
    line-height: 0.9375rem;
  }
}
@media screen and (max-width: 638px) {
  /* line 145, ../../scss/app/widgets/_herobanner.scss */
  .hero-banner_text {
    left: 2.5rem;
  }
}
@media screen and (max-width: 637px) {
  /* line 145, ../../scss/app/widgets/_herobanner.scss */
  .hero-banner_text {
    top: 0.0625rem;
  }
}

/* line 161, ../../scss/app/widgets/_herobanner.scss */
.hero-banner_faq {
  z-index: 100;
}
@media only screen and (min-width: 639px) and (max-width: 1023px) {
  /* line 161, ../../scss/app/widgets/_herobanner.scss */
  .hero-banner_faq {
    position: absolute;
    right: 1.5625rem;
  }
}
@media only screen and (min-width: 1024px) {
  /* line 161, ../../scss/app/widgets/_herobanner.scss */
  .hero-banner_faq {
    position: absolute;
    right: 0.3125rem;
  }
}

/* line 174, ../../scss/app/widgets/_herobanner.scss */
.hero-banner_description {
  padding-top: 0.75rem;
}
@media only screen and (max-width: 638px) {
  /* line 174, ../../scss/app/widgets/_herobanner.scss */
  .hero-banner_description {
    display: none;
  }
}

/* line 182, ../../scss/app/widgets/_herobanner.scss */
.hero-banner_sort {
  /*float: right;*/
  padding-right: 0.3125rem !important;
}
@media only screen and (max-width: 638px) {
  /* line 182, ../../scss/app/widgets/_herobanner.scss */
  .hero-banner_sort {
    float: none;
    clear: both;
  }
}

@media only screen and (max-width: 638px) {
  /* line 196, ../../scss/app/widgets/_herobanner.scss */
  .hero-banner_faq {
    margin-left: 0.9375rem;
  }
}
@media only screen and (min-width: 769px) {
  /* line 2, ../../scss/app/widgets/_tpgrid.scss */
  .accessories-result {
    width: 100%;
    float: left;
  }
}
@media only screen and (min-width: 639px) and (max-width: 768px) {
  /* line 14, ../../scss/app/widgets/_tpgrid.scss */
  .accessories-result .tpgrid_item {
    width: 46.543% !important;
    height: 30.9375rem !important;
  }
}
@media only screen and (min-width: 769px) and (orientation: portrait) {
  /* line 14, ../../scss/app/widgets/_tpgrid.scss */
  .accessories-result .tpgrid_item {
    width: 47% !important;
    height: 30.9375rem !important;
  }
}
@media only screen and (min-width: 769px) and (orientation: landscape) {
  /* line 14, ../../scss/app/widgets/_tpgrid.scss */
  .accessories-result .tpgrid_item {
    width: 31.7284% !important;
    height: 30.9375rem !important;
  }
}

/* line 32, ../../scss/app/widgets/_tpgrid.scss */
.w_tpgrid {
  float: left;
}

/* line 36, ../../scss/app/widgets/_tpgrid.scss */
.tpgrid_first-item {
  width: 40%;
  height: 31.25rem;
  border: solid 1px #cccccc;
  border-top: 0;
  -webkit-border: solid 1px #cccccc;
  -webkit-border-top: 0;
  /* float: left; */
  display: inline-block;
  cursor: pointer;
  position: relative;
}

/* line 49, ../../scss/app/widgets/_tpgrid.scss */
.tpgrid_item {
  width: 20%;
  height: 15.625rem;
  border: solid 1px #cccccc;
  -webkit-border: solid 1px #cccccc;
  border-left: 0;
  border-top: 0;
  -webkit-border-left: 0;
  -webkit-border-top: 0;
  /* float: left; */
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
  position: relative;
}

/* line 65, ../../scss/app/widgets/_tpgrid.scss */
.tpgrid_placeholder-first {
  width: 78.51563%;
  height: 66.8%;
  margin: 10% auto;
}

/* line 71, ../../scss/app/widgets/_tpgrid.scss */
.tpgrid_placeholder {
  width: 64.84375%;
  height: 64.84375%;
  margin: 5% auto;
  position: relative;
}

/* line 78, ../../scss/app/widgets/_tpgrid.scss */
.tpgrid_outofstock {
  width: 100%;
  background-color: #fff;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  font-size: 0.875rem;
  color: #000;
  top: 40%;
  text-align: center;
  position: absolute;
  line-height: 1;
  font-weight:700;
  opacity:.9;
  box-shadow: 1px 3px 8px 1px rgba(0, 0, 0, 0.1);
}

/* line 90, ../../scss/app/widgets/_tpgrid.scss */
.tpgrid_product {
  position: absolute;
  bottom: 0.625rem;
  left: 1.25rem;
  width: 245px;
 
}
.tpgrid_product-view {
  color:#000!important;
  font-weight:normal;
}

/* line 97, ../../scss/app/widgets/_tpgrid.scss */
.tpgrid_product-name {
  margin-bottom: 0.3125rem;
  line-height: 0.9375rem;
  font-size: 0.75rem;
}
/* line 102, ../../scss/app/widgets/_tpgrid.scss */
.tpgrid_product-name a {
  color: #333;
}

/* line 107, ../../scss/app/widgets/_tpgrid.scss */
.tpgrid_product-price {
  font-size: 0.75rem;
  margin-bottom: 0.3125rem;
  font-weight: bold;
}

/* line 113, ../../scss/app/widgets/_tpgrid.scss */
.tpgrid_sale-icon {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10001;
}

/* line 120, ../../scss/app/widgets/_tpgrid.scss */
.tpgrid_sale-text {
  font-weight: normal;
  text-decoration: line-through;
}

/* line 125, ../../scss/app/widgets/_tpgrid.scss */
.tpgrid_detail-box {
  position: absolute;
  box-shadow: 0px 0px 10px 4px rgba(119, 119, 119, 0.75);
  -moz-box-shadow: 0px 0px 10px 4px rgba(119, 119, 119, 0.75);
  -webkit-box-shadow: 0px 0px 10px 4px rgba(119, 119, 119, 0.75);
  z-index: 630;
  background-color: #fff;
}

/* line 134, ../../scss/app/widgets/_tpgrid.scss */
.tpgrid_placeholder-over {
  position: relative;
  z-index: 640;
}

/* line 139, ../../scss/app/widgets/_tpgrid.scss */
.tpgrid_box {
  position: absolute;
  bottom: 0.9375rem;
  width: 90%;
}
/* line 144, ../../scss/app/widgets/_tpgrid.scss */
.tpgrid_box hr {
  margin-bottom: 1.25rem !important;
}

/* line 149, ../../scss/app/widgets/_tpgrid.scss */
.tpgrid_name-price {
  z-index: 640;
  position: relative;
}
/* line 153, ../../scss/app/widgets/_tpgrid.scss */
.tpgrid_name-price .tpgrid_product-name a {
  cursor: pointer;
  color: #000000 !important;
  text-decoration: underline !important;
}

/* line 161, ../../scss/app/widgets/_tpgrid.scss */
.tpgrid_border-left {
  border-left: solid 1px #cccccc;
}
@media screen and (max-width: 1020px) {
  /* line 161, ../../scss/app/widgets/_tpgrid.scss */
  .tpgrid_border-left {
    border-left: 0px;
  }
}

/* line 169, ../../scss/app/widgets/_tpgrid.scss */
.tpgrid_border-top {
  border-top: solid 1px #cccccc;
}

@media screen and (max-width: 1020px) {
  /* line 175, ../../scss/app/widgets/_tpgrid.scss */
  .tpgrid_first-item {
    width: 33.33333% !important;
    height: 21.25rem !important;
  }

  /* line 180, ../../scss/app/widgets/_tpgrid.scss */
  .tpgrid_item {
    width: 33.33333% !important;
    height: 25rem !important;
  }

  /* line 185, ../../scss/app/widgets/_tpgrid.scss */
  .tpgrid_placeholder-first {
    width: 74.11765%;
    height: 13.125rem;
    padding-top: 0.9375rem;
        /*& img{

             width:rem-calc(165);
           height:rem-calc(132);
        }*/
  }

  /* line 196, ../../scss/app/widgets/_tpgrid.scss */
  .tpgrid_placeholder {
    width: 74.11765%;
    height: 13.125rem;
    padding-top: 1.875rem;
  }

  /* line 202, ../../scss/app/widgets/_tpgrid.scss */
  .tpgrid_item.clear {
    clear: none !important;
  }
}
@media screen and (max-width: 638px) {
  /* line 209, ../../scss/app/widgets/_tpgrid.scss */
  .page {
    margin-top: 2.75rem;
  }

  /* line 213, ../../scss/app/widgets/_tpgrid.scss */
  .tpgrid_first-item {
    width: 100% !important;
    height: 18.25rem !important;
  }

  /* line 218, ../../scss/app/widgets/_tpgrid.scss */
  .tpgrid_item {
    width: 100% !important;
    height: 18.25rem !important;
    border-left: solid 1px #bbb;
  }

  /* line 224, ../../scss/app/widgets/_tpgrid.scss */
  .tpgrid_placeholder-first {
    width: 54.85893%;
    height: 13.125rem;
    margin: 0;
    margin-left: 2.6875rem;
    margin-top: 0.5rem !important;
  }
  /* line 231, ../../scss/app/widgets/_tpgrid.scss */
  .tpgrid_placeholder-first img {
    height: 8.25rem;
  }

  /* line 236, ../../scss/app/widgets/_tpgrid.scss */
  .tpgrid_placeholder {
    width: 39.49843%;
    height: 13.125rem;
    margin: 0;
    margin-left: 2.6875rem;
    margin-top: 0 !important;
    padding-top: 0 !important;
  }
  /* line 244, ../../scss/app/widgets/_tpgrid.scss */
  .tpgrid_placeholder img {
    height: 8.25rem;
  }

  /* line 249, ../../scss/app/widgets/_tpgrid.scss */
  .tpgrid_item.clear {
    clear: none !important;
  }

  /* line 253, ../../scss/app/widgets/_tpgrid.scss */
  .tpgrid_product {
    left: 50%;
    top: 0px;
    margin-top: 38px;
    width: auto;
  }
}
@media screen and (max-width: 450px) {
  /* line 262, ../../scss/app/widgets/_tpgrid.scss */
  .tpgrid_first-item {
    width: 100% !important;
    height: 10rem !important;
  }

  /* line 267, ../../scss/app/widgets/_tpgrid.scss */
  .tpgrid_item {
    width: 100% !important;
    height: 11.25rem !important;
  }

  /* line 272, ../../scss/app/widgets/_tpgrid.scss */
  .tpgrid_placeholder-first {
    width: 41.25%;
    height: 6.875rem;
    margin: 0;
    margin-left: 0.9375rem;
    margin-top: 0.8125rem !important;
  }
  /* line 279, ../../scss/app/widgets/_tpgrid.scss */
  .tpgrid_placeholder-first img {
    height: 7.5rem;
  }

  /* line 284, ../../scss/app/widgets/_tpgrid.scss */
  .tpgrid_placeholder {
    width: 41.25%;
    height: 6.875rem;
    margin: 0;
    margin-left: 0.9375rem;
    margin-top: 1.375rem !important;
  }

  /* line 292, ../../scss/app/widgets/_tpgrid.scss */
  .tpgrid_item.clear {
    clear: none !important;
  }

  /* line 296, ../../scss/app/widgets/_tpgrid.scss */
  .tpgrid_product {
    left: 50%;
    top: 0px;
    margin-top: 22px;
  }
}
@media screen and (max-width: 360px) {
  /* line 304, ../../scss/app/widgets/_tpgrid.scss */
  .tpgrid_outofstock {
    top: 36%;
    font-size: 0.625rem;
  }
}
/* line 2, ../../scss/app/widgets/_cartbox.scss */
.w_cart-box {
  position: absolute;
  border: 1px solid #cccccc;
  width: 26.625rem;
  background-color: #fff;
  margin-top: 2.625rem;
  right: 0;
  padding: 0.75rem;
  z-index: 10002;
  box-shadow: 0px 1px 3px 1px rgba(119, 119, 119, 0.2);
  display: none;
}
@media screen and (max-width: 550px) {
  /* line 2, ../../scss/app/widgets/_cartbox.scss */
  .w_cart-box {
    width: 15.625rem;
  }
}

/* line 19, ../../scss/app/widgets/_cartbox.scss */
.cart-box_notify {
  background-color: #fff;
  display: inline-block;
  color: #fff;
  width: 100%;
  margin-top: 1.5625rem;
}

/* line 27, ../../scss/app/widgets/_cartbox.scss */
.cart-box_button {
  position: absolute;
  background: none repeat scroll 0% 0% #fff;
  border-width: 1px 1px 0px;
  border-style: solid;
  border-color: #cccccc;
  padding-top: 1rem;
  padding-left: 1.625rem;
  font-size: 0.9375rem;
  font-weight: bold;
  padding-right: 0.625rem;
  height: 2.5625rem;
  width: 7.6875rem;
  right: -0.0625rem;
  top: 0;
  margin-top: -2.625rem;
  z-index: 2;
  /* box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2), 0px 0px 1px #EAEAEA inset; */
  cursor: pointer;
}
.w_cart-box {
	z-index:1111;
}
#cartBtn {
	z-index:9999;
}
.w_cart-box #cartBtn:focus {
	box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2), 0px 0px 1px #EAEAEA inset;
}
/* line 47, ../../scss/app/widgets/_cartbox.scss */
.cart-box_button span {
  position: absolute;
  top: 14px;
}

/* line 53, ../../scss/app/widgets/_cartbox.scss */
.cart-box_mini-block {
  position: absolute;
  top: -0.25rem;
  right: 0;
  width: 7.5625rem;
  height: 0.5625rem;
  background: none repeat scroll 0% 0% #fff;
  z-index: 25;
}

/* line 63, ../../scss/app/widgets/_cartbox.scss */
.cart-box_icon {
  float: left;
  width: 15.87678%;
  background-color: #D8DADA;
  min-height: 6.125rem;
  padding: 10px 2px !important;
  text-align: center;
}
/* line 71, ../../scss/app/widgets/_cartbox.scss */
.cart-box_icon .vzicon:before {
  font-size: 2.8125rem;
}

/* line 76, ../../scss/app/widgets/_cartbox.scss */
.cart-box_shipping {
  float: left;
  width: 82.5%;
  /*margin-left:percentage(37px/422px);*/
  padding: 0.625rem 0px 0.625rem 0.625rem !important;
}
/* line 82, ../../scss/app/widgets/_cartbox.scss */
.cart-box_shipping p {
  font-size: 1rem;
  color: #4b4b4b;
  text-rendering: auto;
}
@media screen and (max-width: 550px) {
  /* line 82, ../../scss/app/widgets/_cartbox.scss */
  .cart-box_shipping p {
    padding-left: 1.5625rem;
    font-size: 0.875rem;
  }
}

/* line 95, ../../scss/app/widgets/_cartbox.scss */
.cart-box_product ul {
  margin: 0;
  padding: 0;
  position: relative;
}
/* line 101, ../../scss/app/widgets/_cartbox.scss */
.cart-box_product li {
  list-style: none;
  display: block;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  height: auto;
  clear: both;
  float: left;
  width: 100%;
}
/* line 112, ../../scss/app/widgets/_cartbox.scss */
.cart-box_product li:not(:nth-child(1)) {
  border-top: solid 1px #bbb;
}
/* line 116, ../../scss/app/widgets/_cartbox.scss */
.cart-box_product li:last-child {
  border-bottom: solid 1px #bbb;
}

@media (max-width: 1024px) {
  /* line 122, ../../scss/app/widgets/_cartbox.scss */
  .m_cart-box_product li {
    width: 100% !important;
    border-right: 0 !important;
    padding-left: 0 !important;
  }
}

/* line 131, ../../scss/app/widgets/_cartbox.scss */
.cart-box_product-title {
  font-size: 1.5rem;
  float: left;
  width: 100%;
  border-bottom: solid 1px #bbb;
  padding-bottom: 0.625rem;
  font-weight: bold;
}

/* line 140, ../../scss/app/widgets/_cartbox.scss */
.cart-box_product-lists {
  height: auto;
  max-height: 312px;
  overflow-y: hidden; 
  display: inline-block;
  width: 100%;
}

/* line 147, ../../scss/app/widgets/_cartbox.scss */
.cart-box_block {
  float: left;
}
/* line 150, ../../scss/app/widgets/_cartbox.scss */
.cart-box_block img {
  width: 4rem;
  height: 4rem;
 /*@media screen and (-webkit-min-device-pixel-ratio:0) {
height:auto;
}*/
}

/* line 159, ../../scss/app/widgets/_cartbox.scss */
.cart-box_product-name {
  float: left;
  margin-left: 0.9375rem;
}
/* line 167, ../../scss/app/widgets/_cartbox.scss */
.cart-box_product-name ul {
  margin: 0;
  padding: 0;
  position: relative;
}
/* line 173, ../../scss/app/widgets/_cartbox.scss */
.cart-box_product-name li {
  list-style: none;
  display: block;
}
/* line 178, ../../scss/app/widgets/_cartbox.scss */
.cart-box_product-name .cart-box_name {
  padding-top: 0;
  padding-bottom: 0;
  border-top: none !important;
  border-bottom: none !important;
  text-align: left;
}

.cart-box_product-name .cart-box_name a.disp_prod_link, .cart-box_product-name .cart-box_name a.disp_prod_link:hover, .cart-box_product-name .cart-box_name a.disp_prod_link:focus{
  color:#000 !important;
  text-decoration: underline;
}
/* line 189, ../../scss/app/widgets/_cartbox.scss */
.cart-box_product-name .cart-box_quantity, .cart-box_product-name .cart-box_sale {
  font-weight: bold;
  color: #4b4b4b;
  font-weight: bold;
  padding-top: 0;
  border-top: none !important;
  border-bottom: none !important;
}
/* line 198, ../../scss/app/widgets/_cartbox.scss */
.cart-box_product-name .cart-box_quantity {
  padding-bottom: 0.3125rem !important;
}
/* line 202, ../../scss/app/widgets/_cartbox.scss */
.cart-box_product-name .cart-box_sale {
  padding-bottom: 0 !important;
  color: #cd040b;
}
/* line 206, ../../scss/app/widgets/_cartbox.scss */
/* .cart-box_product-name .cart-box_sale .vzicon:before { */
  /*top: 0.3125rem; fix for sale image */
/* } */

/* line 212, ../../scss/app/widgets/_cartbox.scss */
.cart-box_product-price {
  float: right;
  text-align: right;
  font-weight: bold;
}
.cart-box_product-Edit a{
  color:#000 !important;
  font-weight:normal !important;
  text-decoration: underline !important;;
}
@media screen and (max-width: 647px) {
  /* line 212, ../../scss/app/widgets/_cartbox.scss */
  .cart-box_product-price {
    text-transform: uppercase;
  }
}

/* line 221, ../../scss/app/widgets/_cartbox.scss */
.cart-box_product-edit {
  float: right;
  padding-top: 1.25rem;
}

/* line 227, ../../scss/app/widgets/_cartbox.scss */
.cart-box_placeholder .cart-box_next-item {
  float: left;
  font-size: 0.75rem;
  margin-top: 1.25rem;
}
/* line 232, ../../scss/app/widgets/_cartbox.scss */
.cart-box_placeholder .cart-box_next-item .next {
  opacity: 0.5;
  cursor: default;
}
/* line 238, ../../scss/app/widgets/_cartbox.scss */
.cart-box_placeholder .cart-box_next-list {
  float: left;
  margin-top: 1.25rem;
}
/* line 243, ../../scss/app/widgets/_cartbox.scss */
.cart-box_placeholder .cart-box_total {
  float: right;
  font-weight: bold;
  font-size: 1.3125rem;
  margin-top: 0.625rem;
  min-width: 10.625rem;
  text-align: left;
}
/* line 251, ../../scss/app/widgets/_cartbox.scss */
.cart-box_placeholder .cart-box_total .cart-box_sub-total {
  font-weight: normal;
  font-size: 1rem;
}
/* line 257, ../../scss/app/widgets/_cartbox.scss */
.cart-box_placeholder .button {
  margin-top: 0.3125rem;
  margin-bottom: 0;
  width: 10rem !important;
  height: 2.75rem;
  font-size: 1.0625rem;
  text-align: center;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
/* line 268, ../../scss/app/widgets/_cartbox.scss */
.cart-box_placeholder .button:first-child {
  margin-right: 0.625rem;
}

@media screen and (max-width: 767px) {
  /* line 274, ../../scss/app/widgets/_cartbox.scss */
  .w_cart-box {
    display: none !important;
  }
}
/* line 279, ../../scss/app/widgets/_cartbox.scss */
.cart-box_notify .tp_truck-icon {
  background-position: 5px -230px;
  margin-top: 0.75rem;
  width: 3.125rem;
  height: 3.125rem;
}

/* line 288, ../../scss/app/widgets/_cartbox.scss */
.custom-carousel .tpgrid_detail-box {
  height: 490px !important;
}
/* line 292, ../../scss/app/widgets/_cartbox.scss */
.custom-carousel .carousel_pager-item {
  display: inline-block;
  margin: 0px 0.375rem;
  cursor: pointer;
  width: 0.75rem !important;
  height: 0.75rem !important;
  background: #CCC !important;
  border-radius: 99px !important;
  transition: all 0.3s ease 0s;
}

/* line 1, ../../scss/app/widgets/_carttable.scss */
.view-cart_table {
  width: 72%;
  float: left;
  background: #fff;
  border-right: 0.0625rem solid #cccccc;
}
@media (min-width: 767px) and (max-width: 963px) {
  /* line 1, ../../scss/app/widgets/_carttable.scss */
  .view-cart_table {
    width: 100%;
    clear: both;
  }
}
@media only screen and (max-width: 640px) {
  /* line 1, ../../scss/app/widgets/_carttable.scss */
  .view-cart_table {
    width: 100%;
    clear: both;
  }
}

/* line 19, ../../scss/app/widgets/_carttable.scss */
.view-cart_table-header {
  border-bottom: 0.0625rem solid #cccccc;
  background: #fff;
  height: 3.375rem;
  overflow: hidden;
}
@media only screen and (min-width: 60.625rem) {
  /* line 19, ../../scss/app/widgets/_carttable.scss */
  .view-cart_table-header {
    height: 4.4375rem;
  }
}
/* line 30, ../../scss/app/widgets/_carttable.scss */
.view-cart_table-header .view-cart_column {
  /*padding-top:rem-calc(30);
   padding-bottom:rem-calc(10);*/
  line-height: 4.0625rem !important;
  font-size: 0.75rem;
  font-weight: bold;
}
@media only screen and (min-width: 60.625rem) {
  /* line 30, ../../scss/app/widgets/_carttable.scss */
  .view-cart_table-header .view-cart_column {
    font-size: 1rem !important;
  }
}
/* line 42, ../../scss/app/widgets/_carttable.scss */
.view-cart_table-header .view-cart_column:nth-child(2) {
  border: 0;
  font-size: 0.75rem;
}
@media (max-width: 638px) {
  /* line 19, ../../scss/app/widgets/_carttable.scss */
  .view-cart_table-header {
    display: none;
  }
}

/* line 53, ../../scss/app/widgets/_carttable.scss */
.view-cart_column {
  float: left;
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  -ms-transition: none;
  transition: none;
}

/* line 62, ../../scss/app/widgets/_carttable.scss */
.view-cart_column:nth-child(1) {
  width: 54.6875%;
  padding-left: 0.9375rem;
}
@media (max-width: 768px) {
  /* line 62, ../../scss/app/widgets/_carttable.scss */
  .view-cart_column:nth-child(1) {
    width: 55.72917%;
  }
}
@media (max-width: 638px) {
  /* line 62, ../../scss/app/widgets/_carttable.scss */
  .view-cart_column:nth-child(1) {
    width: 100%;
    clear: both;
  }
}

/* line 76, ../../scss/app/widgets/_carttable.scss */
.view-cart_column:nth-child(2) {
  width: 23.95833%;
  padding-left: 0.9375rem;
  border-left: 0.0625rem solid #cccccc;
  border-right: 0.0625rem solid #cccccc;
  font-size: 0.875rem;
}
@media (max-width: 768px) {
  /* line 76, ../../scss/app/widgets/_carttable.scss */
  .view-cart_column:nth-child(2) {
    width: 24.73958%;
  }
}
@media (max-width: 638px) {
  /* line 76, ../../scss/app/widgets/_carttable.scss */
  .view-cart_column:nth-child(2) {
    width: 48.66562%;
    float: left;
    border: 0;
    margin-top: 0.9375rem;
  }
}

/* line 94, ../../scss/app/widgets/_carttable.scss */
.view-cart_column:nth-child(3) {
  width: 21.35417%;
  padding-left: 1.5625rem;
  padding-right: 1.5625rem;
  font-weight: bold;
}
@media (max-width: 768px) {
  /* line 94, ../../scss/app/widgets/_carttable.scss */
  .view-cart_column:nth-child(3) {
    width: 15.625%;
  }
}
@media (max-width: 638px) {
  /* line 94, ../../scss/app/widgets/_carttable.scss */
  .view-cart_column:nth-child(3) {
    width: 47.09576%;
    float: left;
    margin-top: 0.3125rem;
  }
}

/* line 111, ../../scss/app/widgets/_carttable.scss */
.view-cart_product-image {
  width: 6.125rem;
  height: 6rem;
  float: left;
  position: relative;
}
@media (max-width: 768px) {
  /* line 111, ../../scss/app/widgets/_carttable.scss */
  .view-cart_product-image {
    width: 7.625rem;
    height: 8.5625rem;
  }
}
@media (max-width: 638px) {
  /* line 111, ../../scss/app/widgets/_carttable.scss */
  .view-cart_product-image {
    width: 3.6875rem;
    height: 3.5625rem;
  }
}
/* line 127, ../../scss/app/widgets/_carttable.scss */
.view-cart_product-image img {
  width: 100%;
  height: 100%;
}

/* line 133, ../../scss/app/widgets/_carttable.scss */
.view-cart_product-text {
  width: 76.19048%;
  float: left;
  padding-left: 2.5rem;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.3125rem !important;
}
@media (min-width: 639px) and (max-width: 767px) {
  /* line 133, ../../scss/app/widgets/_carttable.scss */
  .view-cart_product-text {
    width: 55.2381%;
    padding-left: 0.9375rem;
  }
}
@media (min-width: 768px) {
  /* line 133, ../../scss/app/widgets/_carttable.scss */
  .view-cart_product-text {
    width: 61.90476%;
    padding-left: 0.9375rem;
  }
}
@media (max-width: 638px) {
  /* line 133, ../../scss/app/widgets/_carttable.scss */
  .view-cart_product-text {
    width: 78.49294%;
    padding-left: 0.9375rem;
    line-height: 1.3125rem !important;
  }
}

/* line 158, ../../scss/app/widgets/_carttable.scss */
.view-cart_quantity {
  width: 35.65217%;
  float: left;
  margin-left: 0.625rem !important;
  display: block;
  margin-top: 0;
  padding:0rem 1.5rem 0rem .625rem;
  height:2.25rem;
  border:none;
  border-bottom:1px solid #ccc!important;
}
@media (min-width: 638px) and (max-width: 638px) {
  /* line 158, ../../scss/app/widgets/_carttable.scss */
  .view-cart_quantity {
    width: 27.41935% !important;
  }
}
@media (max-width: 768px) {
  /* line 158, ../../scss/app/widgets/_carttable.scss */
  .view-cart_quantity {
    width: 43.15789%;
  }
}

/* line 174, ../../scss/app/widgets/_carttable.scss */
.view-cart_remove {
  display: block;
  padding-top: 3.6875rem;
  font-size: 0.875rem;
  clear: both;
  color: #000;
  text-decoration: underline;
}
@media (min-width: 639px) and (max-width: 767px) {
  /* line 174, ../../scss/app/widgets/_carttable.scss */
  .view-cart_remove {
    padding-top: 4.375rem;
  }
}
@media (max-width: 638px) {
  /* line 174, ../../scss/app/widgets/_carttable.scss */
  .view-cart_remove {
    right: 1.25rem;
    padding-top: 0.625rem;
  }
}

/* line 190, ../../scss/app/widgets/_carttable.scss */
.view-cart_remove-item {
  display: block;
}
/* line 193, ../../scss/app/widgets/_carttable.scss */
.view-cart_remove-item p {
  font-size: 0.875rem;
  padding-right: 0.9375rem !important;
}
/* line 198, ../../scss/app/widgets/_carttable.scss */
.view-cart_remove-item a {
  font-size: 0.875rem;
  padding-top: 1.25rem;
  display: inline-block;
}
@media (max-width: 638px) {
  /* line 190, ../../scss/app/widgets/_carttable.scss */
  .view-cart_remove-item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #f6f6f6;
    padding-top: 2.5rem;
    padding-left: 1.25rem;
  }
}

/* line 216, ../../scss/app/widgets/_carttable.scss */
.view-cart_line {
  padding: 0 0.3125rem;
}

/* line 220, ../../scss/app/widgets/_carttable.scss */
.view-cart_break {
  display: inline-block;
}
@media (max-width: 768px) {
  /* line 220, ../../scss/app/widgets/_carttable.scss */
  .view-cart_break {
    display: block;
    padding-left: 0.75rem;
  }
}
@media (max-width: 638px) {
  /* line 220, ../../scss/app/widgets/_carttable.scss */
  .view-cart_break {
    display: block;
    padding-left: 0;
  }
}

/* line 234, ../../scss/app/widgets/_carttable.scss */
.view-cart_table-body {
  clear: both;
  border-bottom: 0.0625rem solid #cccccc;
  position: relative;
  height: auto;
  overflow: hidden;
}
/* line 241, ../../scss/app/widgets/_carttable.scss */
.view-cart_table-body .view-cart_column {
  padding-top: 1.25rem;
  height: auto;
  overflow: hidden;
  font-size: 1.25rem;
  padding-bottom: 2.5rem;
}
@media (max-width: 768px) {
  /* line 241, ../../scss/app/widgets/_carttable.scss */
  .view-cart_table-body .view-cart_column {
    padding-bottom: 1.25rem;
  }
}
@media (max-width: 638px) {
  /* line 253, ../../scss/app/widgets/_carttable.scss */
  .view-cart_table-body .view-cart_column:nth-child(1) {
    padding-top: 3.125rem;
  }
}
/* line 259, ../../scss/app/widgets/_carttable.scss */
.view-cart_table-body .view-cart_column:nth-child(2) {
  font-size: 0.875rem;
  padding-bottom: 0.3125rem;
}
@media (max-width: 637px) {
  /* line 259, ../../scss/app/widgets/_carttable.scss */
  .view-cart_table-body .view-cart_column:nth-child(2) {
    line-height: 1.5625rem;
    padding-bottom: 1.25rem;
  }
}
/* line 268, ../../scss/app/widgets/_carttable.scss */
.view-cart_table-body .view-cart_column:nth-child(2) span {
  padding-top: 1.0625rem;
}
@media (max-width: 637px) {
  /* line 268, ../../scss/app/widgets/_carttable.scss */
  .view-cart_table-body .view-cart_column:nth-child(2) span {
    padding-top: 0.625rem;
  }
}
/* line 277, ../../scss/app/widgets/_carttable.scss */
.view-cart_table-body .view-cart_column:nth-child(3) {
  line-height: 1.0625rem;
}
@media (max-width: 638px) {
  /* line 277, ../../scss/app/widgets/_carttable.scss */
  .view-cart_table-body .view-cart_column:nth-child(3) {
    text-align: right;
    padding-right: 1.25rem;
    padding-left: 0px;
    padding-top: 2.0625rem;
  }
}
@media (max-width: 1024px) {
  /* line 277, ../../scss/app/widgets/_carttable.scss */
  .view-cart_table-body .view-cart_column:nth-child(3) {
    line-height: 1.375rem;
  }
}
/* line 291, ../../scss/app/widgets/_carttable.scss */
.view-cart_table-body .view-cart_column:nth-child(3) .view-cart_price-text {
  font-size: 1rem;
  text-transform: uppercase;
  padding-right: 0.3125rem;
  display: none;
}
@media (max-width: 638px) {
  /* line 291, ../../scss/app/widgets/_carttable.scss */
  .view-cart_table-body .view-cart_column:nth-child(3) .view-cart_price-text {
    display: inline-block;
  }
}
/* line 302, ../../scss/app/widgets/_carttable.scss */
.view-cart_table-body .view-cart_column:nth-child(3) .view-cart_price-original {
  display: inline-block;
  padding-bottom: 0.3125rem;
}

/* line 309, ../../scss/app/widgets/_carttable.scss */
/* [data-remove-item] { */
  /*display:none;*/
  /*visibility:hidden;*/
/* } */

/* line 314, ../../scss/app/widgets/_carttable.scss */
.view-cart_sale-icon {
  padding-top: 0.3125rem;
/* fix for cart page promotion text alignment
  padding-left: 0.9375rem;
  float: left;
*/
}

/* line 320, ../../scss/app/widgets/_carttable.scss */
.view-cart_sale-text {
  color: #cd040b;
  text-transform: uppercase;
  position: absolute;
  font-weight: bold;
  font-size: 0.75rem;
  padding-top: 0.625rem;
  padding-left: 0.125rem;
  display: inline-block;
}
@media (max-width: 638px) {
  /* line 320, ../../scss/app/widgets/_carttable.scss */
  /* .view-cart_sale-text { */
    /*padding-top:rem-calc(5);
     padding-left:rem-calc(2);*/
  /* } */
}

/* line 336, ../../scss/app/widgets/_carttable.scss */
option {
  text-indent: 4px;
}

/* Sign In checkout Styles Starts*/
/* line 2, ../../scss/app/widgets/_signinCheckout.scss */
.checkout-title {
  font-size: 3.625rem;
  color: #000;
}
@media (max-width: 638px) {
  /* line 2, ../../scss/app/widgets/_signinCheckout.scss */
  .checkout-title {
    font-size: 2rem;
    padding: 0.9375rem 0 0.9375rem 0.9375rem !important;
    /*border-bottom:rem-calc(1) solid $grey-5;*/
    /*background:$white !important;*/
  }
}

/* line 15, ../../scss/app/widgets/_signinCheckout.scss */
.begincheckout .main {
  background: #fff;
  padding-top: 0;
  padding-bottom: 0;
}

/* line 22, ../../scss/app/widgets/_signinCheckout.scss */
.w_start-checkout {
  border: 0.0625rem solid #e4e5e3;
  margin: 0.9375rem 0 0 0;
  height: auto;
  list-style-type: none;
}
@media (min-width: 1025px) {
  /* line 22, ../../scss/app/widgets/_signinCheckout.scss */
  .w_start-checkout {
    margin-bottom: 5rem !important;
  }
}
@media (max-width: 638px) {
  /* line 22, ../../scss/app/widgets/_signinCheckout.scss */
  .w_start-checkout {
    border: 0;
    margin: 0;
  }
}
/* line 37, ../../scss/app/widgets/_signinCheckout.scss */
/* .w_start-checkout .m_accordion-summary { */
  /*padding: rem-calc(10) rem-calc(15) rem-calc(10) rem-calc(15);*/
/* } */
@media (min-width: 639px) {
  /* line 37, ../../scss/app/widgets/_signinCheckout.scss */
  .w_start-checkout .m_accordion-summary {
    padding-bottom: 0;
  }
}
@media (min-width: 970px) {
  /* line 37, ../../scss/app/widgets/_signinCheckout.scss */
  .w_start-checkout .m_accordion-summary {
    padding-top: 1.25rem;
    padding-left: 2.5rem;
  }
}
@media (min-width: 639px) {
  /* line 48, ../../scss/app/widgets/_signinCheckout.scss */
  .w_start-checkout .m_accordion-summary span {
    font-size: 1.5rem !important;
  }
}
/* line 55, ../../scss/app/widgets/_signinCheckout.scss */
.w_start-checkout .m_accordion-details {
  padding: 0 0.9375rem 0 0.9375rem;
}
@media (min-width: 970px) {
  /* line 55, ../../scss/app/widgets/_signinCheckout.scss */
  .w_start-checkout .m_accordion-details {
    padding-left: 2.5rem;
  }
}
@media (min-width: 639px) {
  /* line 55, ../../scss/app/widgets/_signinCheckout.scss */
  .w_start-checkout .m_accordion-details {
    height: auto !important;
    display: block !important;
    overflow: visible;
  }
}
/* line 68, ../../scss/app/widgets/_signinCheckout.scss */
.w_start-checkout .m_accordion-details:active {
  background-color: transparent !important;
}
@media (min-width: 639px) {
  /* line 73, ../../scss/app/widgets/_signinCheckout.scss */
  .w_start-checkout li {
    border-top: 0 !important;
    border-bottom: 0 !important;
  }
}
/* line 79, ../../scss/app/widgets/_signinCheckout.scss */
.w_start-checkout li:first-child {
  border-top: 0 !important;
}
@media (min-width: 639px) {
  /* line 83, ../../scss/app/widgets/_signinCheckout.scss */
  .w_start-checkout li:before {
    display: none !important;
  }
}

/* line 91, ../../scss/app/widgets/_signinCheckout.scss */
.start-checkout_signin {
  width: 100%;
}
@media (min-width: 639px) {
  /* line 91, ../../scss/app/widgets/_signinCheckout.scss */
  .start-checkout_signin {
    width: 50%;
    float: left;
    border-right: 0.0625rem solid #e4e5e3;
  }
}
@media (min-width: 639px) {
  /* line 100, ../../scss/app/widgets/_signinCheckout.scss */
  .start-checkout_signin > li {
    background-color: #f2f2f2;
  }
}

/* line 107, ../../scss/app/widgets/_signinCheckout.scss */
.userid-label {
  font-size: 0.875rem;
  color: #858689;
  margin-top: 1.25rem;
  margin-bottom: 0.625rem;
}
@media (max-width: 638px) {
  /* line 107, ../../scss/app/widgets/_signinCheckout.scss */
  .userid-label {
    margin-top: 0.9375rem;
  }
}

/* line 118, ../../scss/app/widgets/_signinCheckout.scss */
.signin-info {
  padding-bottom: 1.875rem;
}
/* line 121, ../../scss/app/widgets/_signinCheckout.scss */
.signin-info input[type="text"] {
  background: #fff;
  border: 0.0625rem solid #d9d9d9;
  border-radius: 0.1875rem;
  font-size: 1.125rem;
}
/* line 128, ../../scss/app/widgets/_signinCheckout.scss */
.signin-info .note {
  font-size: 0.6875rem;
  line-height: 1.1875rem;
}

/* line 134, ../../scss/app/widgets/_signinCheckout.scss */
.signin-frame iframe {
  width: 78%;
}
@media (max-width: 638px) {
  /* line 134, ../../scss/app/widgets/_signinCheckout.scss */
  .signin-frame iframe {
    width: 100%;
  }
}

/*.inactivebtn { // Branding Updates
    background: #D2D3D4 !important;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    width: auto;
    color: #F7F7F7;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    height: 2.75rem;
    font-size: 1.0625rem;
    cursor:default;
}*/
/* line 153, ../../scss/app/widgets/_signinCheckout.scss */
.signin-option {
  width: 50%;
  float: left;
}
/* line 157, ../../scss/app/widgets/_signinCheckout.scss */
.signin-option input[type="checkbox"] + label {
  display: block;
  font-size: 0.875rem;
}
/* line 162, ../../scss/app/widgets/_signinCheckout.scss */
.signin-option input[type="checkbox"]:checked + label {
  font-size: 0.875rem;
  display: block;
}
@media (max-width: 638px) {
  /* line 153, ../../scss/app/widgets/_signinCheckout.scss */
  .signin-option {
    width: 100%;
  }
}

/* line 172, ../../scss/app/widgets/_signinCheckout.scss */
.checkbox-alert {
  display: block;
  clear: both;
  width: 100%;
  padding-top: 1.25rem;
  /*@media (max-width:638px){
      display:none;
  }*/
}
/* line 178, ../../scss/app/widgets/_signinCheckout.scss */
.checkbox-alert .icon-info {
  padding-left: 0.625rem;
}

/* line 186, ../../scss/app/widgets/_signinCheckout.scss */
.alert-text {
  float: left;
  font-size: 0.75rem;
  line-height: 1.3125rem;
}
@media (min-width: 639px) and (max-width: 850px) {
  /* line 186, ../../scss/app/widgets/_signinCheckout.scss */
  .alert-text {
    width: auto;
    padding-right: 0.625rem;
  }
}

/* line 197, ../../scss/app/widgets/_signinCheckout.scss */
.forgot-id {
  clear: both;
  padding-top: 1.25rem;
  font-size: 0.875rem;
}

/* line 203, ../../scss/app/widgets/_signinCheckout.scss */
.note {
  font-size: 0.6875rem;
  padding-top: 1.25rem;
  width: 75%;
}
@media (max-width: 638px) {
  /* line 203, ../../scss/app/widgets/_signinCheckout.scss */
  .note {
    width: 100%;
    font-size: 0.875rem;
  }
}

/* line 214, ../../scss/app/widgets/_signinCheckout.scss */
.start-checkout_guest {
  width: 100%;
}
@media (min-width: 639px) {
  /* line 214, ../../scss/app/widgets/_signinCheckout.scss */
  .start-checkout_guest {
    width: 50%;
    float: left;
  }
}
@media (min-width: 639px) {
  /* line 222, ../../scss/app/widgets/_signinCheckout.scss */
  .start-checkout_guest li {
    background: inherit;
  }
}
/* line 227, ../../scss/app/widgets/_signinCheckout.scss */
.start-checkout_guest li:first-child {
  border-bottom: 0;
}

/* line 234, ../../scss/app/widgets/_signinCheckout.scss */
.no-signin p {
  font-size: 0.75rem;
  padding: 0.625rem 0 0;
  margin-bottom: 0;
  height: 6.9375rem;
}
@media (max-width: 638px) {
  /* line 234, ../../scss/app/widgets/_signinCheckout.scss */
  .no-signin p {
    height: auto;
    padding-bottom: 1.5625rem;
  }
}
@media (max-width: 480px) {
  /* line 246, ../../scss/app/widgets/_signinCheckout.scss */
  .no-signin .button {
    width: 100% ;
  }
}

/* line 254, ../../scss/app/widgets/_signinCheckout.scss */
.public-computer.w_tooltip {
  width: 29.375rem;
  max-width: 29.375rem !important;
  margin-top: 0 !important;
}
/* line 259, ../../scss/app/widgets/_signinCheckout.scss */
.public-computer.w_tooltip:before {
  top: 50% !important;
}
/* line 264, ../../scss/app/widgets/_signinCheckout.scss */
.public-computer.w_tooltip.m_flow-up:before {
  top: 50% !important;
}
/* line 269, ../../scss/app/widgets/_signinCheckout.scss */
.public-computer.w_tooltip p, .public-computer.w_tooltip li, .public-computer.w_tooltip .about-public-computer {
  font-size: 0.875rem;
}
/* line 273, ../../scss/app/widgets/_signinCheckout.scss */
.public-computer.w_tooltip .more-info {
  font-size: 0.875rem;
  font-weight: bold;
}

/* line 281, ../../scss/app/widgets/_signinCheckout.scss */
.w_modal.public-computer {
  top: 5% !important;
}
/* line 284, ../../scss/app/widgets/_signinCheckout.scss */
.w_modal.public-computer .modal_x {
  position: absolute;
  right: 0.3125rem;
}
/* line 289, ../../scss/app/widgets/_signinCheckout.scss */
.w_modal.public-computer p, .w_modal.public-computer li, .w_modal.public-computer .about-public-computer {
  font-size: 0.875rem;
  line-height: 1.3125rem;
}
/* line 294, ../../scss/app/widgets/_signinCheckout.scss */
.w_modal.public-computer .more-info {
  font-size: 0.875rem;
  font-weight: bold;
}
/* line 299, ../../scss/app/widgets/_signinCheckout.scss */
.w_modal.public-computer .modal_content {
  padding-top: 0;
  margin-top: 1.25rem !important;
  padding-right: 1.875rem;
  line-height: 1.125rem;
}

/* line 309, ../../scss/app/widgets/_signinCheckout.scss */
.public-computer .about-public-computer {
  padding-top: 0.625rem;
}
/* line 313, ../../scss/app/widgets/_signinCheckout.scss */
.public-computer ul {
  list-style-type: disc;
  padding: 0.3125rem 0 1.25rem 0;
}
/* line 318, ../../scss/app/widgets/_signinCheckout.scss */
.public-computer li {
  line-height: 1.1875rem;
  padding-top: 0.3125rem;
}

/* Sign In checkout Styles Ends*/
/*.cart_font{
    font-size:rem-calc(33);
}*/
/* line 5, ../../scss/app/components/_type-sizes.scss */
/* button:active,
.button:focus, a.button:focus { */
  /*box-shadow: none !important;
  outline:0px;
   -moz-outline-style: none;
  outline:none;
  outline: 0;*/
/* } */

/* line 14, ../../scss/app/components/_type-sizes.scss */
.sale_text {
    font-size: 0.75rem;
    font-weight: bold;
    color: #cd040b;
    display: inline-block;  /* fix by dev for IE misalignment issue while resizing.*/
    /* width: 79%; */
    width: 78%;
    vertical-align: middle;
    line-height: 0.875rem;
    padding-left: 0.3125rem;
}

/* line 20, ../../scss/app/components/_type-sizes.scss */
sup {
  font-size: 1.125rem;
}

/* line 24, ../../scss/app/components/_type-sizes.scss */
.validate_success {
  position: absolute;
  /*top: 0;*/
  right: -20px;
  /*margin-top: 36px;*/
  bottom: 2.1875rem;
}

/*[data-validate-address] .validate_success {
    right: -30px;
}*/
/*[data-modal="outof_stock-modal"] .modal_content{
    padding-top:rem-calc(20); padding-bottom:rem-calc(40);
@media screen and (max-width: 390px){
  
    padding-top:rem-calc(40);
}
}*/
/* line 44, ../../scss/app/components/_type-sizes.scss */
.ico-zoom_list {
  margin-left: 1.25rem;
  cursor: pointer;
}

/*.w_modal.accessories_modal{ width:60% ;margin-left:-25%;
 @media (min-width: 1025px) and (max-width: 1280px){
}
  @media (min-width:1600px) {
     width:43%;
     margin-left:-25%;
 }
 @media (min-width:1400px) and (max-width:1599px)  {
     width:51%;
 }
 @media (min-width:991px) and (max-width:1150px){
     width:70% !important;margin-left:-35%;
 }
 @media (min-width:639px) and (max-width:990px){
     width: 91% !important;
     margin-left: -46%;
 }
 @media (max-width:637px){
     width: 82% !important;
    margin-left: -41%;
 }
@media screen and (max-width: 1024px){
}
}
.w_modal.accessories_modal h1{
@media screen and (max-width: 638px){
    font-size:rem-calc(28)
}
}*/
/* line 80, ../../scss/app/components/_type-sizes.scss */
.w_modal.freeshipping_modal {
  width: 40%;
  margin-left: -20%;
}
@media screen and (max-width: 1024px) {
  /* line 80, ../../scss/app/components/_type-sizes.scss */
  .w_modal.freeshipping_modal {
    width: 90% !important;
    margin-left: -45% !important;
  }
}

/* line 90, ../../scss/app/components/_type-sizes.scss */
/* .modal_content .w_list-control { */
  /*@media screen and (max-width: 638px){
  padding-left:0px;
  padding-right:0px;
  }*/
/* } */
@media screen and (max-width: 638px) {
  /* line 91, ../../scss/app/components/_type-sizes.scss */
  .modal_content .w_list-control .m_accordion-summary {
    line-height: 1.5rem !important;
  }
}
@media screen and (max-width: 638px) {
  /* line 96, ../../scss/app/components/_type-sizes.scss */
  .modal_content .w_list-control .m_accordion-summary span {
    line-height: 1.25rem !important;
  }
}

@media screen and (max-width: 638px) {
  /* line 108, ../../scss/app/components/_type-sizes.scss */
  .modal_content .w_list-control > li.m_single > * {
    padding-left: 0px;
  }
}

@media screen and (max-width: 638px) {
  /* line 114, ../../scss/app/components/_type-sizes.scss */
  .modal_content .w_list-control .m_accordion-summary::before {
    right: 0.125rem;
  }
}

/*.product-wrap{min-height:400px; background-color: #D2D3D4 !important; border-right:1px solid #BBB; border-left:1px solid #BBB; width:100%}*/
/* Tooltip for Call Helpline starts*/
/* line 121, ../../scss/app/components/_type-sizes.scss */
.w_tooltip.tooltip_helpline {
  position: absolute;
  z-index: 350;
  padding: 0.625rem 0.5rem;
  background-color: #ffffff;
  left: -3.4375rem;
  top: 2.1875rem;
  width: 175px !important;
  box-shadow: 0 0px 2px rgba(0, 0, 0, 0.5);
  margin: 0px;
}

/* line 133, ../../scss/app/components/_type-sizes.scss */
.w_tooltip.m_rev.tooltip_helpline:before {
  bottom: -2em;
  left: 50%;
  top: 2px;
  border-color: transparent transparent #ffffff #ffffff;
  transform-origin: 0px 0px 0px;
  transform: rotate(135deg);
  box-shadow: -3px 3px 3px 0px rgba(0, 0, 0, 0.2);
}

/* line 143, ../../scss/app/components/_type-sizes.scss */
.tooltip_helpline-text {
  font-size: 0.75rem;
  color: #666;
  text-transform: none;
}

/* Tooltip for Call Helpline ends*/
/*.state_select-box, select[required].state_select-box ,select.state_select-box:focus:not([readonly]), select.state_select-box.value:focus:not([readonly]) { // Branding Updates
    background:  url("../../../images/downArrow.png") 96% 20px;
      background-repeat: no-repeat, no-repeat;
    background-color:#fff !important;
    border-radius: 3px !important;
    border: 1px solid #CCC !important;
    background-size: auto, 0.375rem auto;
    padding-left: rem-calc(4);
        border-radius: 3px;
        margin-top: rem-calc(6);
        margin-bottom: rem-calc(5);
        padding-top: rem-calc(8);
        text-align: left;
        color: #a3a3a3 !important;
        font-size: rem-calc(18);
        cursor: pointer;
        padding-right: rem-calc(20);
         
}*/
/* line 170, ../../scss/app/components/_type-sizes.scss */
.checkout_state-dropdown {
  height: 2.9375rem !important;
  padding-top: 0.5rem !important;
  background-position: right 0.4375rem top 1.25rem, 0.625rem 0.9375rem !important;
}

/* Modal Window Button styles starts*/
/* line 177, ../../scss/app/components/_type-sizes.scss */
.support {
  float: left;
}
@media screen and (max-width: 638px) {
  /* line 177, ../../scss/app/components/_type-sizes.scss */
  .support {
    float: none;
  }
}

/* line 185, ../../scss/app/components/_type-sizes.scss */
.support_buttons {
  float: left;
  width: auto;
}
@media screen and (max-width: 638px) {
  /* line 185, ../../scss/app/components/_type-sizes.scss */
  .support_buttons {
    width: 90%;
  }
}

/* line 194, ../../scss/app/components/_type-sizes.scss */
.support_call {
  float: left;
  padding-right: 1.25rem;
  border-right: 1px solid #BBB;
}
@media screen and (max-width: 638px) {
  /* line 194, ../../scss/app/components/_type-sizes.scss */
  .support_call {
    float: none;
    padding-right: 0px;
    border-right: 0px;
  }
}

/* line 206, ../../scss/app/components/_type-sizes.scss */
.support_chat {
  float: left;
  padding-left: 0.625rem;
}
@media screen and (max-width: 638px) {
  /* line 206, ../../scss/app/components/_type-sizes.scss */
  .support_chat {
    float: none;
    padding-left: 0px;
    padding-top: 1.25rem;
    clear: both;
  }
}

/* line 218, ../../scss/app/components/_type-sizes.scss */
.support_ok {
  float: right;
}
@media screen and (max-width: 638px) {
  /* line 218, ../../scss/app/components/_type-sizes.scss */
  .support_ok {
    float: none;
    clear: both;
    padding-top: 1.25rem;
    display: none;
  }
}

/* Modal Window Button styles ends*/
/*select{
     &:focus{
     border:rem-calc(1) solid $brand-1 !important;
     background: url("../../../images/dropdownFocus.png") no-repeat scroll right 10px top 20px #FFF !important;
     }
     &:not(:focus){
         border:rem-calc(1) solid $grey-5 !important;
         background: url("../../../images/dropdown.png") no-repeat scroll right 10px top 20px #FFF !important;
     }
 }*/
/* line 241, ../../scss/app/components/_type-sizes.scss */
ul {
  line-height: 1.1875rem;
}

/* line 1, ../../scss/app/components/_viewcart.scss */
.w_view-cart {
  height: auto;
}

/* line 6, ../../scss/app/components/_viewcart.scss */
.view-cart_toolbar .w_toolbar {
  border-left: 0;
}

/* line 11, ../../scss/app/components/_viewcart.scss */
.view-cart_wrap {
  height: auto;
  overflow: hidden;
  border-top: 0.0625rem solid #cccccc;
  background: #fff;
}

/* line 18, ../../scss/app/components/_viewcart.scss */
.view-cart_right {
  width: 28%;
  float: left;
  background: #f6f6f6;
}
@media (min-width: 767px) and (max-width: 963px) {
  /* line 18, ../../scss/app/components/_viewcart.scss */
  .view-cart_right {
    width: 100%;
    float: left;
  }
}
@media (max-width: 640px) {
  /* line 18, ../../scss/app/components/_viewcart.scss */
  .view-cart_right {
    width: 100%;
    clear: both !important;
  }
}

/* line 34, ../../scss/app/components/_viewcart.scss */
.view-cart_free-ship {
  background: #fff;
  height: 4.375rem;
  overflow: hidden;
  border-bottom: 0.0625rem solid #cccccc;
}
/* line 42, ../../scss/app/components/_viewcart.scss */
.view-cart_free-ship div:first-child {
  width: 3.75rem;
  background-color: #f9b295;
  height: 4.6875rem;
  padding: 0.625rem 0 0 0.9375rem;
}
@media (min-width: 767px) and (max-width: 963px) {
  /* line 34, ../../scss/app/components/_viewcart.scss */
  .view-cart_free-ship {
    float: right;
    height: 4.375rem;
    width: 50%;
    overflow: hidden;
  }
}
@media (max-width: 640px) {
  /* line 34, ../../scss/app/components/_viewcart.scss */
  .view-cart_free-ship {
    width: 100%;
    clear: both;
    height: 4.375rem;
    float: none;
  }
}
/* line 65, ../../scss/app/components/_viewcart.scss */
.view-cart_free-ship span {
  font-size: 0.875rem;
  color: #000;
  float: left;
  margin-bottom: 0;
  width: 74.25743%;
  padding: 1.25rem 0 1.25rem 0.9375rem;
}
@media (max-width: 768px) {
  /* line 65, ../../scss/app/components/_viewcart.scss */
  .view-cart_free-ship span {
    float: left;
    position: relative;
    top: 0.4375rem;
  }
}
@media (min-width: 769px) and (max-width: 915px) {
  /* line 65, ../../scss/app/components/_viewcart.scss */
  .view-cart_free-ship span {
    float: left;
    width: 62.79435%;
  }
}
@media (max-width: 637px) {
  /* line 65, ../../scss/app/components/_viewcart.scss */
  .view-cart_free-ship span {
    float: left;
    width: 70.64364%;
    position: relative;
    top: 0.4375rem !important;
  }
}

/* line 93, ../../scss/app/components/_viewcart.scss */
/* .view-cart_free-ship:first-child { */
  /*border-top:1px solid $grey-4;
  @media (max-width:960px){
      border-top:0px
  }*/
/* } */

/* line 100, ../../scss/app/components/_viewcart.scss */
.m_icon-truck {
  transform: rotateY(180deg);
  float: left;
  color: #fff;
  position: relative;
  left: -0.9375rem;
  top: -0.3125rem;
}

/* line 109, ../../scss/app/components/_viewcart.scss */
.view-cart_return-policy {
  background: #f9b295;
  padding: 1.25rem 0.9375rem;
  height: auto;
  overflow: hidden;
}

/* line 116, ../../scss/app/components/_viewcart.scss */
.view-cart_return-icon {
  /* background: url(../../images/large-boxes-icon.png) center center no-repeat; */
  background: url(../../img/accessories/media/images-accessories/returns.svg) center center no-repeat; 
  width: 3.375rem;
  height: 2.4375rem;
  float: left;
  position: relative;
  top: -0.3125rem;
}
@media (max-width: 768px) {
  /* line 116, ../../scss/app/components/_viewcart.scss */
  .view-cart_return-icon {
    top: -0.3125rem;
  }
}
@media (max-width: 637px) {
  /* line 116, ../../scss/app/components/_viewcart.scss */
  /* .view-cart_return-icon { */
    /*margin-top:0;
     position:static;*/
  /* } */
}

/* line 134, ../../scss/app/components/_viewcart.scss */
.view-cart_return {
  position: relative;
  top: 8px;
}
@media (max-width: 768px) {
  /* line 134, ../../scss/app/components/_viewcart.scss */
  .view-cart_return {
    position: static;
  }
}
@media (max-width: 637px) {
  /* line 134, ../../scss/app/components/_viewcart.scss */
  .view-cart_return {
    position: static;
  }
}

/* line 147, ../../scss/app/components/_viewcart.scss */
.view-cart_total-block {
  width: 100%;
}
@media (max-width: 768px) {
  /* line 147, ../../scss/app/components/_viewcart.scss */
  .view-cart_total-block {
    width: 50%;
  }
}
@media (max-width: 637px) {
  /* line 147, ../../scss/app/components/_viewcart.scss */
  .view-cart_total-block {
    width: 100%;
    clear: both;
  }
}

@media (max-width: 768px) {
  /* line 160, ../../scss/app/components/_viewcart.scss */
  .view-cart_total-block:nth-child(1) {
    float: right;
    padding-right: 2.8125rem;
    width: 41.66667%;
  }
}
@media (max-width: 637px) {
  /* line 160, ../../scss/app/components/_viewcart.scss */
  .view-cart_total-block:nth-child(1) {
    width: 100%;
    float: none;
    height: auto;
    overflow: hidden;
    padding-right: 0;
    padding-bottom: 1.25rem;
  }
}

/* line 177, ../../scss/app/components/_viewcart.scss */
.view-cart_total-block:nth-child(2) {
  padding-top: 16.2px;
  clear: both;
  text-align: left;
}
@media (max-width: 768px) {
  /* line 177, ../../scss/app/components/_viewcart.scss */
  .view-cart_total-block:nth-child(2) {
    margin-left: 0;
    padding-top: 0;
    text-align: left;
    clear: left !important;
  }
}
@media (max-width: 367px) {
  /* line 177, ../../scss/app/components/_viewcart.scss */
  .view-cart_total-block:nth-child(2) {
    text-align: left;
  }
}
/* line 193, ../../scss/app/components/_viewcart.scss */
.view-cart_total-block:nth-child(2) .button {
  width: 100% !important;
}
@media (min-width: 638px) and (max-width: 768px) {
  /* line 193, ../../scss/app/components/_viewcart.scss */
  .view-cart_total-block:nth-child(2) .button {
    width: 70% !important;
  }
}
@media (max-width: 637px) {
  /* line 193, ../../scss/app/components/_viewcart.scss */
  .view-cart_total-block:nth-child(2) .button {
    width: 100% !important;
  }
}

/* line 206, ../../scss/app/components/_viewcart.scss */
.view-cart_total {
  padding: 1.25rem 1.25rem 0 1.25rem;
  clear: both;
  background:#fff;
}

/* line 211, ../../scss/app/components/_viewcart.scss */
.view-cart_subtotal {
  float: left;
  font-size: 0.875rem;
  font-weight: bold;
}

/* line 217, ../../scss/app/components/_viewcart.scss */
.view-cart_subtotal-value {
  float: right;
  font-size: 0.875rem;
  font-weight: bold;
}

/* line 223, ../../scss/app/components/_viewcart.scss */
.view-cart_total-text {
  font-size: 1.375rem;
  font-weight: bold;
}

/* line 228, ../../scss/app/components/_viewcart.scss */
.view-cart_total-value {
  font-size: 1.375rem;
  float: right;
  font-weight: bold;
}

/* line 234, ../../scss/app/components/_viewcart.scss */
.view-cart_empty {
  min-height: 8.825rem;
  padding: 1.875rem 0px;
  border-bottom: 1px solid #BBB;
}

/* line 241, ../../scss/app/components/_viewcart.scss */
.view-cart_noitems {
  font-size: 1.125rem;
  float: left;
  width: 50%;
  padding-left: 0.9375rem;
  padding-top: 0.625rem;
  color: #4b4b4b;
}
@media (max-width: 637px) {
  /* line 241, ../../scss/app/components/_viewcart.scss */
  .view-cart_noitems {
    width: 100% !important;
  }
}

/* line 255, ../../scss/app/components/_viewcart.scss */
.view-cart_empty .view-cart-continue {
  width: 20%;
}
@media (min-width: 767px) and (max-width: 963px) {
  /* line 255, ../../scss/app/components/_viewcart.scss */
  .view-cart_empty .view-cart-continue {
    width: 30% !important;
    float: left;
    line-height: 22px;
  }
}
@media (max-width: 637px) {
  /* line 255, ../../scss/app/components/_viewcart.scss */
  .view-cart_empty .view-cart-continue {
    margin: 3.4375rem 0.9375rem;
    width: 90% !important;
  }
}

/* line 271, ../../scss/app/components/_viewcart.scss */
.view-cart-checkout {
  width: 100% !important;
  height: 2.75rem;
  overflow: hidden;
  font-size: 1.0625rem !important;
  padding: 0.75rem 0px;
  background: none repeat scroll 0% 0% #ED1C24 !important;
}
@media (min-width: 767px) and (max-width: 963px) {
  /* line 271, ../../scss/app/components/_viewcart.scss */
  .view-cart-checkout {
    width: 100% !important;
  }
}
@media (max-width: 768px) {
  /* line 271, ../../scss/app/components/_viewcart.scss */
  .view-cart-checkout {
    width: 65.45961% !important;
    display: block;
  }
}
@media (max-width: 637px) {
  /* line 271, ../../scss/app/components/_viewcart.scss */
  .view-cart-checkout {
    width: 36.89168% !important;
    display: block;
    margin-top: 1.875rem;
  }
}
@media (min-width: 320px) and (max-width: 460px) {
  /* line 271, ../../scss/app/components/_viewcart.scss */
  .view-cart-checkout {
    width: 100% !important;
    display: block !important;
    clear: both;
  }
}

/* line 301, ../../scss/app/components/_viewcart.scss */
.view-cart-checkout:hover {
  background: none repeat scroll 0% 0% #ED1C24;
}

/* line 305, ../../scss/app/components/_viewcart.scss */
.view-cart-continue {
  width: 100%;
  margin-bottom: 0 !important;
  font-size: 1.0625rem !important;
  padding: 0.75rem 0px;
  background: none repeat scroll 0% 0% #86878A !important;
  border-radius: 3px;
  height: 2.75rem;
}
@media (min-width: 767px) and (max-width: 963px) {
  /* line 305, ../../scss/app/components/_viewcart.scss */
  .view-cart-continue {
    width: 100% !important;
  }
}
@media (max-width: 768px) {
  /* line 305, ../../scss/app/components/_viewcart.scss */
  .view-cart-continue {
    width: 65.45961% !important;
    display: block;
    float: none;
  }
}
@media (max-width: 637px) {
  /* line 305, ../../scss/app/components/_viewcart.scss */
  .view-cart-continue {
    width: 36.89168% !important;
    display: block !important;
    clear: both;
  }
}
@media (min-width: 320px) and (max-width: 460px) {
  /* line 305, ../../scss/app/components/_viewcart.scss */
  .view-cart-continue {
    width: 100% !important;
    display: block !important;
    clear: both;
  }
}

/* line 337, ../../scss/app/components/_viewcart.scss */
.view-cart-continue:hover {
  background: none repeat scroll 0% 0% #86878A !important;
}

/* line 341, ../../scss/app/components/_viewcart.scss */
.view-cart_save {
  display: block;
  clear: both;
  padding-top: 0.9375rem;
  font-size: 0.875rem;
  font-weight: bold;
}

/* line 349, ../../scss/app/components/_viewcart.scss */
.view-cart_note {
  background: #fff;
  padding: 0 2.1875rem 3.125rem 2.1875rem;
}
@media (max-width: 768px) {
  /* line 349, ../../scss/app/components/_viewcart.scss */
  .view-cart_note {
    padding: 0 1.5625rem 1.5625rem 1.5625rem;
  }
}
/* line 357, ../../scss/app/components/_viewcart.scss */
.view-cart_note p {
  font-size: 0.625rem;
}
/* line 361, ../../scss/app/components/_viewcart.scss */
.view-cart_note p:first-child {
  padding-top: 1.875rem;
  clear: both;
}

/* line 367, ../../scss/app/components/_viewcart.scss */
.tp_truck-icon {
  /* background: url("../../images/category-listitings.png") 6px -225px no-repeat; */
  width: 3.625rem;
  height: 2.0625rem;
  float: left;
}

/* line 374, ../../scss/app/components/_viewcart.scss */
.faq {
  padding: 0.9375rem 1.25rem 0.625rem 0.9375rem;
  height: auto;
  overflow: hidden;
  float: right;
  position: absolute;
  right: 0;
}
@media only screen and (max-width: 638px) {
  /* line 374, ../../scss/app/components/_viewcart.scss */
  .faq {
    float: left;
    position: relative;
    padding-left:.875rem;
  }
}
/* line 384, ../../scss/app/components/_viewcart.scss */
.faq .faq-text {
  font-size: 0.875rem;
}

/* line 1, ../../scss/app/components/_backcart.scss */
.checkout-back-cart {
  padding-left: 6.25rem;
  margin-top: 1.5625rem;
  margin-left: 0.9375rem;
}
/* line 5, ../../scss/app/components/_backcart.scss */
.checkout-back-cart a {
  color:#000;
}
/* line 8, ../../scss/app/components/_backcart.scss */
.checkout-back-cart a:hover {
  color: #000;
}
/* line 12, ../../scss/app/components/_backcart.scss */
.checkout-back-cart a span {
  padding-right: 0.3125rem;
}
@media screen and (max-width: 1024px) {
  /* line 1, ../../scss/app/components/_backcart.scss */
  .checkout-back-cart {
    padding-left: 0;
  }
}
@media screen and (max-width: 638px) {
  /* line 1, ../../scss/app/components/_backcart.scss */
  .checkout-back-cart {
    /*font-weight: bold;*/
    line-height: 0.6875rem;
    padding-bottom: 0.3125rem;
  }
}

/* line 1, ../../scss/app/components/_pagetitle.scss */
.page-cart-title {
  margin-top: 0;
  margin-left: 0;
  padding-top: 0.9375rem;
  padding-bottom: 0.9375rem;
    /*@media screen and (max-width: 1080px) {
         &.begin-checkout{
            font-size:rem-calc(38);
         }
     }

      @media screen and (max-width: 768px) {
         &.begin-checkout{
            font-size:rem-calc(58);
         }
    }*/
}
@media (min-width: 639px) and (max-width: 1024px) {
  /* line 1, ../../scss/app/components/_pagetitle.scss */
  .page-cart-title {
    padding-top: 0.9375rem !important;
  }
}
/* line 11, ../../scss/app/components/_pagetitle.scss */
.page-cart-title.begin-checkout {
  font-size: 4.4375rem;
  color: #3d4146;  
}
/* line 16, ../../scss/app/components/_pagetitle.scss */
.page-cart-title.view-cart_title {
  padding-left: 0.9375rem;
  background-color: transparent;
}
/* line 19, ../../scss/app/components/_pagetitle.scss */
.page-cart-title.view-cart_title span {
  color: #000;
}
@media screen and (max-width: 1280px) {
  /* line 25, ../../scss/app/components/_pagetitle.scss */
  .page-cart-title.begin-checkout {
    font-size: 3.625rem;
    
  }
}
@media screen and (max-width: 638px) {
  /* line 41, ../../scss/app/components/_pagetitle.scss */
  .page-cart-title.begin-checkout {
    font-size: 2rem;
  }
}
/* line 46, ../../scss/app/components/_pagetitle.scss */
.page-cart-title.view-cart_title {
  font-size: 2.0625rem;
  padding-bottom: 0.9375rem;
  line-height: 1.625rem !important;
}
/* line 52, ../../scss/app/components/_pagetitle.scss */
.page-cart-title.view-cart_title span:last-child {
  font-size: 1.1875rem;
}

/* line 58, ../../scss/app/components/_pagetitle.scss */
.order-receipt-title {
  font-size: 1.5rem;
}

/* line 62, ../../scss/app/components/_pagetitle.scss */
.checkout .page-cart-title {
  padding-left: 10rem;
}
@media screen and (max-width: 1024px) {
  /* line 62, ../../scss/app/components/_pagetitle.scss */
  .checkout .page-cart-title {
    padding-left: 0.9375rem;
  }
}

/* line 70, ../../scss/app/components/_pagetitle.scss */
.begincheckout .page-cart-title {
  padding-left: 10rem;
}
@media screen and (max-width: 1024px) {
  /* line 70, ../../scss/app/components/_pagetitle.scss */
  .begincheckout .page-cart-title {
    padding-left: 0.9375rem;
  }
}

/* line 2, ../../scss/app/components/_order.scss */
.order-details {
  padding-left: 0;
  padding-right: 0;
}
@media (min-width: 639px) and (max-width: 1023px) {
  /* line 2, ../../scss/app/components/_order.scss */
  .order-details {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}
@media (min-width: 320px) and (max-width: 638px) {
  /* line 2, ../../scss/app/components/_order.scss */
  .order-details {
    padding-left: 0;
    padding-right: 0;
  }
}
@media only screen and (max-width: 638px) {
  /* line 15, ../../scss/app/components/_order.scss */
  .order-details .page-cart-title {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
}

/* line 23, ../../scss/app/components/_order.scss */
.order-description {
  float: left;
  width: 50%;
  font-size: 1rem;
  padding-left:2rem;
}
@media (min-width: 639px) and (max-width: 1024px) {
  /* line 23, ../../scss/app/components/_order.scss */
  .order-description {
    width: 70%;
  }
}
@media (min-width: 320px) and (max-width: 638px) {
  /* line 23, ../../scss/app/components/_order.scss */
  .order-description {
    width: 90%;
    margin: 1.25rem;
  }
}
@media only screen and (min-width: 60.625rem) {
  /* line 23, ../../scss/app/components/_order.scss */
  .order-description {
    margin-left: 0;
  }
}

/* line 40, ../../scss/app/components/_order.scss */
.order-print {
  float: right;
  margin: 0 1.25rem;
  text-align: center;
  width: 40%;
}
@media (min-width: 639px) and (max-width: 1024px) {
  /* line 40, ../../scss/app/components/_order.scss */
  .order-print {
    width: 15%;
  }
}
@media (min-width: 320px) and (max-width: 638px) {
  /* line 40, ../../scss/app/components/_order.scss */
  .order-print {
    display: none;
  }
}

/* line 55, ../../scss/app/components/_order.scss */
.warning-box {
  padding: 0.625rem;
  margin-top: 2.5rem;
  text-align: left;
  float: right;
  width: 45%;
  /*min-height: rem-calc(220);*/
}
@media screen and (max-width: 1024px) {
  /* line 55, ../../scss/app/components/_order.scss */
  .warning-box {
    float: none;
    clear: both;
    margin-bottom: 1.25rem;
    margin-left: 0.9375rem;
    min-height: 3.4375rem;
  }
}
@media (min-width: 639px) and (max-width: 1024px) {
  /* line 55, ../../scss/app/components/_order.scss */
  .warning-box {
    width: 98%;
    margin-left: 0;
    padding-left: 0;
  }
}
@media (min-width: 320px) and (max-width: 638px) {
  /* line 55, ../../scss/app/components/_order.scss */
  .warning-box {
    width: 90%;
  }
}

@media only screen and (min-width: 48rem) {
  /* line 81, ../../scss/app/components/_order.scss */
  .bill-ship-info {
    padding-top: 1.5rem;
  }
}
@media only screen and (max-width: 47.9375rem) {
  /* line 85, ../../scss/app/components/_order.scss */
  .bill-ship-info > ul {
    margin: 0 1.25rem;
  }
}
/* line 90, ../../scss/app/components/_order.scss */
.bill-ship-info > ul > li p:last-child {
  margin-bottom: 0;
}

/* styles for check out gift card option*/
/* line 97, ../../scss/app/components/_order.scss */
.gift-card_tooltip-content {
  border: 1px solid #e4e5e3;
}
/* line 99, ../../scss/app/components/_order.scss */
/* .gift-card_tooltip-content:before { */
  /* background-image: url("../../images/tooltip_arrow.svg"); */
/* } */

/* line 104, ../../scss/app/components/_order.scss */
.hide-imp {
  display: none !important;
}

/* styles for check out gift card option*/


/* line 1, ../../scss/app/components/_footer.scss */
.w_footer_desktop {
  position: relative;
  z-index: -1;
}

@media (min-width: 320px) and (max-width: 638px) {
  /* line 5, ../../scss/app/components/_footer.scss */
  .footer_desktop {
    display: none;
  }
}

/* line 10, ../../scss/app/components/_footer.scss */
.footer_device {
  display: none;
}
@media (min-width: 320px) and (max-width: 638px) {
  /* line 10, ../../scss/app/components/_footer.scss */
  .footer_device {
    display: block;
    padding: 0.9375rem;
  }
}

/* Pagination Styles*/
/* line 2, ../../scss/app/components/_pagination.scss */
.grid-pagination {
  clear: both;
  width: auto;
  text-align: center;
}
@media only screen and (max-width: 984px) {
  /* line 2, ../../scss/app/components/_pagination.scss */
  .grid-pagination {
    padding-top: 0.75rem;
    margin: 0 0.5625rem;
  }
}
@media only screen and (max-width: 638px) {
  /* line 12, ../../scss/app/components/_pagination.scss */
  .grid-pagination .button span {
    display: none;
  }
}

/* line 19, ../../scss/app/components/_pagination.scss */
.grid-pagination_numbers {
  display: inline-block;
  margin: 0.75rem 0.9375rem 0 0.9375rem;
  counter-reset: page;
}
/* line 24, ../../scss/app/components/_pagination.scss */
.grid-pagination_numbers li {
  width: 1.375rem;
  height: 1.375rem;
  display: inline-block;
  border-radius: 1.25rem;
  margin-right: 0.3125rem;
  text-align: center;
  font-weight: bold;
  color: #949494;
  background: transparent;
  cursor: pointer;
  line-height: 1.25rem;
}
/* line 37, ../../scss/app/components/_pagination.scss */
.grid-pagination_numbers li.active {
  background: #cd040b;
  color: #fff;
}
/* line 42, ../../scss/app/components/_pagination.scss */
.grid-pagination_numbers li:before {
  counter-increment: page;
  content: counter(page);
}

.businessCust{
  float: right;
  padding-top: 5rem;
  margin-right: 30rem;
  font-size: 1rem !important;
}

/* line 52, ../../scss/app/components/_pagination.scss */
.grid-pagination_next, .grid-pagination_prev {
  display: inline-block;
}
/* line 54, ../../scss/app/components/_pagination.scss */
.grid-pagination_next.disable, .grid-pagination_prev.disable {
  color: #959595 !important;
  opacity: 0.9;
  cursor: not-allowed;
}
/* line 58, ../../scss/app/components/_pagination.scss */
.grid-pagination_next.disable:before, .grid-pagination_prev.disable:before {
  color: #959595 !important;
}

/* line 2, ../../scss/app/components/_store-front.scss */
.store-front .main {
  max-width: 100%;
  overflow-x: hidden;
}

/* line 8, ../../scss/app/components/_store-front.scss */
.store-front-container {
  max-width: 80rem;
  margin: 0 auto;
}

/* Nav Starts */
/* line 16, ../../scss/app/components/_store-front.scss */
.store-icon-nav li {
  -moz-transition: ease 0.3s;
  -o-transition: ease 0.3s;
  -webkit-transition: ease 0.3s;
  transition: ease 0.3s;
}
/* line 22, ../../scss/app/components/_store-front.scss */
.store-icon-nav li div img {
  border: 0 !important;
}

/* line 27, ../../scss/app/components/_store-front.scss */
.store-icon-nav li span {
  color: black;
  font-weight: bold;
  text-align: center;
  display: block;
 
}

/* line 38, ../../scss/app/components/_store-front.scss */
.store-icon-nav li:hover {
  -ms-transform: translateY(-15px);
  -webkit-transform: translateY(-15px);
  transform: translateY(-15px);
  text-decoration: none;
  font-weight: bold;
}

/* line 44, ../../scss/app/components/_store-front.scss */
/* .store-icon-nav li:hover span { */
 /*color: #a80309;*/
/* } */

/* Nav Ends */
/* Spinner loader styles*/
@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-ms-keyframes spin {
  from {
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spin {
  from {
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/* line 66, ../../scss/app/components/_spinner.scss */
.spinner {
  /* background: url(../../vzrf-extend/images/loading-spinner.png) no-repeat; */
  width: 2.1875rem;
  height: 2.1875rem;
  background-size: 2.1875rem 2.1875rem;
  /* -webkit-animation-name: spin;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: spin;
  -moz-animation-duration: 2s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: spin;
  -ms-animation-duration: 2s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear; */
}

/* line 1, ../../scss/app/components/_custom-colors.scss */
.bg-blue {
  background-color: blue;
}
.bg-sandstone {
  background-color: #e5e6ea !important;
}
.bg-satinnickel {
  background-color: #D4D9D9 !important;
}
/**new color css added*/
.bg-glacierwhite {
  background-color: #e5e6e8 !important;
}
.bg-twilightblue {
  background-color: #49768d !important;
}
.bg-cottonwhite {
  background-color: #e2e2e2 !important;
}

/* line 4, ../../scss/app/components/_custom-colors.scss */
.bg-gray {
  background-color: gray;
}

/* line 7, ../../scss/app/components/_custom-colors.scss */
.bg-pink {
  background-color: pink;
}

/* line 10, ../../scss/app/components/_custom-colors.scss */
.bg-red {
  background-color: red;
}

/* line 13, ../../scss/app/components/_custom-colors.scss */
.bg-teal {
  background-color: teal;
}

/* line 16, ../../scss/app/components/_custom-colors.scss */
.bg-silver {
  background-color: silver;
}

.bg-grey {
  background-color: grey;
}
/* Color swatches for the Sony products.*/
.bg-arcticwhite {
  background-color: #DBDFE8;
}
/* Color swatches for the Sony products.*/
.bg-smokegray {
  background-color: #cfd5d5;
}
/* line 2, ../../scss/app/widgets/_checkoutbox.scss */
.w_guest-checkout {
  width: 50%;
  height: 25rem;
  border: solid 1px #d9d9d9;
  margin-left: 0.9375rem;
  margin-top: 0.3125rem;
  padding: 1.25rem 1.25rem 1.25rem 3.125rem;
  position: relative;
}
@media screen and (max-width: 1280px) {
  /* line 2, ../../scss/app/widgets/_checkoutbox.scss */
  .w_guest-checkout {
    width: 50%;
    height: 21.875rem;
    padding-left: 1.25rem;
  }
}
@media screen and (max-width: 638px) {
  /* line 2, ../../scss/app/widgets/_checkoutbox.scss */
  .w_guest-checkout {
    width: 100%;
    height: auto;
    margin-left: 0;
    margin-top: 0;
    padding: 0;
    border: none;
  }
}
@media only screen and (min-width: 60.625rem) {
  /* line 2, ../../scss/app/widgets/_checkoutbox.scss */
  .w_guest-checkout {
    margin-left: 0;
    background: #f2f2f2;
  }
}

/* line 32, ../../scss/app/widgets/_checkoutbox.scss */
.guest-checkout {
  width: 82.03125%;
  margin-left: 6.25rem;
}
@media screen and (max-width: 1024px) {
  /* line 32, ../../scss/app/widgets/_checkoutbox.scss */
  .guest-checkout {
    width: auto;
    margin-left: 0;
  }
}
@media only screen and (min-width: 60.625rem) {
  /* line 32, ../../scss/app/widgets/_checkoutbox.scss */
  .guest-checkout {
    margin-left: 0;
  }
}

/* line 46, ../../scss/app/widgets/_checkoutbox.scss */
.guest-checkout_name {
  font-size: 1.5rem;
}
@media screen and (max-width: 638px) {
  /* line 46, ../../scss/app/widgets/_checkoutbox.scss */
  .guest-checkout_name {
    background-color: #E2E6E6;
    padding-left: 0.9375rem;
    font-size: 1.4375rem;
    padding-top: 0.9375rem;
    padding-bottom: 0.9375rem;
  }
}

/* line 57, ../../scss/app/widgets/_checkoutbox.scss */
.guest-checkout_login {
  font-size: 0.75rem;
  margin-top: 1.25rem;
}
@media screen and (max-width: 638px) {
  /* line 57, ../../scss/app/widgets/_checkoutbox.scss */
  .guest-checkout_login {
    padding: 0.9375rem;
  }
}

/* line 66, ../../scss/app/widgets/_checkoutbox.scss */
.guest-checkout_button {
  position: absolute;
  bottom: 3.125rem;
}
/* line 70, ../../scss/app/widgets/_checkoutbox.scss */
.guest-checkout_button .button {
  width: 15.625rem;
  /*padding-top:rem-calc(15); // Branding Updates
  font-size: rem-calc(17) !important;
  height: rem-calc(44) !important;
  background: #3C4045;
  border-radius: 3px;*/
}
@media screen and (max-width: 638px) {
  /* line 66, ../../scss/app/widgets/_checkoutbox.scss */
  .guest-checkout_button {
    position: relative;
    bottom: 0;
    padding: 0.9375rem;
  }
}

/* line 86, ../../scss/app/widgets/_checkoutbox.scss */
.checkout .w_guest-checkout {
  margin-left: 0.3125rem;
}
@media screen and (max-width: 638px) {
  /* line 86, ../../scss/app/widgets/_checkoutbox.scss */
  .checkout .w_guest-checkout {
    margin-left: 0;
  }
}

/* line 1, ../../scss/app/widgets/_products.scss */
.w_products-list {
  width: 70%;
  float: left;
  position: relative;
  padding: 0 0.625rem;
}
@media (min-width: 639px) and (max-width: 1023px) {
  /* line 1, ../../scss/app/widgets/_products.scss */
  .w_products-list {
    width: 65%;
  }
}
@media screen and (max-width: 638px) {
  /* line 1, ../../scss/app/widgets/_products.scss */
  .w_products-list {
    width: 100%;
    margin-top: 6.25rem;
  }
}

/* line 16, ../../scss/app/widgets/_products.scss */
.products-list_breadcrumb {
  font-size: 0.875rem;
  margin:1rem 0 0 1.5rem;
}
@media screen and (max-width: 638px) {
  /* line 16, ../../scss/app/widgets/_products.scss */
  .products-list_breadcrumb {
    display: none;
  }
}

/* line 24, ../../scss/app/widgets/_products.scss */
.products-list_breadcrumb a {
  color: #000;
  font-weight:normal;
  text-decoration: underline;
}

/* line 26, ../../scss/app/widgets/_products.scss */
.products-list_offer {
  top: 0.625rem;
  left: 1.5rem;
  z-index: 20;
  width: 6.25rem;
  height: 6.25rem;
  position: absolute;
  list-style-type: none;
  border-radius: 5.375rem;
  background: #000;
  padding: 0.625rem 0 0 0;
  text-align: center;
  /*@media (max-width:638px){
      top: rem-calc(10);
      left: rem-calc(10);
      width: rem-calc(70);
      height: rem-calc(70);
      padding:rem-calc(20) rem-calc(10);
  }*/
}
@media (max-width: 768px) {
  /* line 26, ../../scss/app/widgets/_products.scss */
  .products-list_offer {
    left: 0.875rem;
  }
}
@media only screen and (max-width: 638px) {
  /* line 26, ../../scss/app/widgets/_products.scss */
  .products-list_offer {
    top: 2.5rem;
  }
}
/* line 52, ../../scss/app/widgets/_products.scss */
.products-list_offer .products-list_offer-value {
  display: inline-block;
  font-size: 2rem;
  display: inline-block;
  font-weight: bold;
  line-height: .975;
  padding-top: 12px;
  color:#fff;
  /*@media (max-width:638px){
      font-size: rem-calc(14);
  }*/
}
/* line 64, ../../scss/app/widgets/_products.scss */
.products-list_offer .products-list_offer-unit {
  font-size: 1.25rem;
  font-weight: normal;
  display: inline-block;
  color: #fff;
}
@media (max-width: 638px) {
  /* line 64, ../../scss/app/widgets/_products.scss */
  .products-list_offer .products-list_offer-unit {
    left: 0.3125rem;
    top: -0.5625rem;
    /*font-size: rem-calc(15);*/
  }
}
/* line 76, ../../scss/app/widgets/_products.scss */
.products-list_offer .products-list_offer-off {
  font-size: 1.5rem;
  line-height: 1.375rem;
  text-transform: uppercase;
  display: inline-block;
  text-align: right;
}
@media (max-width: 638px) {
  /* line 76, ../../scss/app/widgets/_products.scss */
  /* .products-list_offer .products-list_offer-off { */
    /*font-size: rem-calc(12);*/
  /* } */
}
/* line 85, ../../scss/app/widgets/_products.scss */
.products-list_offer .products-list_offer-off div {
  /* position:relative;
   left: rem-calc(6);*/
  font-weight: bold;
  text-align: right;
  font-size: 1.25rem;
  line-height: 15px;
}

/* ===============================================================
Your custom styles, here you need to specify container's and slide's
sizes, pagination, etc.
================================================================*/
/* ===============================================================
Pagination Styles
================================================================*/
/* line 12, ../../scss/app/widgets/_tpcarousel.scss */
.products-list_carousel .carousel_pager-item {
  /* Stylize pagination button: */
  border-radius: 0;
  background-color: transparent;
  box-shadow: none;
  vertical-align: bottom;
  width: 15.5rem !important;
  height: 2.8125rem !important;
  background-size: 40px 40px !important;
}
@media screen and (max-width: 638px) {
  /* line 12, ../../scss/app/widgets/_tpcarousel.scss */
  .products-list_carousel .carousel_pager-item {
    height: 1px !important;
    vertical-align: top;
  }
  .products-list_carousel .carousel_pager-item{
    height: 3px !important;
    background:#000 !important;
  }
}
@media screen and (max-width: 450px) {
  /* line 12, ../../scss/app/widgets/_tpcarousel.scss */
  .products-list_carousel .carousel_pager-item {
    width: 40px !important;
  }
}
/* line 31, ../../scss/app/widgets/_tpcarousel.scss */
.products-list_carousel .carousel_pager-item img {
  width: 3rem;
  height: 3rem;
}
/* line 36, ../../scss/app/widgets/_tpcarousel.scss */
.products-list_carousel .carousel_pager-item.visible {
  background-color: white;
}
/* line 40, ../../scss/app/widgets/_tpcarousel.scss */
.products-list_carousel .carousel_pager-item.active {
  box-shadow: none;
  height: 3.8125rem;
  width: 20.8125rem !important;
}
@media screen and (max-width: 638px) {
  /* line 40, ../../scss/app/widgets/_tpcarousel.scss */
  .products-list_carousel .carousel_pager-item.active {
    background-color: #cd040b !important;
    border: 0px;
    border-bottom: 2px solid #cd040b;
  }
}
/* line 52, ../../scss/app/widgets/_tpcarousel.scss */
.products-list_carousel .carousel_item {
  /* Specify Slides's Size: */
  width: 100%;
  /*height: 280px;*/
  border-radius: 0;
}
/* line 58, ../../scss/app/widgets/_tpcarousel.scss */
.products-list_carousel .carousel_item.active {
  /* Specific active slide styling: */
  box-shadow: none;
}
/* line 63, ../../scss/app/widgets/_tpcarousel.scss */
/* .products-list_carousel .carousel_item.visible { */
  /* Specific visible slide styling: */
/* } */
@media screen and (max-width: 638px) {
  /* line 63, ../../scss/app/widgets/_tpcarousel.scss */
  /* .products-list_carousel .carousel_item.visible { */
    /*width:240px !important;*/
  /* } */
}
/* line 69, ../../scss/app/widgets/_tpcarousel.scss */
.products-list_carousel .carousel_item img {
  margin: 0 auto;
  width: 20rem;
  height: 20rem;
}
@media (max-width: 638px) {
  /* line 69, ../../scss/app/widgets/_tpcarousel.scss */
  .products-list_carousel .carousel_item img {
    margin-top: 2%;
  }
}
/* line 79, ../../scss/app/widgets/_tpcarousel.scss */
.products-list_carousel .carousel_item .row {
  margin-bottom: 1.5rem;
}
/* line 84, ../../scss/app/widgets/_tpcarousel.scss */
.products-list_carousel .carousel_pager-item {
  /* border: 0.0625rem solid #bbb; */
}
/* line 87, ../../scss/app/widgets/_tpcarousel.scss */
.products-list_carousel .carousel_pager-item.active {
  /* border: 0.0625rem solid #000; */
}
/* line 91, ../../scss/app/widgets/_tpcarousel.scss */
.products-list_carousel .carousel_pager-item .carousel_item {
  background: #f6f6f6;
}
/* line 94, ../../scss/app/widgets/_tpcarousel.scss */
.products-list_carousel .carousel_pager-item .carousel_item.visible {
  background: #f6f6f6;
}
/* line 98, ../../scss/app/widgets/_tpcarousel.scss */
.products-list_carousel .carousel_pager-item .carousel_item.active {
  background: #f6f6f6;
}

/* line 106, ../../scss/app/widgets/_tpcarousel.scss */
/* .carousel_pager-list { */
  /*@media screen and (max-width: 638px) {display:none;}*/
/* } */

/* line 110, ../../scss/app/widgets/_tpcarousel.scss */
.carousel-prev,
.carousel-next {
  color: #4b4b4b;
  display: inline-block;
}
/* line 115, ../../scss/app/widgets/_tpcarousel.scss */
.carousel-prev:hover,
.carousel-next:hover {
  color: #333;
  cursor: pointer;
}

/* line 121, ../../scss/app/widgets/_tpcarousel.scss */
.carousel-prev {
  /*float:left;
  position:absolute;
  top:45%;
  left:20px;*/
  display: inline-block;
}
@media screen and (max-width: 638px) {
  /* line 121, ../../scss/app/widgets/_tpcarousel.scss */
  .carousel-prev {
    top: 52%;
  }
}
/* line 133, ../../scss/app/widgets/_tpcarousel.scss */
.carousel-prev.disable {
  opacity: 0.5;
  cursor: default;
}

/* line 139, ../../scss/app/widgets/_tpcarousel.scss */
/* .carousel-next { */
  /*float:right;
  position:absolute;
  top:45%;
  right:20px;*/
/* } */
@media screen and (max-width: 638px) {
  /* line 139, ../../scss/app/widgets/_tpcarousel.scss */
  .carousel-next {
    top: 52%;
  }
}
/* line 148, ../../scss/app/widgets/_tpcarousel.scss */
.carousel-next.disable {
  opacity: 0.5;
  cursor: default;
}

/* line 156, ../../scss/app/widgets/_tpcarousel.scss */
.products-list_carousel {
  /* Specify Swiper's Size: */
  width: 65%;
  margin: auto;
  margin-top: 30px;
}
@media screen and (max-width: 638px) {
  /* line 156, ../../scss/app/widgets/_tpcarousel.scss */
  .products-list_carousel {
    width: 70% !important;
  }
}
/* line 166, ../../scss/app/widgets/_tpcarousel.scss */
.products-list_carousel .carousel_pager-prev, .products-list_carousel .carousel_pager-next {
  display: inline-block;
  float: none;
  position: relative;
}
@media screen and (max-width: 638px) {
  /* line 166, ../../scss/app/widgets/_tpcarousel.scss */
  .products-list_carousel .carousel_pager-prev, .products-list_carousel .carousel_pager-next {
    top: -0.5625rem;
  }
}

/*@media screen and (max-width: 638px) {
    .products-list_carousel .w_carousel {
        height: 210px;
    }
}*/
@media only screen and (min-width: 980px) {
  /* line 182, ../../scss/app/widgets/_tpcarousel.scss */
  .carousel_pager-list {
    margin: 0 0.9375rem;
    width: 50%;
  }
}

/* line 189, ../../scss/app/widgets/_tpcarousel.scss */
.hero-carousel {
  max-width: 85rem;
}
/* line 192, ../../scss/app/widgets/_tpcarousel.scss */
.hero-carousel .w_carousel {
  height: 31.25rem !important;
   z-index: 100;   /* CETC-23665 fixed by DESIGN DEV*/
}
/* line 196, ../../scss/app/widgets/_tpcarousel.scss */
.hero-carousel .m_carousel_pager-nav-icons {
  position: absolute !important;
  top: 40%;
  left: 0;
  z-index: 999;
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  /* line 196, ../../scss/app/widgets/_tpcarousel.scss */
  .hero-carousel .m_carousel_pager-nav-icons {
    padding: 0 0.9375rem;
  }
}
/* line 205, ../../scss/app/widgets/_tpcarousel.scss */
.hero-carousel .m_carousel_pager-nav-icons .carousel_pager-prev {
  left: -3.75rem;
}
@media only screen and (max-width: 767px) {
  /* line 205, ../../scss/app/widgets/_tpcarousel.scss */
  .hero-carousel .m_carousel_pager-nav-icons .carousel_pager-prev {
    left: 0.1875rem;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  /* line 205, ../../scss/app/widgets/_tpcarousel.scss */
  .hero-carousel .m_carousel_pager-nav-icons .carousel_pager-prev {
    left: 0.5rem;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1050px) {
  /* line 205, ../../scss/app/widgets/_tpcarousel.scss */
  .hero-carousel .m_carousel_pager-nav-icons .carousel_pager-prev {
    margin-left: 0.625rem;
  }
}
@media only screen and (max-width: 767px) {
  /* line 221, ../../scss/app/widgets/_tpcarousel.scss */
  .hero-carousel .m_carousel_pager-nav-icons .carousel_pager-next {
    right: 0.1875rem;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  /* line 221, ../../scss/app/widgets/_tpcarousel.scss */
  .hero-carousel .m_carousel_pager-nav-icons .carousel_pager-next {
    margin-right: 0.625rem;
  }
}
@media only screen and (min-width: 1024px) {
  /* line 221, ../../scss/app/widgets/_tpcarousel.scss */
  .hero-carousel .m_carousel_pager-nav-icons .carousel_pager-next {
    right: 0.9375rem;
    position: relative;
  }
}

/* line 247, ../../scss/app/widgets/_tpcarousel.scss */
.carousel-wrap {
  max-width: 61.5625rem;
  margin: 0 auto;
}
/* line 251, ../../scss/app/widgets/_tpcarousel.scss */
.carousel-wrap .m_carousel_pager-nav-icons {
  position: absolute !important;
  top: 35%;
  width: 100%;
  left: 0;
}
/* line 258, ../../scss/app/widgets/_tpcarousel.scss */
.carousel-wrap .m_carousel_pager-nav-icons .carousel_pager-prev {
  left: 0.3125rem;
  position: relative;
}
/* line 263, ../../scss/app/widgets/_tpcarousel.scss */
.carousel-wrap .m_carousel_pager-nav-icons .carousel_pager-next {
  right: 0.9375rem;
  position: relative;
}
@media only screen and (max-width: 639px) {
  /* line 269, ../../scss/app/widgets/_tpcarousel.scss */
  .carousel-wrap .carousel_pager {
    display: none;
  }
}

/* line 277, ../../scss/app/widgets/_tpcarousel.scss */
.m_carousel-features {
  height: 30.125rem !important;
}
/* line 280, ../../scss/app/widgets/_tpcarousel.scss */
.m_carousel-features .carousel_pager-next, .m_carousel-features .carousel_pager-prev {
  top: 45%;
}
/* line 284, ../../scss/app/widgets/_tpcarousel.scss */
.m_carousel-features .tpgrid_item {
  background: #f6f6f6;
}

@media only screen and (max-width: 639px) {
  /* line 292, ../../scss/app/widgets/_tpcarousel.scss */
  .m_carousel-features {
    height: auto !important;
  }
  /* line 295, ../../scss/app/widgets/_tpcarousel.scss */
  .m_carousel-features + .carousel_pager {
    display: none;
  }
  /* line 299, ../../scss/app/widgets/_tpcarousel.scss */
  .m_carousel-features .tpgrid_item {
    border: 0;
    margin-bottom: 0.625rem;
  }
  /* line 304, ../../scss/app/widgets/_tpcarousel.scss */
  .m_carousel-features .tpgrid_placeholder {
    margin-top: 0;
    margin-left: 0;
  }
  /* line 309, ../../scss/app/widgets/_tpcarousel.scss */
  .m_carousel-features .carousel_pager-next, .m_carousel-features .carousel_pager-prev {
    display: none;
  }
  /* line 313, ../../scss/app/widgets/_tpcarousel.scss */
  .m_carousel-features .carousel_list {
    width: 100% !important;
    -webkit-transform: translate3d(0px, 0px, 0px) !important;
    -moz-transform: translate3d(0px, 0px, 0px) !important;
    -o-transform: translate3d(0px, 0px, 0px) !important;
    -ms-transform: translate3d(0px, 0px, 0px) !important;
    transform: translate3d(0px, 0px, 0px) !important;
  }
  /* line 322, ../../scss/app/widgets/_tpcarousel.scss */
  .m_carousel-features .carousel_item {
    width: 100% !important;
  }
  /* line 326, ../../scss/app/widgets/_tpcarousel.scss */
  .m_carousel-features .carousel_pager-list {
    display: none !important;
  }
}
/* line 332, ../../scss/app/widgets/_tpcarousel.scss */
.thumbnail-pagination {
  width: auto;
  text-align: center;
  height: 73px;
}

/* line 337, ../../scss/app/widgets/_tpcarousel.scss */
.seperate_prev, .seperate_next {
  display: inline-block;
  width: 12%;
  vertical-align: middle;
  text-align: center;
}

/* line 344, ../../scss/app/widgets/_tpcarousel.scss */
.carousel_pager {
  z-index: 1;
  overflow: hidden;
  position: relative;
  display: inline-block;
  margin: 0.75rem 0px 1rem;
  vertical-align: middle;
  text-align: center;
}

/* line 355, ../../scss/app/widgets/_tpcarousel.scss */
.products-list_carousel .carousel_pager {
     width: 220px;
}

/* line 360, ../../scss/app/widgets/_tpcarousel.scss */
.carousel_pager-list {
  width: 100%;
  white-space: nowrap;
  display: inline-block;
  margin: 0px;
  text-align: center;
  height: 100%;
}

/* line 368, ../../scss/app/widgets/_tpcarousel.scss */
.move_next1 {
  -webkit-transform: translate3d(-206px, 0, 0);
  -ms-transform: translate3d(-206px, 0, 0);
  transform: translate3d(-206px, 0, 0);
    /* @media only screen and (max-width:639px){
-webkit-transform: translate3d(-130px,0,0);
     -ms-transform: translate3d(-130px,0,0);
     transform: translate3d(-130px,0,0);
        }*/
}

/* line 379, ../../scss/app/widgets/_tpcarousel.scss */
.move_next2 {
  -webkit-transform: translate3d(-412px, 0, 0);
  -ms-transform: translate3d(-412px, 0, 0);
  transform: translate3d(-412px, 0, 0);
    /*  @media only screen and (max-width:639px){
-webkit-transform: translate3d(-275px,0,0);
     -ms-transform: translate3d(-275px,0,0);
     transform: translate3d(-275px,0,0);
        }*/
}

/* line 390, ../../scss/app/widgets/_tpcarousel.scss */
.move_next3 {
  -webkit-transform: translate3d(-575px, 0, 0);
  -ms-transform: translate3d(-575px, 0, 0);
  transform: translate3d(-575px, 0, 0);
    /*  @media only screen and (max-width:639px){
-webkit-transform: translate3d(-412px,0,0);
     -ms-transform: translate3d(-412px,0,0);
     transform: translate3d(-412px,0,0);
        }*/
}

/* line 401, ../../scss/app/widgets/_tpcarousel.scss */
.move_prev1 {
  -webkit-transform: translate3d(0px, 0, 0);
  -ms-transform: translate3d(0px, 0, 0);
  transform: translate3d(0px, 0, 0);
    /* @media only screen and (max-width:639px){
-webkit-transform: translate3d(0px,0,0);
     -ms-transform: translate3d(0px,0,0);
     transform: translate3d(0px,0,0);
        }*/
}

/* line 412, ../../scss/app/widgets/_tpcarousel.scss */
.move_prev2 {
  -webkit-transform: translate3d(-260px, 0, 0);
  -ms-transform: translate3d(-260px, 0, 0);
  transform: translate3d(-260px, 0, 0);
    /*  @media only screen and (max-width:639px){
-webkit-transform: translate3d(-130px,0,0);
     -ms-transform: translate3d(-130px,0,0);
     transform: translate3d(-130px,0,0);
        }*/
}

/* line 423, ../../scss/app/widgets/_tpcarousel.scss */
.move_prev3 {
  -webkit-transform: translate3d(-420px, 0, 0);
  -ms-transform: translate3d(-420px, 0, 0);
  transform: translate3d(-420px, 0, 0);
    /*   @media only screen and (max-width:639px){
-webkit-transform: translate3d(-275px,0,0);
     -ms-transform: translate3d(-275px,0,0);
     transform: translate3d(-275px,0,0);
        }*/
}

/* line 434, ../../scss/app/widgets/_tpcarousel.scss */
.carousel_pager-prev.disable {
  opacity: 0.5;
  cursor: not-allowed !important;
}

/* line 439, ../../scss/app/widgets/_tpcarousel.scss */
.carousel_pager-prev.disable:hover {
  opacity: 0.5;
  cursor: not-allowed !important;
  background-color: #959595;
}

/* line 445, ../../scss/app/widgets/_tpcarousel.scss */
.carousel_pager-next.disable {
  opacity: 0.5;
  cursor: not-allowed !important;
  background-color: #959595;
}

/* line 451, ../../scss/app/widgets/_tpcarousel.scss */
.carousel_pager-next.disable:hover {
  opacity: 0.5;
  cursor: not-allowed !important;
  background-color: #959595;
}

/* line 457, ../../scss/app/widgets/_tpcarousel.scss */
.featured-accessories {
  max-width: 80rem;
  margin: 0 auto;
  position: relative;
}
.featured-accessories-items {
  width: 238px !important;
}

/* line 462, ../../scss/app/widgets/_tpcarousel.scss */
.hero-carousel-wraper {
  max-width: 80rem;
  margin: 0 auto;
}

/* line 1, ../../scss/app/widgets/_productdetails.scss */
.w_products-details {
  padding: 0px;
  border-left: 1px solid #BBB;
  width: 30%;
  float: left;
  min-height: 33.4375rem;
}
@media (min-width: 639px) and (max-width: 1023px) {
  /* line 1, ../../scss/app/widgets/_productdetails.scss */
  .w_products-details {
    width: 35%;
  }
}
@media screen and (min-width: 1024px) {
  /* line 1, ../../scss/app/widgets/_productdetails.scss */
  .w_products-details {
    position: relative;
  }
}
@media screen and (max-width: 638px) {
  /* line 1, ../../scss/app/widgets/_productdetails.scss */
  .w_products-details {
    width: 100%;
    min-height: 18.75rem;
  }
}

/* line 22, ../../scss/app/widgets/_productdetails.scss */
.products-details_list {
  padding: 0px 0.9375rem;
  /*min-height:rem-calc(190px)*/
}

/* line 26, ../../scss/app/widgets/_productdetails.scss */
.products-details_heading {
  font-size: 1.375rem;
  padding: 1.25rem 0px 0px 0.9375rem;
}
@media screen and (max-width: 638px) {
  /* line 26, ../../scss/app/widgets/_productdetails.scss */
  .products-details_heading {
    position: absolute;
    top: 1.25rem;
  }
}
@media screen and (max-width: 500px) {
  /* line 26, ../../scss/app/widgets/_productdetails.scss */
  .products-details_heading {
    position: absolute;
    top: 0;
  }
}

/* line 41, ../../scss/app/widgets/_productdetails.scss */
.products-details_cart {
  min-height: 9.375rem;
  width: 100%;
  padding: 0.9375rem;
  background-color: #BBB;
  position: absolute;
  bottom: 0px;
}
@media screen and (max-width: 1024px) {
  /* line 41, ../../scss/app/widgets/_productdetails.scss */
  .products-details_cart {
    position: relative;
    min-height: 7.5rem;
  }
}

/* line 58, ../../scss/app/widgets/_productdetails.scss */
.products-details_price {
  font-size: 2.125rem;
}
@media screen and (max-width: 1024px) {
  /* line 58, ../../scss/app/widgets/_productdetails.scss */
  .products-details_price {
    margin-bottom: 1.25rem;
  }
}

/* line 66, ../../scss/app/widgets/_productdetails.scss */
.products-details_addcart {
  margin-top: 1.875rem;
  padding: 0px 25px !important;
  bottom: 0.9375rem;
  position: absolute;
  float: right;
  right: 0.9375rem;
}
@media screen and (max-width: 1024px) {
  /* line 66, ../../scss/app/widgets/_productdetails.scss */
  .products-details_addcart {
    font-size: 1.0625rem;
    position: relative;
    width: 100% !important;
    margin-left: 0.9375rem;
  }
}
@media (min-width: 768px) and (max-width: 900px) {
  /* line 66, ../../scss/app/widgets/_productdetails.scss */
  .products-details_addcart {
    float: none;
    margin-bottom: 0px;
    bottom: 0px;
  }
}
@media screen and (max-width: 900px) {
  /* line 66, ../../scss/app/widgets/_productdetails.scss */
  .products-details_addcart {
    float: none !important;
    width: 100% !important;
  }
}
@media (min-width: 901px) and (max-width: 1024px) {
  /* line 66, ../../scss/app/widgets/_productdetails.scss */
  .products-details_addcart {
    float: none !important;
  }
}

/*.products-details_addcart{
    min-height:rem-calc(74); font-size:rem-calc(14) !important; float:right; margin-bottom:0 !important; padding-top:rem-calc(40) !important; width:50% !important; text-align:left !important;
    background: -webkit-linear-gradient(to bottom, #F00 0%, #C00 100%) repeat scroll 0% 0% transparent;
    background: -moz-linear-gradient(to bottom, #F00 0%, #C00 100%) repeat scroll 0% 0% transparent;
    background: linear-gradient(to bottom, #F00 0%, #C00 100%) repeat scroll 0% 0% transparent;
    box-shadow: 0 1px 1px rgba(255, 255, 255, 0.4) inset, 0px -1px 2px rgba(0, 0, 0, 0.1) inset;
    border:1px solid rgba(0, 0, 0, 0.4)!important;
    height: 2.75rem;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.15s ease 0s;
    outline: medium none;
    -moz-user-select: none;
    line-height: 1.2;
    color: #FFF;
    font-family: arial !important;
    font-weight:bold;
    display: inline-block;
    padding: rem-calc(45) rem-calc(7) 0 rem-calc(7) !important;
    border-radius: 4px;

@media screen and (max-width: 1024px){
   width:100% !important;
   margin-top:rem-calc(20)
}

}*/
/* line 123, ../../scss/app/widgets/_productdetails.scss */
.product-details_sale {
  font-size: 0.875rem;
}
/* line 126, ../../scss/app/widgets/_productdetails.scss */
.product-details_sale .product-details_actual-price {
  text-decoration: line-through;
}
/* line 130, ../../scss/app/widgets/_productdetails.scss */
.product-details_sale .sale_text {
  line-height: 26px;
}

/*.products-details_addcart:hover{  
	background:-webkit-linear-gradient(#e50000,#b30000);
	background:-moz-linear-gradient(#e50000,#b30000);
	background:-o-linear-gradient(#e50000,#b30000);
	background:linear-gradient(#e50000,#b30000);
}*/
/*.w_modal {
    top: 15% !important;

    &.active {
        z-index: 10100 !important;
    }

    @media (min-width:768px) {
        top: 25% !important;
    }
}*/
/* line 152, ../../scss/app/widgets/_productdetails.scss */
.product-details_discount-price {
  font-size: 1.5rem;
  color:#000;
}

/* line 155, ../../scss/app/widgets/_productdetails.scss */
.product-details_limited {
  padding-top: 2.25rem;
  padding-bottom: 1.6875rem;
  clear: both;
}

/* line 2, ../../scss/app/widgets/_productdescription.scss */
.w_products-description {
  padding: 1.25rem;
  margin-top: 0.9375rem;
}
@media screen and (max-width: 638px) {
  /* line 2, ../../scss/app/widgets/_productdescription.scss */
  .w_products-description {
    padding: 0px 1rem !important;
  }
}

/* line 13, ../../scss/app/widgets/_productdescription.scss */
.products-description_wrap [data-tabs-content] {
  clear: both;
}
@media screen and (max-width: 638px) {
  /* line 12, ../../scss/app/widgets/_productdescription.scss */
  .products-description_wrap {
    display: none;
  }
}

/* line 22, ../../scss/app/widgets/_productdescription.scss */
.products-description_accordion {
  display: none;
}
@media screen and (max-width: 638px) {
  /* line 22, ../../scss/app/widgets/_productdescription.scss */
  .products-description_accordion {
    display: block;
  }
}
@media screen and (max-width: 638px) and (min-width: 601px) and (max-width: 638px) {
  /* line 28, ../../scss/app/widgets/_productdescription.scss */
  .products-description_accordion li {
    padding-left: 1.25rem;
  }
}
@media screen and (max-width: 638px) {
  /* line 33, ../../scss/app/widgets/_productdescription.scss */
  .products-description_accordion li.m_accordion-summary {
    font-weight: bold;
  }
}
@media screen and (max-width: 638px) and (min-width: 601px) and (max-width: 638px) {
  /* line 35, ../../scss/app/widgets/_productdescription.scss */
  .products-description_accordion li.m_accordion-summary:before {
    right: 1.5625rem;
  }
}
@media screen and (max-width: 638px) and (min-width: 601px) and (max-width: 638px) {
  /* line 42, ../../scss/app/widgets/_productdescription.scss */
  .products-description_accordion li.m_accordion-details {
    padding-left: 1.25rem;
  }
}
@media screen and (max-width: 638px) {
  /* line 49, ../../scss/app/widgets/_productdescription.scss */
  .products-description_accordion li.open {
    color: #cd040b;
  }
  /* line 52, ../../scss/app/widgets/_productdescription.scss */
  .products-description_accordion li.open:hover {
    color: #cd040b;
  }
  /* line 56, ../../scss/app/widgets/_productdescription.scss */
  .products-description_accordion li.open + .m_accordion-details {
    padding-top: 0.9375rem !important;
  }
  /* line 59, ../../scss/app/widgets/_productdescription.scss */
  .products-description_accordion li.open + .m_accordion-details.padding-all-zero {
    padding-top: 0 !important;
  }
}

/*.products-description_tab {
    @media only screen and (max-width:638px) {
        display: none;
    }

    & + [data-tabs-content-wrap] {
        @media only screen and (max-width:638px) {
            display: none;
        }
    }
}*/
@media only screen and (max-width: 638px) {
  /* line 83, ../../scss/app/widgets/_productdescription.scss */
  .m_product-details + [data-tabs-content-wrap] {
    display: none;
  }
}

/* line 90, ../../scss/app/widgets/_productdescription.scss */
.product_promo-section {
  margin-bottom: 1.5rem;
}
/* line 93, ../../scss/app/widgets/_productdescription.scss */
.product_promo-section .vzicon {
  width: 1.875rem;
  height: 1.875rem;
  background: #cd040b;
  color: #fff;
  text-align: center;
  vertical-align: top;
}
/* line 101, ../../scss/app/widgets/_productdescription.scss */
.product_promo-section .vzicon:before {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  display: inline-block;
  font-size: 1.125rem;
}

/* line 113, ../../scss/app/widgets/_productdescription.scss */
.product_promo-text {
  position: relative;
  top: 0.3125rem;
}

/*.products-description_tab{
    position: relative;
    float: left;
    background:$grey-6;
    width:80%; 
    border-bottom:rem-calc(1) solid $grey-5 !important;
    margin-bottom:rem-calc(25) !important;
    margin-top:rem-calc(15) !important;
    &.m_product-details{ 
        width:auto;
        & li{
            width:auto !important;
            &:hover{
                width:auto !important;
            }
        }
    }
  @media (max-width:861px){
      
  }
  @media (max-width:637px){
      width:90% !important;
      margin:0 auto;
      float:none;
      border-bottom:rem-calc(1) solid $white !important;
  }
                                                 
} 
.products-description_tab li {
    float: left;
    display:inline-block;
    @media (max-width:637px){
        float:none;
    }
}
.products-description_tab .tabs_tab.active {
    border: 0px none !important;
    border-top:rem-calc(3) solid $brand-1 !important;
    border-bottom:0 !important;
    border-right:rem-calc(1) solid $grey-5 !important;
    border-left:rem-calc(1) solid $grey-5 !important;
    background: none repeat scroll 0% 0% $white !important;
    border-bottom:rem-calc(1) solid $white !important;
    position:relative;
    top:rem-calc(1);
    &:hover{
        border: 0px none !important;
        border-top:rem-calc(3) solid $brand-1 !important;
         border-bottom:rem-calc(1) solid $white !important;
        border-right:rem-calc(1) solid $grey-5 !important;
        border-left:rem-calc(1) solid $grey-5 !important;
        background: none repeat scroll 0% 0% $white !important;
    }
    &:first-child{
        border-left:0 !important;
    }
    &:last-child{
        border-right:0 !important;
    }
}
.products-description_tab .tabs_tab, .products-description_tab .tabs_tab:hover {
    border: 0px none;
    border-top:rem-calc(3) solid transparent !important;   
    border-right:rem-calc(1) solid transparent !important;
    border-left:rem-calc(1) solid transparent !important;
    padding-left:rem-calc(20) !important;
    padding-right:rem-calc(80) !important;
    padding-top:rem-calc(15);
    padding-bottom:0 !important;
    text-transform:none !important;
    background:transparent !important;
    position:relative;
    top:rem-calc(1);
    height:rem-calc(60);
    width:50% !important;
    
    @media (max-width:637px){
      display:table-cell;
     vertical-align:middle;
     border-bottom:rem-calc(1) solid $grey-5 !important;
     padding-top:0 !important;
    }

    &:first-child{
        border-left:0 !important;
    }
    &:last-child{
        border-right:0 !important;
    }
    @media (max-width:637px){
         padding-right:0 !important;
         width:50% !important;
       
      }

}
.products-description_tab-content{
    @media (max-width:480px){
        padding-left:rem-calc(15);
    }
}*/
/* line 218, ../../scss/app/widgets/_productdescription.scss */
.products-description_title {
  padding-bottom: 0.9375rem;
  padding-top: 0.3125rem;
  font-weight: bold;
}

/* line 224, ../../scss/app/widgets/_productdescription.scss */
.products-description_details {
  padding-bottom: 0.9375rem;
}

/* line 228, ../../scss/app/widgets/_productdescription.scss */
.products-overview_lists li {
  margin-bottom: 0.9375rem;
}

@media screen and (max-width: 638px) {
  /* line 234, ../../scss/app/widgets/_productdescription.scss */
  /* .w_list-control > li.m_single > * {
    padding-left:rem-calc(25)
  } */
}
/* Accordian styles Starts*/
@media (max-width: 638px) {
  /* line 241, ../../scss/app/widgets/_productdescription.scss */
  .m_accordion-summary {
    line-height: 1.9375rem !important;
  }
  /* line 243, ../../scss/app/widgets/_productdescription.scss */
  .m_accordion-summary:active {
    background: #fff !important;
  }
  /* line 246, ../../scss/app/widgets/_productdescription.scss */
  .m_accordion-summary span {
    /*font-size: rem-calc(21) !important;*/
    line-height: 2.25rem !important;
  }
  /* line 251, ../../scss/app/widgets/_productdescription.scss */
  .m_accordion-summary.open {
    border-bottom: 0.0625rem solid #cccccc !important;
  }
  /* line 254, ../../scss/app/widgets/_productdescription.scss */
  .m_accordion-summary.open span {
    color: #000;
  }

    /*.w_list-control .m_accordion-summary:before {
       
        content: '+';
        font-weight: bold;
        font-size: rem-calc(25);
        line-height: rem-calc(15);
    }

    .w_list-control .m_accordion-summary.open::before {
        content: '-';
        font-weight: bold;
        color: $brand-1;
        top: 35%;
        font-size: rem-calc(30);
        line-height: rem-calc(22);
    }*/
}
/* line 279, ../../scss/app/widgets/_productdescription.scss */
.w_list-control .m_accordion-details:active {
  background: inherit !important;
}
@media only screen and (min-width: 48rem) {
  /* line 279, ../../scss/app/widgets/_productdescription.scss */
  .w_list-control .m_accordion-details:active {
    background-color: #f2f2f2 !important;
  }
}

/* Accordian styles Starts*/
/* line 1, ../../scss/app/widgets/_otherproducts.scss */
.w_others {
  padding: 0;
}
@media screen and (max-width: 638px) {
  /* line 1, ../../scss/app/widgets/_otherproducts.scss */
  .w_others {
    padding: 0px;
  }
}
@media screen and (max-width: 1024px) {
  /* line 1, ../../scss/app/widgets/_otherproducts.scss */
  .w_others {
    padding: 0px;
  }
}
/* line 11, ../../scss/app/widgets/_otherproducts.scss */
.w_others .tpgrid_item {
  border-top: 1px solid #cccccc;
}

/* line 13, ../../scss/app/widgets/_otherproducts.scss */
.others-product {
  position: relative;
  width: 100%;
  float: left;
}
h2.others-product-title {
  margin-top: 0rem !important;
}
/* line 14, ../../scss/app/widgets/_otherproducts.scss */
.others-product-title {
  font-size: 2.25rem;
}
@media screen and (max-width: 1024px) {
  /* line 14, ../../scss/app/widgets/_otherproducts.scss */
  .others-product-title {
    padding-left: 0;
  }
}

/* line 2, ../../scss/app/widgets/_ordersummary.scss */
.w_order-summary {
  margin: 1.25rem 1.25rem;
  clear: both;
  padding-left: 2rem;
  padding-right: 2rem;
}
@media screen and (max-width: 1023px) {
  /* line 2, ../../scss/app/widgets/_ordersummary.scss */
  .w_order-summary {
    margin: 0px;
  }
}
@media only screen and (min-width: 60.625rem) {
  /* line 2, ../../scss/app/widgets/_ordersummary.scss */
  .w_order-summary {
    margin: 0 0;
  }
}

/* line 15, ../../scss/app/widgets/_ordersummary.scss */
.order-summary-title {
  font-size: 1.5rem;
  margin-left: 0;
}
@media (min-width: 639px) and (max-width: 1023px) {
  /* line 15, ../../scss/app/widgets/_ordersummary.scss */
  .order-summary-title {
    margin-left: 0.9375rem;
  }
}
@media (min-width: 320px) and (max-width: 638px) {
  /* line 15, ../../scss/app/widgets/_ordersummary.scss */
  .order-summary-title {
    margin-left: 1.25rem;
  }
}

/* line 29, ../../scss/app/widgets/_ordersummary.scss */
.order-summary-lists {
  border: 1px solid #cccccc;
}
@media (min-width: 639px) and (max-width: 1023px) {
  /* line 29, ../../scss/app/widgets/_ordersummary.scss */
  .order-summary-lists {
    margin: 0px;
  }
}
@media (min-width: 320px) and (max-width: 638px) {
  /* line 29, ../../scss/app/widgets/_ordersummary.scss */
  .order-summary-lists {
    border-top: 1px solid #cccccc;
    border-left: 0px;
    border-right: 0px;
    border-bottom: 0px;
    margin: 0px 0.9375rem;
  }
}

/* line 45, ../../scss/app/widgets/_ordersummary.scss */
.order-summary-header {
  background: #f6f6f6;
  width: 100%;
  margin: 0px auto;
  height: 3.125rem;
  font-size: 1.125rem;
  font-weight: bold;
}
@media (min-width: 320px) and (max-width: 638px) {
  /* line 45, ../../scss/app/widgets/_ordersummary.scss */
  .order-summary-header {
    display: none;
  }
}

/* line 58, ../../scss/app/widgets/_ordersummary.scss */
.order-summary_note {
  padding-left: 3.125rem;
  padding-top: 1.25rem;
}
@media (min-width: 639px) and (max-width: 1023px) {
  /* line 58, ../../scss/app/widgets/_ordersummary.scss */
  .order-summary_note {
    padding-left: 2.5rem;
  }
}
@media (min-width: 320px) and (max-width: 638px) {
  /* line 58, ../../scss/app/widgets/_ordersummary.scss */
  .order-summary_note {
    padding-left: 0.625rem;
  }
}

/* line 72, ../../scss/app/widgets/_ordersummary.scss */
.order-summary-products .order-summary-items:first-child {
  padding-left: 0.625rem;
}
@media (min-width: 639px) and (max-width: 1023px) {
  /* line 72, ../../scss/app/widgets/_ordersummary.scss */
  .order-summary-products .order-summary-items:first-child {
    padding-left: 2.5rem;
  }
}
@media (min-width: 320px) and (max-width: 638px) {
  /* line 72, ../../scss/app/widgets/_ordersummary.scss */
  .order-summary-products .order-summary-items:first-child {
    padding-left: 0.625rem;
  }
}

/* line 85, ../../scss/app/widgets/_ordersummary.scss */
.order-summary_qty-label {
  display: none;
}
@media (min-width: 320px) and (max-width: 638px) {
  /* line 85, ../../scss/app/widgets/_ordersummary.scss */
  .order-summary_qty-label {
    display: block;
    float: left;
  }
}

/* line 94, ../../scss/app/widgets/_ordersummary.scss */
.order-summary_each-label {
  display: block;
}
@media (min-width: 320px) and (max-width: 638px) {
  /* line 94, ../../scss/app/widgets/_ordersummary.scss */
  .order-summary_each-label {
    display: block;
    float: right;
    font-size: 1.1875rem;
    clear: both;
  }
}

/* line 105, ../../scss/app/widgets/_ordersummary.scss */
.order-summary_price-label {
  display: none;
}
@media (min-width: 320px) and (max-width: 638px) {
  /* line 105, ../../scss/app/widgets/_ordersummary.scss */
  .order-summary_price-label {
    display: block;
    font-size: 1.1875rem;
    float: left;
    padding-right: 0.9375rem;
  }
}

/* line 116, ../../scss/app/widgets/_ordersummary.scss */
.order-summary-items:first-child {
  width: 55%;
  padding-left: 3.125rem;
  text-align: left;
}
@media (min-width: 639px) and (max-width: 1023px) {
  /* line 116, ../../scss/app/widgets/_ordersummary.scss */
  .order-summary-items:first-child {
    padding-left: 2.5rem;
  }
}
@media (min-width: 320px) and (max-width: 638px) {
  /* line 116, ../../scss/app/widgets/_ordersummary.scss */
  .order-summary-items:first-child {
    padding-left: 0.625rem;
    float: none;
    width: 95%;
  }
}

/* line 132, ../../scss/app/widgets/_ordersummary.scss */
.order-summary-items {
  width: 15%;
  float: left;
  padding-top: 1.0625rem;
  text-align: right;
}

/* line 139, ../../scss/app/widgets/_ordersummary.scss */
.row .order-summary-items:nth-child(2) {
  font-weight: bold;
}
@media (min-width: 320px) and (max-width: 638px) {
  /* line 139, ../../scss/app/widgets/_ordersummary.scss */
  .row .order-summary-items:nth-child(2) {
    clear: both;
    width: 3.125rem;
    padding-left: 0.625rem;
    font-weight: normal;
  }
}

/* line 150, ../../scss/app/widgets/_ordersummary.scss */
.row .order-summary-items:nth-child(3) {
  font-weight: bold;
}
@media (min-width: 320px) and (max-width: 638px) {
  /* line 150, ../../scss/app/widgets/_ordersummary.scss */
  .row .order-summary-items:nth-child(3) {
    float: right;
    width: 50%;
    padding-left: 0;
    font-size: 1.1875rem;
    font-weight: normal;
  }
}

/* line 162, ../../scss/app/widgets/_ordersummary.scss */
.order-summary-items:last-child {
  padding-right: 1.25rem;
}
@media (min-width: 639px) and (max-width: 1023px) {
  /* line 162, ../../scss/app/widgets/_ordersummary.scss */
  .order-summary-items:last-child {
    padding-right: 2.5rem;
  }
}
@media (min-width: 320px) and (max-width: 638px) {
  /* line 162, ../../scss/app/widgets/_ordersummary.scss */
  .order-summary-items:last-child {
    display: none;
  }
}

@media (min-width: 320px) and (max-width: 638px) {
  /* line 174, ../../scss/app/widgets/_ordersummary.scss */
  order-summary-qty {
    float: left !important;
    text-align: left;
  }
}

/* line 181, ../../scss/app/widgets/_ordersummary.scss */
.order-summary-img {
  float: left;
  width: 20%;
}

/* line 186, ../../scss/app/widgets/_ordersummary.scss */
.order-summary_item-label {
  font-size: 1rem;
  padding-left: 1.25rem;
  float: left;
  padding-top: 0.625rem;
  width: 80%;
}
@media (min-width: 320px) and (max-width: 638px) {
  /* line 186, ../../scss/app/widgets/_ordersummary.scss */
  .order-summary_item-label {
    padding-top: 0;
  }
}

/* line 198, ../../scss/app/widgets/_ordersummary.scss */
.order-summary-label, .order-summary-qty {
  padding-top: 0.25rem;
  text-align: right;
}

@media (min-width: 320px) and (max-width: 638px) {
  /* line 203, ../../scss/app/widgets/_ordersummary.scss */
  .order-summary-label {
    float: right;
  }
}

/* line 209, ../../scss/app/widgets/_ordersummary.scss */
.order-summary-calculations {
  float: right;
  width: 25%;
  min-height: 15rem;
}
@media (min-width: 639px) and (max-width: 1023px) {
  /* line 209, ../../scss/app/widgets/_ordersummary.scss */
  .order-summary-calculations {
    width: 40%;
    margin-right: 1.25rem;
  }
}
@media (min-width: 320px) and (max-width: 638px) {
  /* line 209, ../../scss/app/widgets/_ordersummary.scss */
  .order-summary-calculations {
    width: 100%;
    margin-right: 0;
    float: none;
    background: #f6f6f6;
  }
}
@media (min-width: 639px) and (max-width: 1023px) {
  /* line 226, ../../scss/app/widgets/_ordersummary.scss */
  .order-summary-calculations.m_summary-checkout {
    margin-right: 0;
  }
}

/* line 233, ../../scss/app/widgets/_ordersummary.scss */
.order-summary_wrap {
  font-weight: bold;
  padding: 0 1.25rem 1.25rem;
  border-bottom: 1px solid #cccccc;
}
@media (min-width: 320px) and (max-width: 638px) {
  /* line 233, ../../scss/app/widgets/_ordersummary.scss */
  .order-summary_wrap {
    border-bottom: 0px;
  }
}

/* line 243, ../../scss/app/widgets/_ordersummary.scss */
.order-summary_wrap:nth-child(3) {
  border-bottom: 0px !important;
}

/* line 247, ../../scss/app/widgets/_ordersummary.scss */
.order-summary_subtotal {
  padding-bottom: 0.9375rem;
  padding-top: 1.25rem;
  font-size: 1rem;
}

/* line 253, ../../scss/app/widgets/_ordersummary.scss */
.order-summary_extras {
  font-size: 0.875rem;
  height: 2.1875rem;
  padding-top: 1.25rem;
}

/* line 259, ../../scss/app/widgets/_ordersummary.scss */
.order-summary_total {
  clear: both;
  padding-top: 1.25rem;
  height: auto;
  overflow: hidden;
}

/* line 266, ../../scss/app/widgets/_ordersummary.scss */
.order-summary_total-label {
  font-size: 1.375rem;
  padding-left: 1.375rem;
  float: left;
}
@media (min-width: 320px) and (max-width: 638px) {
  /* line 266, ../../scss/app/widgets/_ordersummary.scss */
  .order-summary_total-label {
    padding-left: 0;
  }
}

/* line 276, ../../scss/app/widgets/_ordersummary.scss */
.order-summary_total-price {
  font-size: 1.375rem;
  float: right;
}

/* line 281, ../../scss/app/widgets/_ordersummary.scss */
.order-summary_print {
  display: none;
  margin: 1.25rem 1.25rem 0 1.25rem;
  text-align: left;
}
/* line 286, ../../scss/app/widgets/_ordersummary.scss */
.order-summary_print a {
  width: 100% !important;
}
@media (min-width: 320px) and (max-width: 638px) {
  /* line 281, ../../scss/app/widgets/_ordersummary.scss */
  .order-summary_print {
    display: none;
  }
}

/* line 295, ../../scss/app/widgets/_ordersummary.scss */
.order-summary_sale + .order-summary-items {
  padding-top: 2.375rem;
}

@media screen and (max-width: 1024px) {
  /* line 1, ../../scss/app/widgets/_otherproducts.scss */
  .w_others {
    padding: 0px;
  }
}
/* line 11, ../../scss/app/widgets/_otherproducts.scss */
.w_others .tpgrid_item {
  border-top: 1px solid #cccccc;
}

/* line 13, ../../scss/app/widgets/_otherproducts.scss */
.others-product .tpgrid_placeholder
{
  display: flex;
  align-items: center;
  min-height: 260px;
}
.others-product {
  position: relative;
  width: 100%;
  float: left;
  border-top:1px solid #ccc;
  padding-top:1.5rem;
}

/* line 14, ../../scss/app/widgets/_otherproducts.scss */
.others-product-title {
  font-size: 2.25rem;
}
@media screen and (max-width: 1024px) {
  /* line 14, ../../scss/app/widgets/_otherproducts.scss */
  .others-product-title {
    padding-left: 0;
  }
}

/* line 2, ../../scss/app/widgets/_checkstep.scss */
.w_checkout-steps {
  width: 97.65625%;
  background-color: #fff;
  margin-left: 0.9375rem;
  margin-right: 0.9375rem;
  min-height: 6.25rem;
  position: relative;
  float: left;
  border-right: 0.0625rem solid #cccccc; /* fix for resize border in IE */
}
@media only screen and (min-width: 60.625rem) {
  /* line 2, ../../scss/app/widgets/_checkstep.scss */
  .w_checkout-steps {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    border-right: 0.0625rem solid #cccccc;
  }
}

/*.checkout-steps_desktop{ // Branding updates
    width:percentage(1050px / 1280px);
    margin-left:8.7%;
}*/
/* line 22, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_block {
  width: 72%;
  background-color: #fff;
  min-height: 6.25rem;
  float: left;
  /*border-bottom: solid 1px $grey-4;*/
}
/* line 28, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_block input, .checkout-step_block select {
  font-size: 0.875rem;
}

/* line 33, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_title {
  /*background-color:$grey-6;*/
  background-color: #fff;
  height: 4.875rem;
  border-top: solid 1px #cccccc;
  position: relative;
}

/* line 41, ../../scss/app/widgets/_checkstep.scss */
.pad-left40 {
  padding-left: 2.5rem !important;
}

/* line 45, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_title.active {
  border-bottom: solid 1px #cccccc;
}

/* line 49, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_title.active:after, .checkout-step_title.active:before {
  top: 100%;
  left: 7%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

/* line 60, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_title.active:after {
  border-color: rgba(236, 237, 238, 0);
  border-top-color: #fff;
  border-width: 13px;
  margin-left: 9px;
}

/* line 67, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_title.active:before {
  border-color: rgba(204, 204, 204, 0);
  border-top-color: #bbb;
  border-width: 0.9375rem;
  margin-left: 0.4375rem;
}

/* line 74, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_title-name {
  font-size: 1.5rem;
  margin-top: 2rem;
  margin-left: 3.4375rem;
  float: left;
  font-weight: bold;
}
@media screen and (max-width: 1024px) {
  /* line 74, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_title-name {
    margin-left: 0.9375rem;
  }
}

/*.checkout-step_form input[type="radio"] + label::before {
    background-image:url(../../images/radio-button.png);
    }
.checkout-step_form input[type="radio"]:checked + label::before {
    background-position: -0.0625rem -30px;
}*/
/*.checkout-step_title-name-desktop{
    font-size:rem-calc(24);
    margin-top:rem-calc(50);
    margin-left:rem-calc(55);
    float:left;

    span{
          
    }
}*/
/* line 108, ../../scss/app/widgets/_checkstep.scss */
.checkout-product_conditions {
  display: block;
  padding-left: 2.8125rem;
  padding-right: 2.8125rem;
  border-top: 1px solid #cccccc;
}
@media screen and (max-width: 1024px) {
  /* line 108, ../../scss/app/widgets/_checkstep.scss */
  .checkout-product_conditions {
    padding: 0 0.9375rem 0.9375rem 0.9375rem;
  }
}
@media (max-width: 638px) {
  /* line 108, ../../scss/app/widgets/_checkstep.scss */
  .checkout-product_conditions {
    background: #fff;
  }
}

/* line 123, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_count {
  font-size: 0.875rem;
  float: right;
  margin-top: 2.5rem;
  margin-right: 4.0625rem;
  font-weight: bold;
}

/* line 131, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_content {
  display: none;
}

/* line 135, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_content input {
  background-color: #FFF;
  border-color: #CCC;
  border-width: 1px;
  border-bottom-color: #000;
}
/* line 141, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_content input.required {
  border: 1px solid #F00;
  padding-left: 0.625rem;
}

/* line 147, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_content.active {
  display: block;
  padding-left: 2.8125rem;
  padding-right: 4.0625rem;
  padding-top: 1.875rem;
  min-height: 6.25rem;
}
@media screen and (max-width: 1024px) {
  /* line 147, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_content.active {
    padding-left: 2.8125rem;
    padding-right: 4.0625rem;
  }
}
@media screen and (max-width: 639px) {
  /* line 147, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_content.active {
    padding-left: 0.3125rem;
    padding-right: 0.3125rem;
  }
}

/* line 165, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_content-desktop.active {
  display: block;
  padding-left: 2.8125rem;
  padding-top: 1.25rem;
  min-height: 6.25rem;
}
@media (max-width: 638px) {
  /* line 165, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_content-desktop.active {
    padding-left: 0.3125rem;
  }
}

/* line 176, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_form {
  /*margin-bottom: rem-calc(18);*/
  position: relative;
}
/* line 179, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_form > div > p:last-child {
  margin-bottom: 0;
}
/* line 183, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_form label {
  font-size: 0.9375rem;
  color: #333333;
}
/* line 188, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_form #state {
  margin-bottom: 1.5rem !important;
}
@media screen and (max-width: 638px) {
  /* line 188, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_form #state {
    width: 100px;
  }
}
/* line 196, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_form .error {
  margin-left: 10px;
  position: relative;
  top: 0px;
  left: -10px;
  font-size: 0.8125rem;
  color: #a80309;
  white-space: nowrap;
  top: -1.25rem;
}
/* line 207, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_form .toolbar_select-box {
  display: block;
}
/* line 211, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_form .checkout-step_shipping-method {
  font-size: 1rem;
  margin-bottom: 0.75rem;
}
/* line 216, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_form .checkout-step_detail-shipping {
  font-size: 0.875rem;
  margin-bottom: 1.875rem;
}
/* line 221, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_form .cc-logo {
  margin-top: 0;
  margin-right: 0.625rem;
}
/* line 226, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_form .checkout-step_shipping-selection, .checkout-step_form .checkout-step_card-selection {
  display: table-cell;
}
/* line 229, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_form .checkout-step_shipping-selection label, .checkout-step_form .checkout-step_card-selection label {
  position: relative !important;
  top: -0.4375rem !important;
}
.detailed-shipping-method input[type="radio"]+label::before {
  /* display: none; */
}

.detailed-shipping-method input[type=radio]+label {
  display: flex;
  padding: 0 0 0 12px;
  /* line-height: 0; */
}

.detailed-shipping-method .RadioButtonGroupWrapper-VDS__sc-15mt7ao-0 {
  padding-left: 5px;
}

.detailed-shipping-method .RadioLabel-VDS__sc-c86p5f-0 {
  padding-left: 8px;
}

.detailed-shipping-method span {
  font-family: Brandfont, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: rgb(0, 0, 0);
  font-style: normal;
  cursor: default;
  text-size-adjust: 100%;
  margin-bottom: 0;
  text-rendering: optimizeLegibility;
}

.detailed-shipping-method .StyledOuterCircle-VDS__sc-uj1g8o-1{
  margin-top: 2px;
}

.detailed-shipping-method .ChildWrapper-VDS__sc-c86p5f-1 {
  font-family: Brandfont, Arial, sans-serif;
  cursor: default;
  font-size: 14px;
  font-weight: 400;
  color: rgb(0, 0, 0);
  font-style: normal;
  text-size-adjust: 100%;
  text-rendering: optimizeLegibility;
}

.detailed-shipping-method .fWEpef .radioOuter {
  border: solid 2px #000000 !important;
}

.detailed-shipping-method .hSKrIX .radioOuter {
  border: solid 2px #000000 !important;
}

.detailed-shipping-address .ChildWrapper-VDS__sc-c86p5f-1 {
  color: rgb(102, 102, 102);
}

.detailed-shipping-address b{
  color: rgb(0, 0, 0);
}

/* banner  */
#hero-carousel-slider .Wrapper-VDS__sc-n00l0f-1{
  z-index: 100;
}

#banner_carousel1.OuterTileContainer-VDS__sc-18pndhp-3, #banner_carousel2.OuterTileContainer-VDS__sc-18pndhp-3, #banner_carousel3.OuterTileContainer-VDS__sc-18pndhp-3, #banner_carousel4.OuterTileContainer-VDS__sc-18pndhp-3, #banner_carousel5.OuterTileContainer-VDS__sc-18pndhp-3, #banner_carousel6.OuterTileContainer-VDS__sc-18pndhp-3, #banner_carousel7.OuterTileContainer-VDS__sc-18pndhp-3, #banner_carousel8.OuterTileContainer-VDS__sc-18pndhp-3, #banner_carousel9.OuterTileContainer-VDS__sc-18pndhp-3, #banner_carousel10.OuterTileContainer-VDS__sc-18pndhp-3{
  background-color: rgb(228,229,227) !important;
  height: 480px !important;
  padding: 0 !important;
  padding-left: 50px !important;
  /* pointer-events: all !important; */
  cursor: default !important;
}

#hero-carousel-slider a.button {
  /* user-select: contain !important; */
}

#hero-carousel-slider .padding-left-medium{
  margin-left: 64px !important;
}

#hero-carousel-slider .carousel_item{
  float: none !important;
  font-weight: normal !important;
  font-family: BrandFont, Arial, sans-serif !important;
}

@media only screen and (max-width:1024px){
  #hero-carousel-slider .padding-left-medium{
    margin-left: 0 !important;
  }
}

@media only screen and (max-width:768px){
  #banner_carousel1.OuterTileContainer-VDS__sc-18pndhp-3, #banner_carousel2.OuterTileContainer-VDS__sc-18pndhp-3, #banner_carousel3.OuterTileContainer-VDS__sc-18pndhp-3, #banner_carousel4.OuterTileContainer-VDS__sc-18pndhp-3, #banner_carousel5.OuterTileContainer-VDS__sc-18pndhp-3, #banner_carousel6.OuterTileContainer-VDS__sc-18pndhp-3, #banner_carousel7.OuterTileContainer-VDS__sc-18pndhp-3, #banner_carousel8.OuterTileContainer-VDS__sc-18pndhp-3, #banner_carousel9.OuterTileContainer-VDS__sc-18pndhp-3, #banner_carousel10.OuterTileContainer-VDS__sc-18pndhp-3{
    padding-left: 10px !important;
  }
}

@media only screen and (max-width:480px){
  #banner_carousel1.OuterTileContainer-VDS__sc-18pndhp-3, #banner_carousel2.OuterTileContainer-VDS__sc-18pndhp-3, #banner_carousel3.OuterTileContainer-VDS__sc-18pndhp-3, #banner_carousel4.OuterTileContainer-VDS__sc-18pndhp-3, #banner_carousel5.OuterTileContainer-VDS__sc-18pndhp-3, #banner_carousel6.OuterTileContainer-VDS__sc-18pndhp-3, #banner_carousel7.OuterTileContainer-VDS__sc-18pndhp-3, #banner_carousel8.OuterTileContainer-VDS__sc-18pndhp-3, #banner_carousel9.OuterTileContainer-VDS__sc-18pndhp-3, #banner_carousel10.OuterTileContainer-VDS__sc-18pndhp-3{
    padding: 0 !important;
    height: 500px !important;
  }

  #hero-carousel-slider .cglOMV img{
    height: 250px !important;
  }

  #hero-carousel-slider .tiny-10{
    float: left !important;
    margin-left: 30px !important;
  }
}

/* feature-accessoires  */
#feature-accessories-carousel .CarouselContainer-VDS__sc-n00l0f-3{
  scroll-padding: 0 16px;
  padding-left: 16px;
  padding-right: 16px;
}

#feature-accessories-carousel .IconButton-VDS__sc-153h7qw-0{
  margin: 0;
}

#feature-accessories-carousel .tpgrid_product-actual-price, .bold{
  font-size: 14px !important;
}

#feature-accessories-carousel .OuterTileContainer-VDS__sc-18pndhp-3{
  pointer-events: all !important;
  height: 530px !important;
  padding: 0 !important;
  border-radius: 0 !important;
}

#feature-accessories-carousel .tpgrid_box .button {
  user-select: contain !important;
}

#feature-accessories-carousel .OuterTileContainer-VDS__sc-18pndhp-3:hover .showCart{
  display: block !important;
  width: 100% !important;
  height: 100% !important;
}

#feature-accessories-carousel .showCart{
  height: 100% !important;
  width: 100% !important;
}

#feature-accessories-carousel .mainShowCart{
  height: 100% !important;
  padding-bottom: 0 !important;
  margin: 0 !important;
  font-weight: normal !important;
  font-family: BrandFont, Arial, sans-serif !important;
  font-size: 0.875rem !important;
}

#feature-accessories-carousel .tpgrid_placeholder img{
  width: 173px !important;
  margin-top: 10px !important;
  margin-left: 20px;
} 

#feature-accessories-carousel .tpgrid_product{
  width: 90% !important;
}

#feature-accessories-carousel .IconButton-VDS__sc-153h7qw-0{
  z-index: 641 !important;
}

#feature-accessories-carousel .tpgrid_item .tpgrid_top-rated.discountTag div{
  left: 15px !important;
  margin: 0 auto !important;
}

#feature-accessories-carousel .TitleLockupWrapper-VDS__sc-1kiwzla-0, .TitleWrapper-VDS__sc-6amjsz-0, .InnerTileContainer-VDS__sc-18pndhp-2, .StyledTypography-VDS__sc-5k55co-0 {
  height: 100% !important;
}

@media only screen and (max-width: 1024px){
  #feature-accessories-carousel .OuterTileContainer-VDS__sc-18pndhp-3{
    height: 450px !important;
  }

  #feature-accessories-carousel .tpgrid_placeholder img {
    width: 130px !important;
  }

  #feature-accessories-carousel .tpgrid_product{
    margin-left: 0 !important;
  }
}

@media only screen and (max-width: 900px){
  #feature-accessories-carousel .OuterTileContainer-VDS__sc-18pndhp-3{
    height: 420px !important;
  }

  #feature-accessories-carousel .tpgrid_placeholder img {
    width: 100px !important;
  }
}

@media only screen and (max-width: 768px){
  #feature-accessories-carousel .OuterTileContainer-VDS__sc-18pndhp-3{
    padding: 0 !important;
    height: 400px !important;
  }

  #feature-accessories-carousel .tpgrid_product{
    padding-left: 10px !important;
  }

  #feature-accessories-carousel .tpgrid_placeholder img {
    width: 80px !important;
    margin-left: 15px !important;
  }

  #feature-accessories-carousel .showCart, .StyledTypography-VDS__sc-5k55co-0{
    height: 100% !important;
  }

  #feature-accessories-carousel .OuterTileContainer-VDS__sc-18pndhp-3:hover .showCart{
    height: 100% !important; 
  }
}

@media only screen and (max-width: 480px){
  #feature-accessories-carousel .OuterTileContainer-VDS__sc-18pndhp-3{
    height: 450px !important;
  }

  #feature-accessories-carousel .showCart{
    height: 100% !important;
    width: 100% !important;
  }

  #feature-accessories-carousel .mainShowCart {
    height: 100% !important;
  }

  #feature-accessories-carousel .tpgrid_product{
    width: 100% !important;
    margin-left: 0 !important;
  }

  #feature-accessories-carousel .tpgrid_placeholder {
    width: 100% !important;
    height: 192px !important;
    margin: 0 !important;
    text-align: center;
  }

  #feature-accessories-carousel .tpgrid_placeholder img {
    width: 207px !important;
    height: 187px !important;
    margin-top: 0px !important;
    margin-left: 0 !important;
  }
}

/* line 235, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_form .checkout-step_shipping-day, .checkout-step_form .checkout-step_shipping-address, .checkout-step_form .checkout-step_card-choose {
  display: table-cell;
  /*padding-left: rem-calc(10);*/
  font-size: 0.875rem;
}
/* line 240, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_form .checkout-step_shipping-day > p:first-child, .checkout-step_form .checkout-step_shipping-address > p:first-child, .checkout-step_form .checkout-step_card-choose > p:first-child {
  margin-bottom: 0.75rem;
}
/* line 244, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_form .checkout-step_shipping-day p, .checkout-step_form .checkout-step_shipping-address p, .checkout-step_form .checkout-step_card-choose p {
  margin-bottom: 0.3125rem;
  font-size: 0.875rem;
}
/* line 249, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_form .checkout-step_shipping-day .checkout-step_shipping-day-free, .checkout-step_form .checkout-step_shipping-address .checkout-step_shipping-day-free, .checkout-step_form .checkout-step_card-choose .checkout-step_shipping-day-free {
  color: #cd040b;
}
/* line 253, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_form .checkout-step_shipping-day .checkout-step_shipping-home, .checkout-step_form .checkout-step_shipping-address .checkout-step_shipping-home, .checkout-step_form .checkout-step_card-choose .checkout-step_shipping-home {
  line-height: 1.125rem;
  margin-top: 0.9375rem;
}
/* line 257, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_form .checkout-step_shipping-day .checkout-step_shipping-home span, .checkout-step_form .checkout-step_shipping-address .checkout-step_shipping-home span, .checkout-step_form .checkout-step_card-choose .checkout-step_shipping-home span {
  color: #666;
}

/* line 264, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_different_shipping {
  display: none;
}

/* line 268, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_shippment {
  padding-top: 1.25rem;
  padding-left: 0.9375rem;
}

/* line 273, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_submit-data, .checkout-step_card-choose {
  position: relative;
}
/* line 276, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_submit-data p, .checkout-step_card-choose p {
  margin-bottom: 0.3125rem;
  font-size: 1rem;
}
/* line 281, ../../scss/app/widgets/_checkstep.scss */
/* .checkout-step_submit-data .checkout-step_edit-card, .checkout-step_card-choose .checkout-step_edit-card { */
  /*top:0; // Branding Updates
      font-weight:bold;
      width:rem-calc(60);
      right:rem-calc(65);            
    border-radius:3px !important;*/
/* } */
@media screen and (max-width: 638px) {
  /* line 281, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_submit-data .checkout-step_edit-card, .checkout-step_card-choose .checkout-step_edit-card {
    right: 1.25rem;
  }
}
/* line 292, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_submit-data .checkout-step_edit-data, .checkout-step_card-choose .checkout-step_edit-data {
  position: absolute;
  top: 0;
  font-weight: bold;
  width: 3.75rem;
  right: 4.0625rem;
}
@media screen and (max-width: 638px) {
  /* line 292, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_submit-data .checkout-step_edit-data, .checkout-step_card-choose .checkout-step_edit-data {
    right: 1.25rem;
  }
}

/* line 305, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_submit-title {
  font-size: 1rem;
  color: #333 !important;
}

/* line 311, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_help-content .checkout-step_help-block {
  margin-top: 0.9375rem;
  padding-right: 3.625rem;
  float: right;
}
/* line 317, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_help-content .checkout-step_help-title {
  color: #8ad4f7;
  font-size: 0.875rem;
}
/* line 322, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_help-content .checkout-step_help-details {
  font-size: 0.875rem;
  padding-top: 0.3125rem;
}

/* line 328, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_payment-section {
  padding: 0.9375rem;
}
@media screen and (max-width: 1024px) {
  /* line 328, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_payment-section {
    padding-bottom: 0px;
  }
}

/* line 336, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_choose-payment {
  font-size: 1rem;
  padding-bottom: 0.9375rem;
  padding-left: 0.3125rem;
}

/* line 342, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_payment-title {
  border: 1px solid #d9d9d9;
  padding: 0.3125rem 0.9375rem;
  background: #f6f6f6;
  border-top: solid 5px #cd040b;
  white-space: nowrap;
  border-bottom: 0px;
}
/* line 350, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_payment-title p {
  margin-bottom: 0px !important;
  line-height: 0px !important;
}
/* line 355, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_payment-title label {
  color: #cd040b !important;
  line-height: 45px !important;
  margin-bottom: 0px !important;
}

/* line 362, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_help-content .checkout-step_edit-data {
  top: auto;
  bottom: -12.1875rem;
}

/* line 367, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_cc-security {
  width: 50%;
  padding: 0;
  float: left;
  margin-left: 1.875rem;
}
@media screen and (max-width: 638px) {
  /* line 367, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_cc-security {
    margin-left: 0px;
    margin-top: 0.625rem;
    width: 100%;
  }
}

/* line 380, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_order-table {
  width: 100%;
  background-color: #fff;
  min-height: 6.25rem;
  float: left;
}
@media only screen and (max-width: 47.9375rem) {
  /* line 380, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_order-table {
    margin-bottom: 1.5rem;
    min-height: unset;
  }
}
/* line 389, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_order-table img {
  width: 4rem;
  height: auto;
}
/* line 394, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_order-table .checkout-step_order-column-1 {
  width: 11%;/*fix for image width*/
  float: left;
}
/* line 399, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_order-table .checkout-step_order-column-2 {
  width: 48%;/*fix for image stretch in review*/
  font-size: 1rem;
  float: left;
  padding-left: 1.25rem;
  padding-top: 1.25rem;
}
/* line 407, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_order-table .checkout-step_order-column-3 {
  width: 13.45291%;
  font-size: 0.875rem;
  float: left;
  /*padding-right: rem-calc(35);*/
  text-align: right;
  padding-top: 1.25rem;
}
/* line 414, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_order-table .checkout-step_order-column-3:last-child {
  padding-right: 1.125rem;
  width: auto;
}

/* line 421, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_return-shipping {
  /*border-top:solid 1px $grey-4;*/
  /*margin-right:rem-calc(38);*/
  height: auto;
  overflow: hidden;
  margin-bottom: 0.625rem;
}
@media (max-width: 637px) {
  /* line 421, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_return-shipping {
    margin-bottom: 0;
  }
}

/* line 433, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_return {
  float: left;
  border-right: solid 1px #cccccc;
  padding-right: 1.25rem;
  padding-top: 0.9375rem;
  font-size: 0.875rem;
}
@media (max-width: 637px) {
  /* line 433, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_return {
    float: none;
    clear: both;
    width: 100% !important;
    border-right: 0;
    border-bottom: solid 1px #cccccc;
  }
}
/* line 448, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_return span {
  font-size: 0.875rem !important;
  font-weight: bold;
}

/* line 454, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_shipping-order {
  float: left;
  font-size: 0.875rem;
  padding-left: 1.25rem;
  padding-top: 0.9375rem;
  padding-bottom: 0.9375rem;
}
@media (max-width: 637px) {
  /* line 454, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_shipping-order {
    float: none;
    clear: both;
    width: 100% !important;
    padding-left: 0;
  }
}
/* line 468, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_shipping-order span {
  font-size: 0.875rem !important;
  font-weight: bold;
}

/* line 474, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_price {
  width: 28%;
  min-height: 37.5rem;
  float: left;
  background: #fff;
}
@media screen and (max-width: 768px) and (orientation: portrait) {
  /* line 474, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_price {
    border-top: 0.0625rem solid #ccc;
  }
}
@media screen and (max-width: 1024px) {
  /* line 474, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_price {
    min-height: 12.5rem;
	border-top: 0.0625rem solid #ccc; /* fix for resize border in IE */
    /*border-top: rem-calc(1) solid #ccc;*/
  }
}
/* line 489, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_price .cart-box_product {
  display: block;
  width: 100%;
  padding: 0.9375rem;
  float: left;
  height: 11.5625rem;
  overflow-y: scroll;
  min-height: 0px;
}
@media (min-width: 1025px) {
  /* line 489, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_price .cart-box_product {
    padding-left: 0px;
    padding-right: 0px;
    border-bottom: 1px solid #fff;
  }
}
@media (max-width: 1024px) {
  /* line 489, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_price .cart-box_product {
    height: 14.0625rem;
  }
}
/* line 508, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_price .cart-box_product .cart-box_product-name {
  width: 59.21053%;
  float: left;
}
/* line 512, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_price .cart-box_product .cart-box_product-name li {
  font-size: 0.875rem;
}
/* line 517, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_price .cart-box_product .cart-box_product-name .cart-box_price strike {
  color: #4b4b4b;
}
/* line 521, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_price .cart-box_product .cart-box_product-name .cart-box_price span {
  font-weight: bold;
}
/* line 525, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_price .cart-box_product .cart-box_product-name .cart-box_price .cart-box_sale {
  text-transform: uppercase;
  clear: both;
  margin-top: 0.75rem;
}
/* line 532, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_price .cart-box_product .cart-box_product-name .cart-box_name {
  padding-bottom: 0;
}
/* line 537, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_price .cart-box_product .cart-box_product-name-desktop {
  float: left;
}
@media (min-width: 1025px) {
  /* line 537, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_price .cart-box_product .cart-box_product-name-desktop {
    width: 66%;
  }
}
/* line 544, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_price .cart-box_product .cart-box_product-name-desktop .cart-box_name {
  padding-bottom: 0;
}
/* line 550, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_price .cart-box_product li:not(:nth-child(1)) {
  border-top: 0px solid #BBB;
}
/* line 554, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_price .cart-box_product li:last-child {
  border-bottom: 0px solid #BBB;
}
/* line 559, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_price .cart-box_discount {
  display: block;
  width: 100%;
  padding: 0.9375rem;
  float: left;
  background: #fff;
  border-top: 1px solid #fff;
  position: relative;
}
@media (min-width: 1025px) {
  /* line 559, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_price .cart-box_discount {
    padding-left: 0px;
    padding-right: 0px;
    border-bottom: 1px solid #ccc;
    padding-bottom:1.5rem;
  }
}
@media (max-width: 1024px) {
  /* line 559, ../../scss/app/widgets/_checkstep.scss */
  /* .checkout-step_price .cart-box_discount { */
    /*height: rem-calc(225);*/
  /* } */
}
/* line 579, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_price .checkout-step_priceholder {
  height: auto;
  overflow: hidden;
  /*border-top:1px solid #BBB;*/
}
@media (min-width: 1024px) {
  /* line 579, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_price .checkout-step_priceholder {
    border-top: 0.0625rem solid #cccccc;
  }
}
@media (max-width: 638px) {
  /* line 579, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_price .checkout-step_priceholder {
    border-top: 0 !important;
  }
}
/* line 592, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_price .checkout-step_price-policy {
  background-color: #fff;
  font-size: 0.875rem;
  float: left;
  width: 100%;
  border-bottom: 1px solid #bbb;
}
@media (max-width: 638px) {
  /* line 592, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_price .checkout-step_price-policy {
    border-top: 0.0625rem solid #cccccc;
  }
}
/* line 603, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_price .checkout-step_price-policy .ico-placeholder {
  float: left;
  background-color: #f9b295;
  padding: 1.25rem 0.625rem;
  /*width: 31.5%;*/
  border-bottom: 0.0625rem solid transparent;
  height: 4.75rem;
}
@media (max-width: 638px) {
  /* line 603, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_price .checkout-step_price-policy .ico-placeholder {
    width: 4.1875rem;
  }
}
/* line 615, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_price .checkout-step_price-policy .policy-details {
  float: left;
  padding: 1.25rem 0.625rem;
}
@media (min-width: 638px) and (max-width: 1023px) {
  /* line 615, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_price .checkout-step_price-policy .policy-details {
    padding: 1.75rem 0.625rem;
  }
}
/* line 625, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_price .checkout-step_shipping-available {
  background-color: #fff;
  width: 100%;
  font-size: 0.875rem;
  /*border-top:solid 1px $white;*/
  border-bottom: solid 1px #cccccc;
  color: #4b4b4b;
  float: left;
}
/* line 635, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_price .checkout-step_shipping-available .ico-placeholder {
  float: left;
  background-color: #f9b295;
  /*padding: rem-calc(20) rem-calc(10);*/
  width: 4.1875rem;
  text-align: center;
  height: 4.6875rem;
}
@media (max-width: 638px) {
  /* line 635, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_price .checkout-step_shipping-available .ico-placeholder {
    width: 4.1875rem;
  }
}
/* line 648, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_price .checkout-step_shipping-available .policy-details {
  float: left;
  padding: 1.25rem 0.625rem;
}
@media (min-width: 638px) and (max-width: 1023px) {
  /* line 648, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_price .checkout-step_shipping-available .policy-details {
    padding: 1.75rem 0.625rem;
  }
}
@media (max-width: 1024px) {
  /* line 625, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_price .checkout-step_shipping-available {
    border-top: 0px solid #BBB;
  }
}
/* line 662, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_price .checkout-step_shipping-available-desktop {
  background-color: #fff;
  width: 100%;
  font-size: 0.875rem;
  border-bottom: solid 1px #cccccc;
  color: #4b4b4b;
  padding: 0;
  float: left;
}
/* line 671, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_price .checkout-step_shipping-available-desktop .ico-placeholder {
  float: left;
  background-color: #f9b295;
  padding: 0.5rem 0.625rem;
  width: 27.96053%;
  text-align: center;
}
/* line 679, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_price .checkout-step_shipping-available-desktop .policy-details {
  float: left;
  padding: 1.25rem 0.625rem;
}
@media (min-width: 639px) and (max-width: 1024px) {
  /* line 679, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_price .checkout-step_shipping-available-desktop .policy-details {
    padding: 1.75rem 0.625rem;
  }
}
/* line 689, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_price .checkout-step_shipping-items {
  height: 3.75rem;
  width: 100%;
  background-color: #fff !important;
  font-size: 1.125rem;
  float: left;
  padding: 1.25rem 1.25rem 0.625rem;
}
@media screen and (max-width: 1023px) {
  /* line 689, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_price .checkout-step_shipping-items {
    width: 100%;
    height: 5rem;
    font-size: 1rem;
    padding: 0.9375rem;
    background-color: #FFFFFF;
    margin-top: 0px;
  }
}
@media screen and (max-width: 638px) {
  /* line 689, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_price .checkout-step_shipping-items {
    margin-top: 0px;
  }
}
/* line 711, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_price .checkout-step_discount {
  /*height: rem-calc(80);*/
  width: 100%;
  background-color: #fff !important;
  font-size: 1rem;
  float: left;
  padding: 1.25rem 1.25rem 1.25rem 0.625rem;
}
@media screen and (max-width: 1023px) {
  /* line 711, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_price .checkout-step_discount {
    width: 100%;
    /*height: rem-calc(60);*/
    font-size: 1rem;
    padding: 0.9375rem;
    background-color: #ECEDEE;
  }
}
@media screen and (max-width: 768px) {
  /* line 711, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_price .checkout-step_discount {
    margin-top: 0px;
    padding-top: 1.4375rem;
  }
}
@media screen and (max-width: 638px) {
  /* line 711, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_price .checkout-step_discount {
    margin-top: 0px;
    padding-top: 1.4375rem;
  }
}
/* line 738, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_price .checkout-step_total-price {
  width: 100%;
  float: left;
}
/* line 742, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_price .checkout-step_total-price .checkout-step_sub-total {
  border-top: solid 1px #ccc;
  border-bottom: solid 1px #ccc;
  padding: 0.9375rem;
}
/* line 751, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_price .checkout-step_total-price .checkout-step_sub-total .checkout-step_sub-total-name {
  font-size: 0.875rem;
  font-weight: bold;
}
@media only screen and (min-width: 768px) and (orientation: portrait) {
  /* line 751, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_price .checkout-step_total-price .checkout-step_sub-total .checkout-step_sub-total-name {
    text-align: right;
  }
}
/* line 760, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_price .checkout-step_total-price .checkout-step_sub-total .checkout-step_sub-total-price {
  font-size: 0.875rem;
  text-align: right;
  font-weight: bold;
}
/* line 767, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_price .checkout-step_total-price .checkout-step_total-shipping {
  padding: 0.9375rem;
}
/* line 770, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_price .checkout-step_total-price .checkout-step_total-shipping .checkout-step_sub-total-name {
  font-size: 0.875rem;
}
@media only screen and (min-width: 768px) and (orientation: portrait) {
  /* line 770, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_price .checkout-step_total-price .checkout-step_total-shipping .checkout-step_sub-total-name {
    text-align: right;
  }
}
/* line 777, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_price .checkout-step_total-price .checkout-step_total-shipping .checkout-step_sub-total-price {
  font-size: 0.875rem;
  text-align: right;
}
/* line 783, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_price .checkout-step_total-price .checkout-step_total-name {
  padding: 0.9375rem;
  font-weight: bold;
  background: #000;
  color: #fff;
}
/* line 787, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_price .checkout-step_total-price .checkout-step_total-name .checkout-step_sub-total-name {
  text-transform: capitalize;
  font-size: 1.375rem;
}
@media only screen and (min-width: 768px) and (orientation: portrait) {
  /* line 787, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_price .checkout-step_total-price .checkout-step_total-name .checkout-step_sub-total-name {
    text-align: right;
  }
}
/* line 795, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_price .checkout-step_total-price .checkout-step_total-name .checkout-step_sub-total-price {
  font-size: 1.375rem;
  text-transform: uppercase;
  text-align: right;
}

/* line 805, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_edit-savedcard {
  display: none;
  margin-top: 1.25rem;
}

/* line 810, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_order-qty-mobile {
  display: none;
  text-align: right;
}

/* line 815, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_edit-address {
  top: 3.75rem !important;
}

@media screen and (max-width: 638px) {
  /* line 820, ../../scss/app/widgets/_checkstep.scss */
  .checkout-mobile-itemcount {
    margin-top: 0 !important;
  }
}
@media screen and (max-width: 1023px) {
  /* line 826, ../../scss/app/widgets/_checkstep.scss */
  .checkout-mobile-itemcount {
    display: block;
    width: 100%;
    height: 3.75rem;
    font-size: 1rem;
    padding: 0.9375rem;
    background-color: #ECEDEE;
    margin-top: 0.9375rem;
  }
  /* line 835, ../../scss/app/widgets/_checkstep.scss */
  .checkout-mobile-itemcount .left, .checkout-mobile-itemcount .right {
    padding-top: 0.625rem;
  }

  /* line 840, ../../scss/app/widgets/_checkstep.scss */
  .checkout-mobile-subtotal {
    display: block;
    width: 100%;
    height: 3.75rem;
    font-size: 1rem;
    padding: 0.9375rem;
    clear: both;
  }
  /* line 848, ../../scss/app/widgets/_checkstep.scss */
  .checkout-mobile-subtotal .left, .checkout-mobile-subtotal .right {
    padding-top: 0.625rem;
  }
  /* line 852, ../../scss/app/widgets/_checkstep.scss */
  .checkout-mobile-subtotal .right {
    text-align: right;
  }
}
/* line 858, ../../scss/app/widgets/_checkstep.scss */
.checkout-stciky {
  position: absolute;
}

/* line 862, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_pre-order {
  font-size: 0.625rem;
}

/* line 866, ../../scss/app/widgets/_checkstep.scss */
.cart-box_tablet-place-order {
  display: none;
  margin-bottom: 0.9375rem !important;
}

@media screen and (max-width: 1150px) {
  /* line 872, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_price .cart-box_product .cart-box_product-name {
    width: 55%;
  }
}
@media screen and (max-width: 978px) {
  /* line 878, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_price .checkout-step_shipping-available div:nth-child(2) {
    width: 55% !important;
  }

  /* line 882, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_price .checkout-step_price-policy div:nth-child(2) {
    width: 72% !important;
  }

  /* line 886, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_price .cart-box_product .cart-box_product-name {
    width: 40%;
  }
}
@media screen and (max-width: 858px) {
  /* line 892, ../../scss/app/widgets/_checkstep.scss */
  .w_checkout-steps {
    width: 100% !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-top: 0 !important;
  }

  /* line 899, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_block {
    width: 100% !important;
  }

  /* line 903, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_price {
    width: 100% !important;
  }

  /* line 907, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_price .checkout-step_price-policy {
    width: 50%;
  }

  /* line 911, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_price .checkout-step_shipping-available {
    width: 50%;
    /*border-top:none;
    padding:rem-calc(20) !important;*/
  }

  /* line 917, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_price .checkout-step_shipping-available div:nth-child(2) {
    width: 72% !important;
  }

  /* line 921, ../../scss/app/widgets/_checkstep.scss */
  .cart-box_product li:nth-child(odd) {
    width: 45%;
    display: table-cell;
    clear: none;
    /*border-right: solid 1px #bbb;*/
    margin-bottom: 1.25rem;
  }

  /* line 929, ../../scss/app/widgets/_checkstep.scss */
  .cart-box_product li:nth-child(even) {
    width: 50%;
    display: table-cell;
    clear: none;
    padding-left: 1.875rem;
    margin-bottom: 1.25rem;
  }

  /* line 937, ../../scss/app/widgets/_checkstep.scss */
  .cart-box_product-name li {
    margin-bottom: 0px !important;
  }

  /* line 941, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_price .cart-box_product {
    border-bottom: solid 1px #bbb;
  }

  /* line 945, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_price .cart-box_product .cart-box_product-name {
    width: 69%;
  }
  /* line 948, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_price .cart-box_product .cart-box_product-name li {
    width: 70%;
    border-right: none !important;
  }

  /* line 954, ../../scss/app/widgets/_checkstep.scss */
  .cart-box_product-name li {
    display: block !important;
  }

  /* line 958, ../../scss/app/widgets/_checkstep.scss */
  .cart-box_quantity {
    padding-left: 0 !important;
  }

  /* line 962, ../../scss/app/widgets/_checkstep.scss */
  .cart-box_tablet-place-order {
    width: 45%;
    float: left;
    min-height: 6.25rem;
    display: block;
    padding-top: 1.25rem;
    padding-left: 0.9375rem;
    margin-bottom: 0.9375rem !important;
  }
  /* line 971, ../../scss/app/widgets/_checkstep.scss */
  /* .cart-box_tablet-place-order button { */
    /*display:none;*/
  /* } */

  /* line 976, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_total-price {
    width: 50% !important;
    float: left;
  }
  /* line 980, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_total-price .checkout-step_sub-total {
    border-bottom: none !important;
  }
  /* line 985, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_total-price .checkout-step_total-place-button {
    display: none;
    margin-bottom: 0.9375rem;
  }
}
@media screen and (max-width: 858px) and (max-width: 638px) {
  /* line 985, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_total-price .checkout-step_total-place-button {
    display: block;
  }
}

@media screen and (max-width: 858px) {
  /*.checkout-step_review-place{
      display:none;
  }*/
  /* line 997, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_mobile-title {
    width: 60%;
  }
}
@media screen and (max-width: 768px) {
  /* line 1003, ../../scss/app/widgets/_checkstep.scss */
  .cart-box_product li:nth-child(odd) {
    width: 100%;
    margin-bottom: 0;
  }

  /* line 1008, ../../scss/app/widgets/_checkstep.scss */
  .cart-box_product li:nth-child(even) {
    width: 100%;
    padding-left: 0;
    margin-bottom: 0;
  }
}
@media screen and (max-width: 638px) {
  /* line 1016, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_price .cart-box_product {
    padding-left: 0px;
    padding-right: 0px;
    height: 12.8125rem;
  }

  /* line 1022, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_price .checkout-step_price-policy {
    width: 100% !important;
    border-bottom: solid 1px #ccc;
  }

  /* line 1027, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_price .checkout-step_shipping-available {
    width: 100% !important;
  }

  /* line 1031, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_total-price {
    width: 100% !important;
  }

  /* line 1035, ../../scss/app/widgets/_checkstep.scss */
  .cart-box_product li {
    width: 100% !important;
    display: block;
    clear: none;
    border-right: none;
    border-bottom: solid 1px #bbb !important;
  }

  /* line 1044, ../../scss/app/widgets/_checkstep.scss */
  .cart-box_product li:nth-child(1) .cart-box_name {
    border-bottom: 0 !important;
  }
  /* line 1048, ../../scss/app/widgets/_checkstep.scss */
  .cart-box_product li:nth-child(1) .cart-box_quantity {
    border-bottom: 0 !important;
  }

  /* line 1054, ../../scss/app/widgets/_checkstep.scss */
  .cart-box_product li:nth-child(2) .cart-box_name {
    border-bottom: 0 !important;
  }
  /* line 1058, ../../scss/app/widgets/_checkstep.scss */
  .cart-box_product li:nth-child(2) .cart-box_quantity {
    border-bottom: 0 !important;
  }

  /* line 1063, ../../scss/app/widgets/_checkstep.scss */
  .cart-box_product li:nth-child(even) {
    width: 50%;
    display: table-cell;
    clear: none;
    padding-left: 0;
    border-right: 0px !important;
  }

  /* line 1071, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_price .cart-box_product {
    border-bottom: 0 !important;
  }

  /* line 1081, ../../scss/app/widgets/_checkstep.scss */
  .cart-box_tablet-place-order {
    display: none !important;
    margin-bottom: 0.9375rem !important;
  }

  /* line 1086, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_total-place-button {
    display: none;
  }
  /* line 1089, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_total-place-button button {
    width: 100% !important;
  }

  /* line 1094, ../../scss/app/widgets/_checkstep.scss */
  .checkout-stop_city.tiny-5 {
    width: 34% !important;
  }

  /* line 1099, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_cc-logo .tiny-8 {
    width: 90% !important;
  }

  /* line 1104, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_exp-date .tiny-4 {
    width: 60%;
  }
  /* line 1107, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_exp-date .tiny-4 .tiny-5 {
    width: 41%;
  }

  /* line 1112, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_exp-date .tiny-8 {
    width: 95%;
    margin-top: 5px;
    position: relative;
    left: -16px;
  }

  /* line 1119, ../../scss/app/widgets/_checkstep.scss */
  .checkout .m_toolbar {
    display: none !important;
  }
}
@media screen and (max-width: 638px) {
  /* line 1125, ../../scss/app/widgets/_checkstep.scss */
  .w_checkout-steps {
    margin-top: 0;
  }
}
@media screen and (max-width: 638px) {
  

  /* line 1137, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_form-mobile {
    width: 95%;
  }

  /* line 1141, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_edit-address {
    right: 10px !important;
    top: 40px !important;
  }

  /* line 1146, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_mobile-title {
    width: 100%;
  }
  /* line 1149, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_mobile-title .checkout-step_payment-title {
    background-color: #fff;
    border: none;
    border-bottom: solid 1px #bbb;
    padding-left: 0px;
    text-align: left;
  }
  /* line 1156, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_mobile-title .checkout-step_payment-title label {
    color: #333 !important;
  }

  /* line 1162, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_mobile-payment {
    background-color: #fff !important;
    border: none !important;
    padding-top: 0;
    padding-bottom: 0.9375rem;
  }
  /* line 1168, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_mobile-payment .checkout-step_choose-payment {
    background-color: #fff !important;
  }

  /* line 1174, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_cc-logo .tiny-6 {
    width: 95%;
  }

  /* line 1180, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_exp-date .tiny-4 {
    width: 95%;
  }
  /* line 1183, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_exp-date .tiny-4 div:nth-child(2) {
    width: 47%;
  }
  /* line 1187, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_exp-date .tiny-4 div:nth-child(3) {
    width: 2%;
  }
  /* line 1191, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_exp-date .tiny-4 div:nth-child(4) {
    width: 48%;
  }
  /* line 1196, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_exp-date .tiny-8 {
    width: 95%;
    float: left !important;
    margin-top: 0.625rem;
  }

  /* line 1203, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_form-mobile-none {
    display: none;
  }

  /* line 1207, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_help {
    padding-left: 0.625rem;
  }

  /* line 1212, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_billing-selection .tiny-7 {
    width: 90%;
  }

  /* line 1217, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_order-qty-mobile {
    display: inline-block;
    font-size: 1rem;
    padding: 0.625rem 0 0 .635rem;
  }

  /* line 1223, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_order-qty-tablet {
    display: none;
  }

  /* line 1227, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_order-table .checkout-step_order-column-1 {
    width: 15%;
  }

  /* line 1231, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_order-table .checkout-step_order-column-2 {
    width: 80%;
    padding: 0;
    padding-left: 0.9375rem;
  }

  /* line 1237, ../../scss/app/widgets/_checkstep.scss */
  .cart-box_product-name ul li:last-child {
    border-bottom: none !important;
  }
}
@media screen and (max-width: 425px) {
  /* line 1243, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_exp-date .tiny-8 {
    left: 0;
  }

  /* line 1248, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_form-mobile .tiny {
    width: 95% !important;
  }

  /* line 1253, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_title-name {
    font-size: 0.9375rem !important;
    margin-top: 2.5rem;
  }

  /* line 1258, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_mobile-selection {
    width: 95%;
  }

  /* line 1262, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_ccard {
    width: 95%;
  }

  /* line 1267, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_cc-logo .tiny-6 {
    width: 100% !important;
  }

  /* line 1272, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_submit-block {
    width: 100%;
    margin-bottom: 0.625rem;
  }
  /* line 1276, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_submit-block p {
    color: #666;
  }

  /* line 1281, ../../scss/app/widgets/_checkstep.scss */
  /* .checkout-step_edit-step2 { */
    /*position: absolute;
    top: rem-calc(-83) !important;*/
  /* } */

  /* line 1286, ../../scss/app/widgets/_checkstep.scss */
  /* .checkout-step_edit-step1 { */
    /*position: absolute;
    top: rem-calc(-82) !important;*/
  /* } */

  /* line 1292, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_submit-block .p-30 {
    width: 20% !important;
  }

  /* line 1297, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_count {
    margin-right: 0.9375rem;
  }

  /* line 1301, ../../scss/app/widgets/_checkstep.scss */
  .checkout .m_toolbar {
    display: none;
  }

  /* line 1305, ../../scss/app/widgets/_checkstep.scss */
  .cart-box_tablet-place-order {
    display: none !important;
    margin-bottom: 0.9375rem !important;
  }

  /* line 1310, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_total-place-button {
    display: none;
    margin-bottom: 0.9375rem;
  }
}
/* line 1316, ../../scss/app/widgets/_checkstep.scss */
.page-loader {
  background-color: transparent !important;
  transition: none;
}

/* line 1321, ../../scss/app/widgets/_checkstep.scss */
.page-loader.active {
  background-color: transparent !important;
  transition: none;
}

/* line 1326, ../../scss/app/widgets/_checkstep.scss */
.page-overlay.active {
  opacity: 1 !important;
}

/*.checkout-step_edit-data, .checkout-step_edit-card{ // Branding Updates
    background:$grey-6 !important;
    
    border-radius: 3px;
    font-weight: normal !important;
    color:$grey-2 !important;
    padding:0 rem-calc(30) 0 rem-calc(30) !important;
   height:rem-calc(35) !important;
   line-height:rem-calc(31) !important;
   width:auto !important;
    &:hover{
        background:$grey-6 !important;
    }
    &:focus, &:active{
        -moz-box-shadow:none !important;
         -webkit-box-shadow:none !important;
         -o-box-shadow:none !important;
         box-shadow:none !important;
    }
}*/
/* line 1349, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_edit-field {
  width: 100%;
}
/* line 1352, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_edit-field .tiny-8 {
  width: 90% !important;
}

/* Checkout List items accordion style*/
@media screen and (max-width: 1023px) {
  /* line 1358, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_shipping-items {
    width: 100%;
    height: 3.75rem;
    font-size: 1rem;
    padding: 1.25rem 0.9375rem;
    background-color: #ECEDEE;
    margin-top: 0;
    border: 1px solid #cccccc;
    border-bottom: 0;
  }
}
@media screen and (max-width: 638px) {
  /* line 1358, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_shipping-items {
    margin-top: 0;
    background-color: transparent;
  }
}

/* line 1377, ../../scss/app/widgets/_checkstep.scss */
.checkout-step-showing-items, .checkout-step_discount-code {
  cursor: pointer;
}
/* line 1380, ../../scss/app/widgets/_checkstep.scss */
.checkout-step-showing-items.minus, .checkout-step_discount-code.minus {
  color: #cd040b;
}

/* line 1386, ../../scss/app/widgets/_checkstep.scss */
.checkout-step-showing-items.plus:after {
  background-position: 6px 5px;
}

/* line 1392, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_discount-code.plus:after {
  content: "\e79d";
  color: #cd040b;
  font-family: "VZIcons";
  float: right;
}
@media only screen and (min-width: 60.625rem) {
  /* line 1392, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_discount-code.plus:after {
    position: relative;
    top: calc(-15);
  }
}
/* line 1404, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_discount-code.minus:after {
  content: "\e79b";
  color: #cd040b;
  font-family: "VZIcons";
  float: right;
}

/* line 1412, ../../scss/app/widgets/_checkstep.scss */
.plus:after {
  content: "\e79d";
  color: #cd040b;
  font-family: "VZIcons";
  float: right;
}

/* line 1419, ../../scss/app/widgets/_checkstep.scss */
.minus:after {
  content: "\e79b";
  color: #cd040b;
  font-family: "VZIcons";
  float: right;
}

/* line 1426, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_price .tp_truck-icon {
  background-position: 5px -244px;
}

/* Bill Pay Customizing CSS Starts*/
/* line 1432, ../../scss/app/widgets/_checkstep.scss */
/* .checkout-step_cc-info { */
  /*& tr:nth-child(3n) td:nth-child(1){
        @media screen and (max-device-width: 1024px) {              
                  width: 100%;
          }
  }*/
/* } */
/* line 1433, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_cc-info table, .checkout-step_cc-info table tr td {
  border: 0px;
}
/* line 1437, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_cc-info td {
  display: block;
  padding: 0px;
}
/* line 1441, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_cc-info td span {
  color: #cd040b;
}
/* line 1445, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_cc-info td input {
  margin-top: 0.375rem;
  font-size: 0.875rem;
}
@media (min-width: 768px) {
  /* line 1445, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_cc-info td input {
    width: 70%;
  }
}
/* line 1454, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_cc-info td .state_select-box, .checkout-step_cc-info td select[required].state_select-box, .checkout-step_cc-info td select.state_select-box:focus:not([readonly]), .checkout-step_cc-info td select.state_select-box.value:focus:not([readonly]) {
  margin-bottom: 1rem;
}
/* line 1459, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_cc-info td:nth-child(odd) {
  width: 100%;
  /*font-size: 0.875rem;
  text-transform: uppercase;*/
  font-size: 0.875rem;
  color: #333333;
  font-weight: bold;
}

.checkout-step_cc-info tr:nth-child(4n) td:nth-child(4n) table td:nth-child(1) {
  width: 35%;
  display: table-cell;
}

.checkout-step_cc-info tr:nth-child(4n) td:nth-child(4n) table td:nth-child(2) {
  padding-left: 1.25rem;
  display: table-cell;
  width: 100%;
}
.checkout-step_cc-info tr:nth-child(4n) td:nth-child(4n) table td:nth-child(2) select {width: 48% !important;}

@media screen and (min-width: 639px) and (max-width: 767px) {
  
  .checkout-step_cc-info tr:nth-child(4n) td:nth-child(4n) table td:nth-child(1) {
    width: 50%;
  }
}

@media screen and (max-width: 638px) {

  .checkout-step_cc-info tr:nth-child(4n) td:nth-child(4n) table td:nth-child(1) {
    width: 50%;
  }
  .checkout-step_cc-info tr:nth-child(4n) td:nth-child(4n) table td:nth-child(2) select {width: 100% !important;}
}

/* line 1468, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_cc-info td:nth-child(even) {
  width: 96%;
  border-color: #9B9B9B;
  color: #595A5D;
  margin-bottom: 0rem !important;
}
@media screen and (max-width: 638px) {
  /* line 1468, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_cc-info td:nth-child(even) {
    width: 100%;
  }
}
/* line 1482, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_cc-info tr:nth-child(2n) td:nth-child(2) select {
  width: 70%;
}
@media screen and (max-width: 767px) {
  /* line 1482, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_cc-info tr:nth-child(2n) td:nth-child(2) select {
    width: 100%;
  }
}
/* line 1491, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_cc-info tr:nth-child(3n) td:nth-child(2n) input {
  width: 70% !important;
  /*@media screen and (min-width:768px) and (max-width: 1024px) {              
              width: 55% !important;
  }*/
}
@media (max-width: 767px) {
  /* line 1491, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_cc-info tr:nth-child(3n) td:nth-child(2n) input {
    width: 100% !important;
  }
}
/* line 1502, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_cc-info tr:nth-child(3n) td:nth-child(4n) table td {
  display: table-cell;
}
/* line 1506, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_cc-info tr:nth-child(3n) td:nth-child(1), .checkout-step_cc-info tr:nth-child(3n) td:nth-child(2) {
  width: 96%;
}
@media (max-width: 638px) {
  /* line 1506, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_cc-info tr:nth-child(3n) td:nth-child(1), .checkout-step_cc-info tr:nth-child(3n) td:nth-child(2) {
    width: 100%;
  }
}
/* line 1518, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_cc-info tr:nth-child(3n) td:nth-child(4n) table td:nth-child(1) {
  width: 35%;
}
@media screen and (min-width: 639px) and (max-width: 767px) {
  /* line 1518, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_cc-info tr:nth-child(3n) td:nth-child(4n) table td:nth-child(1) {
    width: 50%;
  }
}
@media screen and (max-width: 638px) {
  /* line 1518, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_cc-info tr:nth-child(3n) td:nth-child(4n) table td:nth-child(1) {
    width: 50%;
  }
}
/* line 1530, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_cc-info tr:nth-child(3n) td:nth-child(4n) table td:nth-child(2) {
  padding-left: 1.25rem;
}
/* line 1533, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_cc-info tr:nth-child(3n) td:nth-child(4n) table td:nth-child(2) select {
  width: 48%;
}
@media (max-width: 638px) {
  /* line 1533, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_cc-info tr:nth-child(3n) td:nth-child(4n) table td:nth-child(2) select {
    width: 100%;
  }
}
@media screen and (min-width: 639px) and (max-width: 767px) {
  /* line 1533, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_cc-info tr:nth-child(3n) td:nth-child(4n) table td:nth-child(2) select {
    width: 100%;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  /* line 1533, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_cc-info tr:nth-child(3n) td:nth-child(4n) table td:nth-child(2) select {
    width: 35%;
  }
}
/* line 1552, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_cc-info tr:nth-child(3n) td:nth-child(3), .checkout-step_cc-info tr:nth-child(3n) td:nth-child(4) {
  width: 50%;
}
@media screen and (max-width: 638px) {
  /* line 1552, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_cc-info tr:nth-child(3n) td:nth-child(3), .checkout-step_cc-info tr:nth-child(3n) td:nth-child(4) {
    width: 100%;
  }
}
/* line 1560, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_cc-info tr:nth-child(3n) td:nth-child(3) {
  /*margin-top:rem-calc(-88); float:right;*/
  width: 100%;
}
@media screen and (max-width: 638px) {
  /* line 1560, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_cc-info tr:nth-child(3n) td:nth-child(3) {
    margin-top: auto;
    float: none;
  }
}
@media screen and (max-width: 1024px) {
  /* line 1560, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_cc-info tr:nth-child(3n) td:nth-child(3) {
    width: 100%;
    clear: both;
    float: none;
    margin-top: 0;
  }
}
/* line 1577, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_cc-info tr:nth-child(3n) td:nth-child(4) {
  width: 96%;
}
@media screen and (max-width: 638px) {
  /* line 1577, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_cc-info tr:nth-child(3n) td:nth-child(4) {
    width: 100%;
    margin-top: auto;
    float: none;
  }
}
/* line 1587, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_cc-info tr:nth-child(6n) td {
  margin-top: -1.25rem;
  width: 87% !important;
}
@media screen and (max-width: 638px) {
  /* line 1587, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_cc-info tr:nth-child(6n) td {
    margin-top: auto;
  }
}
/* line 1596, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_cc-info table:nth-child(2n) td {
  display: table-cell;
  padding-left: 0px !important;
}
/* line 1602, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_cc-info table:nth-child(2n) td:nth-child(1) {
  width: 7% !important;
}
@media screen and (max-width: 638px) {
  /* line 1602, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_cc-info table:nth-child(2n) td:nth-child(1) {
    width: 12% !important;
  }
}
/* line 1609, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_cc-info table:nth-child(2n) td:nth-child(1) label {
  display: inline;
  padding-top: 0.3125rem;
}
/* line 1615, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_cc-info table:nth-child(2n) td:nth-child(2n) {
  width: 90% !important;
}
/* line 1618, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_cc-info table:nth-child(2n) td:nth-child(2n) label {
  margin-bottom: 0px;
  line-height: 1.375rem;
  /*padding-top: rem-calc(10);*/
}
/* line 1625, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_cc-info div:nth-child(2n) div:nth-child(2n) {
  padding-left: 0px !important;
}
/* line 1628, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_cc-info div:nth-child(2n) div:nth-child(2n) table tr:nth-child(2) td select {
  margin-top: 0.375rem !important;
  margin-bottom: 0px !important;
  width: 65%;
}
@media screen and (max-width: 638px) {
  /* line 1628, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_cc-info div:nth-child(2n) div:nth-child(2n) table tr:nth-child(2) td select {
    width: 100%;
  }
}
/* line 1638, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_cc-info div:nth-child(2n) div:nth-child(2n) table tr:nth-child(2) td:nth-child(3), .checkout-step_cc-info div:nth-child(2n) div:nth-child(2n) table tr:nth-child(2) td:nth-child(4), .checkout-step_cc-info div:nth-child(2n) div:nth-child(2n) table tr:nth-child(3) td:nth-child(1), .checkout-step_cc-info div:nth-child(2n) div:nth-child(2n) table tr:nth-child(3) td:nth-child(2) {
  display: table;
}
/* line 1641, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_cc-info div:nth-child(2n) div:nth-child(2n) table tr:nth-child(2) td:nth-child(3) input, .checkout-step_cc-info div:nth-child(2n) div:nth-child(2n) table tr:nth-child(2) td:nth-child(4) input, .checkout-step_cc-info div:nth-child(2n) div:nth-child(2n) table tr:nth-child(3) td:nth-child(1) input, .checkout-step_cc-info div:nth-child(2n) div:nth-child(2n) table tr:nth-child(3) td:nth-child(2) input {
  margin-bottom: 0px;
}
/* line 1646, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_cc-info div:nth-child(2n) div:nth-child(2n) table tr:nth-child(2) td:nth-child(4) {
  width: 32% !important;
}
/* line 1650, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_cc-info div:nth-child(2n) div:nth-child(2n) table tr:nth-child(3) td {
  width: 80%;
}
@media screen and (max-width: 638px) {
  /* line 1650, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_cc-info div:nth-child(2n) div:nth-child(2n) table tr:nth-child(3) td {
    width: 91%;
  }
}
/* line 1658, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_cc-info div:nth-child(2n) div:nth-child(2n) table tr:nth-child(3) {
  float: right;
  margin-top: -4.5rem;
  margin-right: 37%;
  width: 35%;
}
@media screen and (max-width: 638px) {
  /* line 1658, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_cc-info div:nth-child(2n) div:nth-child(2n) table tr:nth-child(3) {
    margin-right: 15%;
    width: 40%;
  }
}

/* Bill Pay Customizing CSS Ends*/
/* line 1673, ../../scss/app/widgets/_checkstep.scss */
#checkout-step_box-items.checkout-step_price {
  min-height: 0px;
  background-color: #f6f6f6;
}

/*input[type="checkbox"] + label::before { // Branding updates
    background:url(../../images/checkbox.png) no-repeat !important;
    background-position: 0 -34px !important;
   
    width:rem-calc(25) !important;
    height:rem-calc(25) !important;
    }

input[type="checkbox"]:checked + label::before {
    background:url(../../images/checkbox.png) no-repeat !important;
     background-position: 0 0 !important;
     width:rem-calc(25) !important;
    height:rem-calc(25) !important;
}*/
/* line 1692, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_review-place {
  display: block;
}
@media (max-width: 858px) {
  /* line 1692, ../../scss/app/widgets/_checkstep.scss */
  /* .checkout-step_review-place { */
    /*display: none;*/
  /* } */
}

/* line 1700, ../../scss/app/widgets/_checkstep.scss */
.pay-bill-content {
  padding: 0.9375rem 1.875rem 0.9375rem 1.25rem;
}
@media (max-width: 638px) {
  /* line 1700, ../../scss/app/widgets/_checkstep.scss */
  .pay-bill-content {
    padding: 0.9375rem 1.875rem 0.9375rem 0;
  }
}

/*select{
    color:#86878A !important;
}*/
/* line 1710, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_total-place-button {
  text-align: center !important;
}

/* line 1715, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_add-discount .secondary-active {
  width: 100% !important;
}

/* line 1720, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_bill-mycard {
  float: left;
  width: 55%;
}

/* line 1726, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_saved-card {
  width: 45%;
  float: right;
}
@media (max-width: 638px) {
  /* line 1726, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_saved-card {
    width: 40%;
  }
}
/* line 1732, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_saved-card .icon-delete {
  float: left;
  padding-top: 0.3125rem;
}
@media (max-width: 638px) {
  /* line 1732, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_saved-card .icon-delete {
    float: none;
    padding-top: 0;
  }
}
/* line 1742, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_saved-card .checkout-step_edit-btn {
  float: left;
  /*margin-left:rem-calc(20);*/
}
@media (max-width: 638px) {
  /* line 1742, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_saved-card .checkout-step_edit-btn {
    float: none;
    margin-left: 0;
  }
}

/* line 1753, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_bill-myaccount p, .checkout-step_bill-mycard p {
  margin-bottom: 0.1875rem;
  font-size: 0.875rem;
}

/* line 1759, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_new-card {
  padding: 1.25rem 0.625rem;
}
/* line 1762, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_new-card tr:nth-child(3n) td:nth-child(4) {
  padding-left: 2.5rem;
}
/* line 1766, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_new-card tr:nth-child(3n) td:nth-child(3) {
  padding-left: 2.5rem;
}
@media (max-width: 638px) {
  /* line 1770, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_new-card tr:nth-child(2) label:nth-child(3) {
    padding-left: 0 !important;
  }
}

/* line 1777, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_card-choose {
  width: 100%;
}

/* line 1782, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_new-card .poTxtLbl {
  padding-top: 0.375rem;
}
/* line 1786, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_new-card table:nth-child(2n) td:nth-child(1) {
  width: 4% !important;
}
@media screen and (max-width: 638px) {
  /* line 1786, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_new-card table:nth-child(2n) td:nth-child(1) {
    width: 9% !important;
  }
}

/* line 1796, ../../scss/app/widgets/_checkstep.scss */
.cart-box_discount .spinner {
  display: none;
}
/* line 1799, ../../scss/app/widgets/_checkstep.scss */
.cart-box_discount .spinner.active {
  position: absolute;
  left: 40%;
  top: 40%;
  z-index: 500;
  display: block;
}
/* line 1808, ../../scss/app/widgets/_checkstep.scss */
.cart-box_discount input#discountcode {
  margin-bottom: 0px;
}
/* line 1812, ../../scss/app/widgets/_checkstep.scss */
.cart-box_discount .error {
  font-size: 0.8125rem;
  color: #a80309;
  font-size: 0.875rem;
  display: none;
  font-weight: bold;
}
/* line 1820, ../../scss/app/widgets/_checkstep.scss */
.cart-box_discount .checkout-step_discount-number {
  width: 88%;
  text-align: left;
  padding-left: 0.625rem;
  float: left;
  position: relative;
}
@media (min-width: 639px) and (max-width: 1024px) {
  /* line 1820, ../../scss/app/widgets/_checkstep.scss */
  .cart-box_discount .checkout-step_discount-number {
    width: 50%;
    float: left;
    padding-left: 0;
  }
}
@media (max-width: 638px) {
  /* line 1820, ../../scss/app/widgets/_checkstep.scss */
  .cart-box_discount .checkout-step_discount-number {
    width: 98%;
    padding-left: 0;
  }
}
/* line 1837, ../../scss/app/widgets/_checkstep.scss */
.cart-box_discount .checkout-step_discount-number.opaque {
  opacity: 0.3;
  cursor: default;
}
/* line 1843, ../../scss/app/widgets/_checkstep.scss */
.cart-box_discount .checkout-step_add-discount {
  width: 100%;
  /*margin-top: rem-calc(10);*/
}
@media (min-width: 639px) and (max-width: 1023px) {
  /* line 1843, ../../scss/app/widgets/_checkstep.scss */
  .cart-box_discount .checkout-step_add-discount {
    /*width: 50% !important;
    float: right;*/
    float: none;
    clear: both;
    margin-top: 0.1875rem;
  }
}
@media (min-width: 639px) and (max-width: 1023px) {
  /* line 1855, ../../scss/app/widgets/_checkstep.scss */
  .cart-box_discount .checkout-step_add-discount .margin-top-tiny {
    margin-top: 0 !important;
  }
}

/* line 1864, ../../scss/app/widgets/_checkstep.scss */
.w_discount.loader {
  min-height: 14.375rem;
}

/* line 1869, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_discount-applied {
  display: none;
}

/* Added on 15 July, 2015 for content block on the Shipping Information - IR T2025262*/
/* line 1873, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_bill-section {
  float: left;
  width: 65%;
}
@media (max-width: 638px) {
  /* line 1873, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_bill-section {
    width: 100%;
    float: none;
  }
}

/* line 1883, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_security {
  float: left;
  width: 35%;
  padding: 0.8125rem 0.625rem 0 0.9375rem;
}
@media (max-width: 638px) {
  /* line 1883, ../../scss/app/widgets/_checkstep.scss */
  .checkout-step_security {
    display: none;
  }
}

/* line 1893, ../../scss/app/widgets/_checkstep.scss */
input.required, input[required], textarea.required, textarea[required] {
  background-image: none;
  padding-left: 0.625rem;
}

/* line 1898, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_cc-info div:nth-child(2n) div:nth-child(2n) table tr:nth-child(3) td {
  width: 100%;
}

/* line 1902, ../../scss/app/widgets/_checkstep.scss */
#txtPObpInfocopZip {
  width: 100% !important;
}

/* line 1911, ../../scss/app/widgets/_checkstep.scss */
.checkout-step_sale .checkout-step_order-column-2, .checkout-step_sale .checkout-step_order-column-3, .checkout-step_sale .checkout-step_order-column-4 {
  padding-top: 0;
}

@media (min-width: 320px) and (max-width: 638px) {
  /* line 2, ../../scss/app/widgets/_footer.scss */
  .footer_desktop {
    display: none;
  }
}

/* line 7, ../../scss/app/widgets/_footer.scss */
.footer_device {
  display: none;
}
@media (min-width: 320px) and (max-width: 638px) {
  /* line 7, ../../scss/app/widgets/_footer.scss */
  .footer_device {
    display: block;
    padding: 0.9375rem;
  }
}

/* line 1, ../../scss/app/widgets/_dropdown.scss */
.w_select-box {
  position: relative;
  margin-left: 0;
}
@media only screen and (min-width: 638px) and (max-width: 768px) {
  /* line 4, ../../scss/app/widgets/_dropdown.scss */
  .w_select-box.m_select-box-sort {
    margin-right: 1.25rem;
    width:10rem !important;
  }
  .w_toolbar .add-filter{
    margin-right:1rem;
  }
}
@media only screen and (min-width: 48rem) {
  /* line 4, ../../scss/app/widgets/_dropdown.scss */
  .w_select-box.m_select-box-sort {
    width: 15.625rem;
  }
}
@media only screen and (max-width: 638px) {
  /* line 1, ../../scss/app/widgets/_dropdown.scss */
  .w_select-box {
    padding: 0 0.9375rem;
    width: 100%;
    float: none !important;
  }
}

/* line 21, ../../scss/app/widgets/_dropdown.scss */
.select-box_selected {
  border: 0;
  border-bottom: 0.0625rem solid #000;
  color: #000;
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 1.8125rem;
  padding: 0 0.3125rem;
  cursor: pointer;
  text-transform: capitalize !important;
  background: transparent;
  width: 100% !important;
  text-align: left;
  font-family: BrandFont, Arial, sans-serif;
}
/* line 36, ../../scss/app/widgets/_dropdown.scss */
.select-box_selected span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* float: left; */
  display: inline-block;
}
/* line 44, ../../scss/app/widgets/_dropdown.scss */
.select-box_selected:after {
  font-family: "VZIcons";
  content: "\e76e";
  color: #cd040b;
  padding-left: 0.9375rem;
  float: right;
}
/* line 52, ../../scss/app/widgets/_dropdown.scss */
.select-box_selected:focus {
  outline: none;
}
/* line 58, ../../scss/app/widgets/_dropdown.scss */
.select-box_selected.m_sort-prefix span:before {
  content: 'Sort by - ';
  text-transform:none;
}

/* line 65, ../../scss/app/widgets/_dropdown.scss */
.select-box_options {
  width: 100%;
  margin: 0 0;
  padding: 0 0;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.176);
  height: auto;
  overflow: hidden;
  position: absolute;
  top: 0;
  background: #fff;
  z-index: 60000;
  display: none;
}
/* line 78, ../../scss/app/widgets/_dropdown.scss */
.select-box_options li {
  color: #000;
  border-bottom: 0.0625rem solid #d7d9d6;
  padding: 0.125rem 0.3125rem !important;
  display: block;
  clear: both;
  font-weight: bold;
  width: 100%;
  cursor: pointer;
  text-transform: capitalize !important;
  font-size: 0.875rem !important;
  line-height: 2.6875rem;
  text-align: left;
}
/* line 92, ../../scss/app/widgets/_dropdown.scss */
.select-box_options li:hover {
  background: #f0f0f0;
}
/* line 96, ../../scss/app/widgets/_dropdown.scss */
.select-box_options li:before {
  font-family: "VZIcons";
  content: "\e65e";
  color: #cd040b;
  padding-right: 0.625rem;
  visibility: hidden;
}
/* line 104, ../../scss/app/widgets/_dropdown.scss */
.select-box_options li.active:before {
  visibility: visible;
}
.w_filter {
    float: left;
    left: -0rem;
    top: 13.8rem;
    border-bottom:1px solid #000;
  }
@media only screen and (max-width:768px) {
  #filter-category{
  padding-left: 6.9rem !important;
  padding-top: 1rem !important;
}
}
@media only screen and  (min-width:1024px) and (max-width:1024px) and (min-height: 768px) and (max-height:768px) {
  #filter-category{
    padding-left: 23.5rem !important;
  }
}
@media only screen and (min-width:1024px) and (max-width:1024px) and (min-height: 1366px) and (max-height:1366px) {
  #filter-category{
    padding-left: 23.5rem !important;
  }
}
@media only screen and (max-width: 638px) {
  /* line 2, ../../scss/app/widgets/_product-filter.scss */
  .w_filter {
    /*position: fixed;
    top: rem-calc(50);*/
    background: #f6f6f6;
    width: 100%;
    padding: 0 1.25rem;
    display: none;
  }
}
@media only screen and (min-width: 639px) and (max-width: 767px) {
  .w_filter {
    background:#fff;
    margin-top: 1.3rem;
    position: absolute;
    z-index: 600;
  }
  #filter-category {
    width: max-content;
  }
}
@media only screen and (min-width: 639px) and (max-width: 768px) {
  .accessories-result .tpgrid_item .tpgrid_top-rated.discountTag{
    left: -1px !important;
    /* top: -14px; */
  }
}
@media only screen and (min-width: 768px) {
  /* line 2, ../../scss/app/widgets/_product-filter.scss */
  .w_filter {
    width: 100%;
    display: block;
    height: auto;
    overflow: hidden;
    position: absolute;
    background: #fff;
    z-index: 639;
  }
}

/* line 31, ../../scss/app/widgets/_product-filter.scss */
.filter-title {
  margin-top: 1.125rem;
  font-size: 1.125rem;
}

.filter-sub-category{
  border-bottom: 1px solid #000;
  margin-top:1rem;
}

@media only screen and (max-width: 638px) {
  /* line 36, ../../scss/app/widgets/_product-filter.scss */
  .filter-result, .filter-clear-all {
    display: none;
  }
}

/* line 43, ../../scss/app/widgets/_product-filter.scss */
.panel.m_panel-category {
  height: auto;
  overflow: hidden;
  margin-bottom: 0;
}
@media only screen and (min-width: 639px) {
  /* line 43, ../../scss/app/widgets/_product-filter.scss */
  .panel.m_panel-category {
    background: transparent !important;
    padding: 0 0 !important;
  }
}

/* line 57, ../../scss/app/widgets/_product-filter.scss */
.m_filter-color li label {
  font-weight: normal;
}
/* line 61, ../../scss/app/widgets/_product-filter.scss */
.m_filter-color li span {
  width: 1.3125rem;
  height: 1.3125rem;
  border: 0.0625rem solid #cccccc;
  display: inline-block;
  margin-right: 0.375rem;
  /* float: left; */
}

/* line 72, ../../scss/app/widgets/_product-filter.scss */
.filter-category {
  margin-bottom: 0;
}
/* line 75, ../../scss/app/widgets/_product-filter.scss */
.filter-category li {
  padding: 0.3125rem 0;
  cursor: pointer;
}
/* line 79, ../../scss/app/widgets/_product-filter.scss */
.filter-category li a {
  font-size: 0.875rem;
  color: #333333;
  font-weight: normal;
}
/* line 84, ../../scss/app/widgets/_product-filter.scss */
.filter-category li a:hover {
  color: #cd040b;
}
/* line 89, ../../scss/app/widgets/_product-filter.scss */
.filter-category li label {
  font-weight: normal;
}

/* line 95, ../../scss/app/widgets/_product-filter.scss */
.filter-category-selected {
  margin-top: 0.625rem;
  margin-bottom: 0;
}
@media only screen and (max-width: 638px) {
  /* line 95, ../../scss/app/widgets/_product-filter.scss */
  .filter-category-selected {
    margin-top: 0;
  }
}
/* line 103, ../../scss/app/widgets/_product-filter.scss */
.filter-category-selected li {
  padding: 0.3125rem 0;
  position: relative;
  padding-right: 1.5625rem;
}
@media only screen and (max-width: 638px) {
  /* line 103, ../../scss/app/widgets/_product-filter.scss */
  .filter-category-selected li {
    padding-right: 1.5625rem;
    float: left;
  }
}
/* line 113, ../../scss/app/widgets/_product-filter.scss */
.filter-category-selected li a {
  font-size: 0.875rem;
  color: #333333;
  font-weight: bold;
}
/* line 119, ../../scss/app/widgets/_product-filter.scss */
.filter-category-selected li .filter-category-clear:after {
  font-family: "VZIcons";
  content: "\e65b";
  font-size: 0.625rem;
  color: #a2a2a2;
  cursor: pointer;
}
@media only screen and (max-width: 638px) {
  /* line 119, ../../scss/app/widgets/_product-filter.scss */
  .filter-category-selected li .filter-category-clear:after {
    padding-left: 0.625rem;
  }
}
@media only screen and (min-width: 639px) {
  /* line 119, ../../scss/app/widgets/_product-filter.scss */
  .filter-category-selected li .filter-category-clear:after {
    position: absolute;
    top: 50%;
    right: 0.625rem;
    margin-top: -0.625rem;
  }
}

/* line 141, ../../scss/app/widgets/_product-filter.scss */
.filter-sub-category .m_accordion-summary {
  background: #ffffff;
  font-size: 1rem;
  font-weight: bold;
  padding: .5rem 0 !important;
  line-height: 2.1875rem !important;
  min-height: 2.1875rem !important;
  border-top: 1px solid #000 !important;
  cursor: pointer;
}
/* line 152, ../../scss/app/widgets/_product-filter.scss */
.filter-sub-category .m_accordion-summary:first-child {
  border-bottom: 0 !important;
}
/* line 156, ../../scss/app/widgets/_product-filter.scss */
.filter-sub-category .m_accordion-summary:active {
  background: #ffffff !important;
}
/* line 160, ../../scss/app/widgets/_product-filter.scss */
.filter-sub-category .m_accordion-summary:before {
  top: 0.625rem !important;
  font-size: 1rem;
  right: 0;
}
/* line 166, ../../scss/app/widgets/_product-filter.scss */
.filter-sub-category .m_accordion-summary:hover::before {
  right: 0;
}
/* line 170, ../../scss/app/widgets/_product-filter.scss */
.filter-sub-category .m_accordion-summary.open {
  border-bottom: 0.0625rem solid #cccccc !important;
}
/* line 173, ../../scss/app/widgets/_product-filter.scss */
.filter-sub-category .m_accordion-summary.open + .m_accordion-details {
  border-bottom: 0 !important;
  padding-top: 0.75rem !important;
}
/* line 182, ../../scss/app/widgets/_product-filter.scss */
.filter-sub-category .m_accordion-details {
  background: #ffffff;
  padding-top: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-bottom: 1rem;
}
/* line 188, ../../scss/app/widgets/_product-filter.scss */
.filter-sub-category .m_accordion-details:active {
  background: #ffffff !important;
}
/* line 192, ../../scss/app/widgets/_product-filter.scss */
.filter-sub-category .m_accordion-details label {
  font-weight: normal;
}

/* line 198, ../../scss/app/widgets/_product-filter.scss */
.w_filter-mobile {
  display: none;
  padding: 0.9375rem 0.9375rem 0.9375rem 0.9375rem;
  position: relative;
  height: 3.125rem;
  background: #fff;
  color: #000;
}
@media only screen and (max-width: 638px) {
  /* line 198, ../../scss/app/widgets/_product-filter.scss */
  .w_filter-mobile {
    display: block;
  }
  .filter-sub-category .m_accordion-summary:last-child{border:none;}
  .w_filter-mobile .filter-mobile_category{box-shadow:0px 10px 10px 0px rgba(0,0,0,0.20);z-index:700;}
}
/* line 210, ../../scss/app/widgets/_product-filter.scss */
.w_filter-mobile a:focus {
  color: #06C;
}

/* line 215, ../../scss/app/widgets/_product-filter.scss */
.filter-mobile_category {
  display: none;
  position: absolute;
  top: 2.5rem;
  left: 0;
  width: 100%;
  background: #fff;
  z-index: 500;
  padding: 0 0.9375rem;
  text-align: left;
}
@media only screen and (max-width: 638px) {
  /* line 215, ../../scss/app/widgets/_product-filter.scss */
  .filter-mobile_category {
    display: block;
  }
}
/* line 230, ../../scss/app/widgets/_product-filter.scss */
.filter-mobile_category .w_select-box {
  padding: 0 0;
}

/* line 235, ../../scss/app/widgets/_product-filter.scss */
.w_promo-section {
  background: #d8dada;
  padding: 1.125rem 1.125rem;
  height: auto;
  overflow: hidden;
}
@media only screen and (max-width: 637px) {
  /* line 235, ../../scss/app/widgets/_product-filter.scss */
  .w_promo-section {
    padding: 0.9375rem 1.5rem;
  }
}
@media only screen and (min-width: 638px) and (max-width: 768px) {
  /* line 235, ../../scss/app/widgets/_product-filter.scss */
  .w_promo-section {
    padding: 0.9375rem 0.9375rem;
  }
}

/* line 254, ../../scss/app/widgets/_product-filter.scss */
.promo-section_image {
  width: 8.125rem;
  height: 8.125rem;
  border-radius: 7.5rem;
  background: #f6f6f6;
}
@media only screen and (max-width: 638px) {
  /* line 254, ../../scss/app/widgets/_product-filter.scss */
  .promo-section_image {
    width: 3.75rem;
    height: 3.75rem;
  }
}

/* line 267, ../../scss/app/widgets/_product-filter.scss */
.no-decoration {
  text-decoration: none;
}

/* line 271, ../../scss/app/widgets/_product-filter.scss */
#filter {
  position: fixed;
  top: 10rem;
  overflow-y: scroll;
  z-index: 500;
  width: 100%;
}
/* line 278, ../../scss/app/widgets/_product-filter.scss */
#filter .w_filter {
  display: block;
}

@media only screen and (max-width: 638px) {
  /* line 283, ../../scss/app/widgets/_product-filter.scss */
  [data-subfilter] {
    overflow-y: scroll;
    background: #fff;
    z-index: 5000;
    margin: 0 ;
    padding: 0;
  }
}
@media only screen and (min-width: 639px) {
  /* line 283, ../../scss/app/widgets/_product-filter.scss */
  [data-subfilter] {
    height: auto !important;
  }
}

@media only screen and (min-width: 60.625rem) {
  /* line 2, ../../scss/app/widgets/_tpModal.scss */
  .w_modal.accessories_modal {
    width: 43%;
    margin-left: 0;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}
@media only screen and (min-width: 60.625rem) {
  /* line 13, ../../scss/app/widgets/_tpModal.scss */
  .w_modal.m_modal-width {
    width: 43%;
    margin-left: 0;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}

/* line 3, ../../scss/app/widgets/_googlenestproducts.scss */
.nest-products {
  padding-top: 60px;
  clear: both;
  color: #0f0f0f;
}
/* line 9, ../../scss/app/widgets/_googlenestproducts.scss */
.nest-products .row.m_width {
  max-width: 61.5625rem;
}

/* line 13, ../../scss/app/widgets/_googlenestproducts.scss */
.nest-products_container {
  padding: 50px 0px 60px 0px;
}
@media only screen and (max-width: 60.5625rem) {
  /* line 13, ../../scss/app/widgets/_googlenestproducts.scss */
  .nest-products_container {
    padding: 50px 20px 60px 20px;
  }
}
@media only screen and (max-width: 47.9375rem) {
  /* line 13, ../../scss/app/widgets/_googlenestproducts.scss */
  .nest-products_container {
    padding: 15px 15px 30px 15px;
  }
}

/* line 22, ../../scss/app/widgets/_googlenestproducts.scss */
.nest-products_header {
  font-size: 48px;
  font-weight: bold;
  line-height: 54px;
}
@media only screen and (max-width: 47.9375rem) {
  /* line 22, ../../scss/app/widgets/_googlenestproducts.scss */
  .nest-products_header {
    font-size: 30px;
    line-height: 1;
  }
}

/* line 31, ../../scss/app/widgets/_googlenestproducts.scss */
.nest-products_largetext {
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.6px;
  padding-bottom: 18px;
}

/* line 37, ../../scss/app/widgets/_googlenestproducts.scss */
.nest-products_subheader {
  font-size: 32px;
  font-weight: bold;
  line-height: 1;
}
@media only screen and (max-width: 47.9375rem) {
  /* line 37, ../../scss/app/widgets/_googlenestproducts.scss */
  .nest-products_subheader {
    font-size: 26px;
  }
}

/* line 45, ../../scss/app/widgets/_googlenestproducts.scss */
.m_custom-col1, .m_custom-col3 {
  width: 30%;
}
@media only screen and (max-width: 47.9375rem) {
  /* line 45, ../../scss/app/widgets/_googlenestproducts.scss */
  .m_custom-col1, .m_custom-col3 {
    width: 100%;
    padding: 0px 20px 15px 20px;
  }
}

/* line 53, ../../scss/app/widgets/_googlenestproducts.scss */
.m_custom-col2 {
  width: 40%;
}
@media only screen and (max-width: 47.9375rem) {
  /* line 53, ../../scss/app/widgets/_googlenestproducts.scss */
  .m_custom-col2 {
    width: 100%;
    padding: 0px 20px 15px 20px;
  }
}

/* line 60, ../../scss/app/widgets/_googlenestproducts.scss */
.grey-box {
  background-color: #e4e4e2;
  padding: 30px 0px 90px 30px;
  clear: both;
}
@media only screen and (max-width: 60.5625rem) {
  /* line 60, ../../scss/app/widgets/_googlenestproducts.scss */
  .grey-box {
    padding: 30px 30px 90px 30px;
    margin-right: 30px;
  }
}
@media only screen and (max-width: 47.9375rem) {
  /* line 60, ../../scss/app/widgets/_googlenestproducts.scss */
  .grey-box {
    padding: 20px 20px 20px 15px;
    display: none;
  }
}

/* line 72, ../../scss/app/widgets/_googlenestproducts.scss */
.smart-device_logo {
  width: 150px;
  height: 150px;
  margin: 30px 0px;
}
@media only screen and (max-width: 47.9375rem) {
  /* line 72, ../../scss/app/widgets/_googlenestproducts.scss */
  .smart-device_logo {
    width: 208px;
    height: 208px;
  }
}

/* line 82, ../../scss/app/widgets/_googlenestproducts.scss */
.smart-device_heading {
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 20px;
}
@media only screen and (max-width: 47.9375rem) {
  /* line 82, ../../scss/app/widgets/_googlenestproducts.scss */
  .smart-device_heading {
    padding-bottom: 10px;
    font-size: 14px;
  }
}

/* line 91, ../../scss/app/widgets/_googlenestproducts.scss */
.smart-device_text {
  padding-bottom: 20px;
  line-height: 17px;
}
@media only screen and (max-width: 47.9375rem) {
  /* line 91, ../../scss/app/widgets/_googlenestproducts.scss */
  .smart-device_text {
    padding-bottom: 10px;
    width: 50%;
  }
}

/* line 99, ../../scss/app/widgets/_googlenestproducts.scss */
.smart-device_list {
  line-height: 17px;
}

/* line 102, ../../scss/app/widgets/_googlenestproducts.scss */
.accessories-banner {
  background-image: url("../../img/accessories/media/images-accessories/accessories-banner.jpg");
  background-repeat: no-repeat;
  display: block;
  color: #0f0f0f;
  width: 100%;
  height: 327px;
  clear: both;
  position: relative;
  margin: 70px 0px;
  background-size: 100%;
}
@media only screen and (max-width: 47.9375rem) {
  /* line 102, ../../scss/app/widgets/_googlenestproducts.scss */
  .accessories-banner {
    background-position: 75%;
    margin: 0px;
  }
}

@media only screen and (max-width: 47.9375rem) {
  /* line 117, ../../scss/app/widgets/_googlenestproducts.scss */
  .accessories-banner_overlay {
    background-color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
  }
}

/* line 128, ../../scss/app/widgets/_googlenestproducts.scss */
.accessories-text_bold {
  position: absolute;
  width: 45%;
  left: 3%;
  top: 15%;
}
@media only screen and (max-width: 47.9375rem) {
  /* line 128, ../../scss/app/widgets/_googlenestproducts.scss */
  .accessories-text_bold {
    width: 90%;
    left: 6%;
  }
}

/* line 138, ../../scss/app/widgets/_googlenestproducts.scss */
.accessories-text {
  position: absolute;
  width: 25%;
  left: 3%;
  top: 42%;
}
@media only screen and (max-width: 47.9375rem) {
  /* line 138, ../../scss/app/widgets/_googlenestproducts.scss */
  .accessories-text {
    width: 62%;
    top: 48%;
    left: 6%;
  }
}

/* line 149, ../../scss/app/widgets/_googlenestproducts.scss */
.accessories-button {
  position: absolute;
  left: 3%;
  top: 65%;
}
/* line 153, ../../scss/app/widgets/_googlenestproducts.scss */
.accessories-button .button {
  letter-spacing: 0.6px;
}
@media only screen and (max-width: 47.9375rem) {
  /* line 149, ../../scss/app/widgets/_googlenestproducts.scss */
  .accessories-button {
    width: 62%;
    top: 74%;
    left: 6%;
  }
}

.modal_header {
  height:102px
}
@media only screen and (max-width: 638px) {
  .modal_header {
    height:77px
  }  
}

/* line 170, ../../scss/app/widgets/_googlenestproducts.scss */
.w_modal.m_custom_width .modal_header {
  padding: 40px 0px 0px 40px;
}
/* line 173, ../../scss/app/widgets/_googlenestproducts.scss */
.w_modal.m_custom_width .modal_content {
  padding: 30px 40px 50px 40px;
}
/* line 176, ../../scss/app/widgets/_googlenestproducts.scss */
.w_modal.m_custom_width .modal_footer {
  padding: 0px 40px 50px 40px;
}
/* line 179, ../../scss/app/widgets/_googlenestproducts.scss */
.w_modal.m_custom_width .alert_header {
  font-size: 32px;
}
@media only screen and (max-width: 47.9375rem) {
  /* line 182, ../../scss/app/widgets/_googlenestproducts.scss */
  .w_modal.m_custom_width .m_margin-bottom {
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 48rem) {
  /* line 190, ../../scss/app/widgets/_googlenestproducts.scss */
  .w_modal.m_custom_width {
    width: 650px;
    margin-left: -325px;
  }
}

/* Layout Style Updates*/
/* line 3, ../../scss/app/branding/_new-brand.scss */
.main {
  max-width: 80rem!important;
  background-color: #fff !important;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
}

@media only screen and (min-width: 639px) {
  /* line 8, ../../scss/app/branding/_new-brand.scss */
  .accessories-header + .main {
    padding-top: 3.125rem !important;
  }
}

/* line 14, ../../scss/app/branding/_new-brand.scss */
body {
  background-color: #fff !important;
}

/* line 18, ../../scss/app/branding/_new-brand.scss */
.page.bg-white > .main {
  background: #fff !important;
}
 

/*Mobile Header Styles Starts*/
/* line 22, ../../scss/app/branding/_new-brand.scss */
/* .w_header_desktop { */
 /* height: 9.375rem !important; for responsive headers*/
/* } */
/* line 25, ../../scss/app/branding/_new-brand.scss */
.w_header_desktop .header_desktop_vzlogo {
  text-align: left;
  margin: 0 auto;
  max-width: 61.25rem;
  position: relative;
  top: 4.375rem;
}
@media only screen and (min-width: 48rem) and (orientation: portrait) {
  /* line 25, ../../scss/app/branding/_new-brand.scss */
  .w_header_desktop .header_desktop_vzlogo {
    left: 0.9375rem;
  }
}

/* line 38, ../../scss/app/branding/_new-brand.scss */
.vzicon.tiny:before {
  font-size: 0.875rem;
}

/* line 42, ../../scss/app/branding/_new-brand.scss */
.w_top-bar {
  background: #fff;
}

/* line 46, ../../scss/app/branding/_new-brand.scss */
.top-bar_charm {
  height: auto !important;
}
/* line 49, ../../scss/app/branding/_new-brand.scss */
.top-bar_charm.active {
  background-color: #fff !important;
}

/* line 54, ../../scss/app/branding/_new-brand.scss */
.icon-nav-menu {
  /* background: transparent url("../../images/icons-all.png") no-repeat scroll 15px -60px; */
  width: 50px;
  cursor: pointer;
  outline: medium none;
  position: absolute;
  left: 0px;
  height: 20px !important;
  top: 16px;
}
/* line 64, ../../scss/app/branding/_new-brand.scss */
.icon-nav-menu:before {
  content: '' !important;
}

/* line 69, ../../scss/app/branding/_new-brand.scss */
.icon-options {
  /* background: transparent url("../../images/icons-all.png") no-repeat scroll 7px -29px; */
  width: 40px;
  cursor: pointer;
  outline: medium none;
  position: absolute;
  right: 0px;
  height: 25px !important;
  top: 13px;
}
/* line 79, ../../scss/app/branding/_new-brand.scss */
.icon-options:before {
  content: '' !important;
}

/* line 84, ../../scss/app/branding/_new-brand.scss */
.top-bar_img {
  width: auto !important;
  height: auto !important;
  margin-top: 0.375rem;
  padding-left: 0.375rem !important;
}

/*Mobile Header Styles Ends*/
/* Top Toolbar Style Updates*/
/* line 93, ../../scss/app/branding/_new-brand.scss */
.w_toolbar {
  clear: both;
}
/* line 96, ../../scss/app/branding/_new-brand.scss */
.w_toolbar .toolbar_product {
  display: none;
}
/* line 100, ../../scss/app/branding/_new-brand.scss */
.w_toolbar.m_toolbar, .w_toolbar.toolbar_fixed {
  background: #fff;
  height: 3.125rem;
}
/* line 105, ../../scss/app/branding/_new-brand.scss */
.w_toolbar.m_toolbar {
  position: absolute;
  z-index: 650;
  line-height: 1.9375rem;
}
@media only screen and (min-width: 639px) {
  /* line 110, ../../scss/app/branding/_new-brand.scss */
  /* .w_toolbar.m_toolbar + .main { */
   /* padding-top: 3.125rem !important;  Responsive Headers issue*/
  /* } */
}
/* line 117, ../../scss/app/branding/_new-brand.scss */
.w_toolbar.m_toolbar-details {
  top: 3.125rem;
  height: auto;
  overflow: hidden;
  z-index: 99;
}
/* line 125, ../../scss/app/branding/_new-brand.scss */
.w_toolbar .toolbar_width {
  max-width: 80rem;
  margin: 0 auto;
  position: relative;
}
/* line 131, ../../scss/app/branding/_new-brand.scss */
.w_toolbar.toolbar_fixed {
  max-width: 100% !important;
}
/* line 134, ../../scss/app/branding/_new-brand.scss */
.w_toolbar.toolbar_fixed .toolbar_count {
  left: 0.25rem;
}
/* line 139, ../../scss/app/branding/_new-brand.scss */
.w_toolbar .toolbar_count-placeholder {
  background: #cd040b;
  top: -0.375rem;
  text-align: center;
}
@media only screen and (max-width: 638px) {
  /* line 139, ../../scss/app/branding/_new-brand.scss */
  .w_toolbar .toolbar_count-placeholder {
    top: 0.0625rem;
  }
}
/* line 149, ../../scss/app/branding/_new-brand.scss */
.w_toolbar .icon-chevron-down:before, .w_toolbar .icon-chevron-up:before {
  font-size: 0.875rem;
}
@media only screen and (max-width: 30rem) {
  /* line 153, ../../scss/app/branding/_new-brand.scss */
  .w_toolbar .toolbar_cart {
    position: absolute;
    right: 0;
  }
}
@media only screen and (max-width: 767px) {
  /* line 153, ../../scss/app/branding/_new-brand.scss */
  .w_toolbar .toolbar_cart {
    margin-right: 0.625rem;
  }
}
@media only screen and (min-width: 48rem) {
  /* line 153, ../../scss/app/branding/_new-brand.scss */
  .w_toolbar .toolbar_cart {
    line-height: 1.8125rem;
    padding-right: 0;
    margin-left: 2.5rem;
  }
}
@media only screen and (min-width: 60.625rem) {
  /* line 153, ../../scss/app/branding/_new-brand.scss */
  .w_toolbar .toolbar_cart {
    margin-right: 0;
  }
}
/* line 173, ../../scss/app/branding/_new-brand.scss */
.w_toolbar .toolbar_cart span {
  font-size: 0.875rem;
}
@media only screen and (max-width: 638px) {
  /* line 178, ../../scss/app/branding/_new-brand.scss */
  .w_toolbar .cartbutton {
    padding-top: 0 !important;
  }
}
/* line 186, ../../scss/app/branding/_new-brand.scss */
.w_toolbar .toolbar_count {
  position: absolute;
  line-height: 0.875rem;
  left: 0.25rem;
  color: #fff;
  font-weight: bold;
}

/* line 198, ../../scss/app/branding/_new-brand.scss */
.toolbar_items {
  position: relative;
}
/* line 201, ../../scss/app/branding/_new-brand.scss */
.toolbar_items li {
  text-transform: inherit !important;
  font-size: 0.875rem;
  /*line-height: rem-calc(48) !important;*/
}
/* line 207, ../../scss/app/branding/_new-brand.scss */
.toolbar_items .toolbar_home-image {
  top: 0.3125rem;
}
@media only screen and (max-width: 638px) {
  /* line 207, ../../scss/app/branding/_new-brand.scss */
  .toolbar_items .toolbar_home-image {
    top: 0;
  }
}

/* line 216, ../../scss/app/branding/_new-brand.scss */
.toolbar_filter {
  font-weight: bold;
}
@media only screen and (min-width: 60.625rem) {
  /* line 216, ../../scss/app/branding/_new-brand.scss */
  .toolbar_filter {
    margin-left: 0;
  }
}

@media only screen and (max-width: 638px) {
  /* line 225, ../../scss/app/branding/_new-brand.scss */
  .toolbar_custom-boxicon::before {
    top: -0.4375rem;
  }
}
/* line 230, ../../scss/app/branding/_new-brand.scss */
.toolbar_sub-item {
  background-color: #ecedee !important;
  box-shadow: 1px 3px 3px 1px #ccc;
}
/* line 234, ../../scss/app/branding/_new-brand.scss */
.toolbar_sub-item ul {
  background: #d2d3d4 !important;
}
/* line 238, ../../scss/app/branding/_new-brand.scss */
.toolbar_sub-item ul li:hover {
  background: #2b2e33 !important;
}
/* line 242, ../../scss/app/branding/_new-brand.scss */
.toolbar_sub-item ul li a {
  text-transform: capitalize !important;
  font-size: 0.75rem !important;
}

@media only screen and (min-width: 60.625rem) {
  /* line 250, ../../scss/app/branding/_new-brand.scss */
  .toolbar_support {
    margin-right: 0;
  }
}

/* line 256, ../../scss/app/branding/_new-brand.scss */
.toolbar_support .toolbar_support-items li {
  font-size: 0.875rem;
  line-height: 3rem !important;
}

/* Hero Banner Style Updates*/
/* line 261, ../../scss/app/branding/_new-brand.scss */
.w_hero-banner {
  background-color: #fff;
}

/* line 265, ../../scss/app/branding/_new-brand.scss */
.hero-banner_title {
  color: #cd040b;
  font-weight: bold;
  font-size: 2.25rem;
}
@media only screen and (max-width: 47.9375rem) {
  /* line 265, ../../scss/app/branding/_new-brand.scss */
  .hero-banner_title {
    font-size: 2.125rem;
  }
}
@media only screen and (min-width: 60.625rem) {
  /* line 265, ../../scss/app/branding/_new-brand.scss */
  .hero-banner_title {
    margin-left: 0;
  }
}

@media only screen and (min-width: 638px) {
  /* line 279, ../../scss/app/branding/_new-brand.scss */
  .hero-banner_details {
    width: 45%;
  }
}

/* Accessories Grid Style Updates*/
/* line 286, ../../scss/app/branding/_new-brand.scss */
.w_tpgrid {
  margin-bottom: 40px;
  float: none;
  clear: both;
}
@media only screen and (max-width: 638px) {
  /* line 286, ../../scss/app/branding/_new-brand.scss */
  .w_tpgrid {
    float: none;
    clear: both;
  }
}

/* line 301, ../../scss/app/branding/_new-brand.scss */
.tpgrid_first-item {
  display: none;
}

/* line 305, ../../scss/app/branding/_new-brand.scss */
.w_others .tpgrid_item {
  border-top: 0;
}

/* line 309, ../../scss/app/branding/_new-brand.scss */
.tpgrid_item {
  width: 23.75635%;
  height: 30.125rem !important;
  /* float: left; */
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
  position: relative;
  /* background-color: transparent; */
  background-color: #f6f6f6;
  padding: 0;
  padding-bottom: 40px;
}
@media only screen and (min-width: 639px) and (max-width: 1020px) {
  /* line 309, ../../scss/app/branding/_new-brand.scss */
  .tpgrid_item {
    width: 31.98% !important;
  }
}
@media only screen and (min-width: 639px) {
  /* line 309, ../../scss/app/branding/_new-brand.scss */
  .tpgrid_item {
    border: 0;
    margin: 5px;
  }
}
@media only screen and (max-width: 638px) {
  /* line 309, ../../scss/app/branding/_new-brand.scss */
  .tpgrid_item {
    height: auto !important;
    overflow: hidden;
    border-width: 0 0 0.0625rem 0;
    /*padding-top: 30px;*/
  }
}
@media only screen and (min-width: 600px) and (max-width: 767px) and (orientation: portrait) {
  /* line 309, ../../scss/app/branding/_new-brand.scss */
  .tpgrid_item {
    padding-bottom: 0 !important;
  }
}
/* line 341, ../../scss/app/branding/_new-brand.scss */
.tpgrid_item.outof_stock {
  clear: none !important;
}
/* line 345, ../../scss/app/branding/_new-brand.scss */
.tpgrid_item .tpgrid_product-name {
  font-weight: bold;
  font-size: 1rem !important;
  line-height: 1.1875rem;
  color: #333333;
  margin-bottom: 1rem;
  min-height: 3.4375rem;
  margin-top: 1.2rem;
}
/* line 353, ../../scss/app/branding/_new-brand.scss */
.tpgrid_item .tpgrid_product-name a {
  font-weight: bold;
  font-size: 1rem !important;
  color: #333333 !important;
  text-decoration: none !important;
}
/* line 361, ../../scss/app/branding/_new-brand.scss */
.tpgrid_item .tpgrid_product-price {
  font-weight: bold;
  font-size: 1rem !important;
  color: #000;
}
@media only screen and (min-width: 638px) {
  /* line 361, ../../scss/app/branding/_new-brand.scss */
  /* .tpgrid_item .tpgrid_product-price {
    padding-bottom: rem-calc(20);
  } */
}
/* line 371, ../../scss/app/branding/_new-brand.scss */
.tpgrid_item .tpgrid_top-rated, .tpgrid_item .tpgrid_new-item, .tpgrid_item .tpgrid_other-item, .tpgrid_item .tpgrid_product-colors {
  color: #000;
  left: 0;
  font-size: 0.875rem;
  font-weight: 700;
  height: 1.875rem;
  z-index:11;
  position: relative;
}

/* line 383, ../../scss/app/branding/_new-brand.scss */
.tpgrid_item .vzicon, .tpgrid_top-rated .vzicon {
  margin-right: 5px;
  color: #fff;
  background: #333333;
  width: 1.875rem;
  height: 1.875rem;
  text-align: center;
}
/* line 392, ../../scss/app/branding/_new-brand.scss */
.tpgrid_item .vzicon.icon-price-tag:before, .tpgrid_top-rated .vzicon.icon-price-tag:before {
  /* -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg); */
  display: inline-block;
  font-size: 1.25rem;
}

/* line 404, ../../scss/app/branding/_new-brand.scss */
.tpgrid_new-item .vzicon {
  margin-right: 5px;
  width: 1.875rem;
  height: 1.875rem;
  line-height: 1.75rem;
  color: #fff;
  background: #333333;
}

/* line 413, ../../scss/app/branding/_new-brand.scss */
.tpgrid_placeholder {
  padding-top: .5rem;
}
@media only screen and (max-width: 638px) {
  /* line 413, ../../scss/app/branding/_new-brand.scss */
  .tpgrid_placeholder {
    float: left;
  }
}
@media only screen and (min-width: 639px) {
  /* line 413, ../../scss/app/branding/_new-brand.scss */
  div.accessories-result .tpgrid_placeholder {
    height: 245px;
    display: flex;
    align-items: center;
  }
  .tpgrid_placeholder {
    width: 75.1269%;
    height: auto; 
    margin: 0 auto 5% auto;
    position: relative;
    z-index:10;
  }
}

/* line 429, ../../scss/app/branding/_new-brand.scss */
.tpgrid_detail-box {
  box-shadow: 0px 10px 8px -1px #C5C5C5;
}
@media only screen and (max-width: 637px) {
  /* line 429, ../../scss/app/branding/_new-brand.scss */
  .tpgrid_detail-box {
    display: none;
  }
}

/* line 437, ../../scss/app/branding/_new-brand.scss */
.tpgrid_product {
  position: relative;
}
@media only screen and (max-width: 638px) {
  /* line 437, ../../scss/app/branding/_new-brand.scss */
  .tpgrid_product {
    float: left;
    width: 50%;
    left: 0%;
    margin-top: 0;
  }
}
@media only screen and (min-width: 639px) {
  /* line 437, ../../scss/app/branding/_new-brand.scss */
  .tpgrid_product {
    padding: 1.25rem 0 0 0.9375rem;
    margin-top: 0.75rem;
    left: 0%;
  }
}

/*Other Styles*/
/* line 459, ../../scss/app/branding/_new-brand.scss */
.product-color {
  padding: 0.3125rem 0.3125rem 0.3125rem 0.3125rem;
  width: 1.375rem;
  height: 1.375rem;
  border: 0.0625rem solid #4b4b4b;
  cursor: pointer;
  border-radius: 100%;
}

/* line 467, ../../scss/app/branding/_new-brand.scss */
.w-150 {
  max-width: 150px;
}

/* line 471, ../../scss/app/branding/_new-brand.scss */
.animateborder {
  display: block !important;
}

/*Cart Box Style*/
/* line 476, ../../scss/app/branding/_new-brand.scss */
.w_cart-box {
  margin-top: 3rem;
}
/* line 482, ../../scss/app/branding/_new-brand.scss */
.w_cart-box .cart-box_placeholder .button {
  text-align: left;
  width: auto !important;
  padding-left: 1.875rem !important;
  padding-right: 3.5rem !important;
}

/* line 490, ../../scss/app/branding/_new-brand.scss */
.cart-box_button {
  padding-top: .625rem;
  top: -0.5625rem;
  height: 3.125rem;
}
/* line 495, ../../scss/app/branding/_new-brand.scss */
.cart-box_button span {
 top: .75rem; 
 right: 1rem;;
}

/* line 500, ../../scss/app/branding/_new-brand.scss */
.cart-box_notify {
  margin-top: 0;
}

/*Footer Styles*/
/* line 505, ../../scss/app/branding/_new-brand.scss */
.w_footer_desktop {
  position: relative;
}

/* line 509, ../../scss/app/branding/_new-brand.scss */
.footer_desktop {
  margin: 0 auto;
  text-align: left;
}
@media only screen and (min-width: 768px) and (max-width: 984px) {
  /* line 509, ../../scss/app/branding/_new-brand.scss */
  /* .footer_desktop { */
    /*margin-left: rem-calc(15);*/
  /* } */
}

/* line 519, ../../scss/app/branding/_new-brand.scss */
.footer_device .vzicon.xlarge:before {
  font-size: 2.25rem !important;
}

/* Accessories Details Page updates*/
/* line 527, ../../scss/app/branding/_new-brand.scss */
.products-details_cart {
  background: transparent;
}

/* line 531, ../../scss/app/branding/_new-brand.scss */
.products-list_breadcrumb {
  font-weight: bold;
}

/* line 535, ../../scss/app/branding/_new-brand.scss */
.carousel-next, .carousel-prev {
  top: 0;
}

/* Form Style Updates*/
/*select {
    color: $black !important;
    height: rem-calc(35) !important;
    background-color: transparent !important;
}*/
/*Accessories Details Page*/
/* line 549, ../../scss/app/branding/_new-brand.scss */
.products-details_heading {
  color: #cd040b;
  font-size: 2.25rem;
  line-height: 2.375rem;
}
@media only screen and (max-width: 638px) {
  /* line 549, ../../scss/app/branding/_new-brand.scss */
  .products-details_heading {
    display: none;
  }
}

/* line 559, ../../scss/app/branding/_new-brand.scss */
.products-list_title {
  display: none;
}
@media only screen and (max-width: 638px) {
  /* line 559, ../../scss/app/branding/_new-brand.scss */
  .products-list_title {
    display: block;
    padding-left:1rem;
  }
}

/* line 568, ../../scss/app/branding/_new-brand.scss */
.products-details_cart {
  position: relative;
}

/* line 572, ../../scss/app/branding/_new-brand.scss */
.w_products-list {
  width: 50%;
  padding: 0 0;
}
@media only screen and (max-width: 638px) {
  /* line 572, ../../scss/app/branding/_new-brand.scss */
  .w_products-list {
    width: 100%;
    margin-top: 0;
    padding: 0 0.9375rem;
  }
}

/* line 583, ../../scss/app/branding/_new-brand.scss */
.products-list_carousel {
  width: 100% !important;
}
/* line 586, ../../scss/app/branding/_new-brand.scss */
.products-list_carousel .w_carousel {
  text-align: center;
}

/* line 591, ../../scss/app/branding/_new-brand.scss */
.w_products-details {
  width: 50%;
  border: 0;
  padding: 0 0.9375rem;
}
@media only screen and (max-width: 638px) {
  /* line 591, ../../scss/app/branding/_new-brand.scss */
  .w_products-details {
    width: 100% !important;
    padding-left: 0;
  }
}
/* line 601, ../../scss/app/branding/_new-brand.scss */
.w_products-details p {
  font-size: 0.875rem !important;
}

/* line 606, ../../scss/app/branding/_new-brand.scss */
.products-details_addcart {
  position: relative;
  right: 0;
  bottom: 0;
  margin-left: 0;
}

/* line 613, ../../scss/app/branding/_new-brand.scss */
.w_products-description {
  padding-left: 0;
  padding-right: 0;
}
@media only screen and (min-width: 60.625rem) {
  /* line 613, ../../scss/app/branding/_new-brand.scss */
  .w_products-description {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
  }
}

/* line 623, ../../scss/app/branding/_new-brand.scss */
.w_product-info {
  padding: 0.625rem 0;
  height: auto;
  overflow: hidden;
  background: #fff;
  display: table;
  width: 100%;
}
@media only screen and (max-width: 60.5625rem) {
  /* line 623, ../../scss/app/branding/_new-brand.scss */
  .w_product-info {
    padding: 0.625rem 0.9375rem;
  }
}
/* line 635, ../../scss/app/branding/_new-brand.scss */
.w_product-info .button {
  display: table-cell;
  vertical-align: middle;
  text-align: left;
}

/* line 642, ../../scss/app/branding/_new-brand.scss */
.product-info_title {
  color: #cd040b;
  font-weight: bold;
  font-size: 1rem;
  display: table-cell;
  vertical-align: middle;
  text-align: left;
  width: 22%;
}

/* line 652, ../../scss/app/branding/_new-brand.scss */
.product-info_features {
  list-style-type: none;
  margin: 0.3125rem 0;
  padding: 0 0 0 0.625rem;
  display: table-cell;
  vertical-align: middle;
  text-align: left;
}
/* line 660, ../../scss/app/branding/_new-brand.scss */
.product-info_features li {
  font-weight: bold;
  font-size: 0.875rem;
  padding: 0 0.625rem;
  display: table-cell;
  vertical-align: middle;
  text-align: left;
  color: #333;
}
/* line 669, ../../scss/app/branding/_new-brand.scss */
.product-info_features li a {
  color: #000;
}

/* line 675, ../../scss/app/branding/_new-brand.scss */
.product-info_divider {
  display: table-cell;
  vertical-align: middle;
  text-align: left;
}

/*View Cart Styles*/
/* line 682, ../../scss/app/branding/_new-brand.scss */
.view-cart_quantity {
  width: 40%;
}

@media only screen and (max-width: 638px) {
  /* line 686, ../../scss/app/branding/_new-brand.scss */
  .view-cart_right {
    float: none;
    width: 100%;
  }
}

/* line 693, ../../scss/app/branding/_new-brand.scss */
.view-cart_free-ship {
  display: table;
  height: auto;
}
@media only screen and (max-width: 638px) {
  /* line 693, ../../scss/app/branding/_new-brand.scss */
  .view-cart_free-ship {
    width: 100%;
  }
}
/* line 701, ../../scss/app/branding/_new-brand.scss */
.view-cart_free-ship div:first-child {
  background-color: #fff;
  height: 4.3125rem;
  float: none;
  display: table-cell;
  vertical-align: middle;
}
@media only screen and (max-width: 767px) {
  /* line 701, ../../scss/app/branding/_new-brand.scss */
  .view-cart_free-ship div:first-child {
    width: 15%;
    text-align: center;
    padding-left: 0;
  }
}
@media only screen and (min-width: 768px) and (orientation: portrait) {
  /* line 701, ../../scss/app/branding/_new-brand.scss */
  .view-cart_free-ship div:first-child {
    width: 15%;
    text-align: center;
    padding-left: 0;
  }
}
@media only screen and (min-width: 768px) and (orientation: landscape) {
  /* line 701, ../../scss/app/branding/_new-brand.scss */
  .view-cart_free-ship div:first-child {
    width: 30.5%;
    text-align: center;
    padding-left: 0;
  }
}
/* line 727, ../../scss/app/branding/_new-brand.scss */
.view-cart_free-ship span {
  padding-bottom: 0;
  padding-top: 0;
  float: none;
  display: table-cell;
  vertical-align: middle;
}
@media only screen and (max-width: 768px) {
  /* line 727, ../../scss/app/branding/_new-brand.scss */
  .view-cart_free-ship span {
    top: 0 !important;
  }
}
/* line 739, ../../scss/app/branding/_new-brand.scss */
.view-cart_free-ship .view-cart_return-icon {
  top: 0;
}

/* line 744, ../../scss/app/branding/_new-brand.scss */
.view-cart_table-body .view-cart_column:nth-child(2) span {
  padding-top: 0.375rem;
}

/* line 748, ../../scss/app/branding/_new-brand.scss */
.view-cart_note p {
  font-size: 0.75rem;
}

/* line 752, ../../scss/app/branding/_new-brand.scss */
.products-details_color-section {
  margin-bottom: 0.75rem;
  width: 100%;
}
/* line 756, ../../scss/app/branding/_new-brand.scss */
.products-details_color-section .w_select-box {
  float: left !important;
  width: 45%;
  position: relative;
  top: 0.625rem;
}
@media only screen and (min-width: 60.625rem) {
  /* line 756, ../../scss/app/branding/_new-brand.scss */
  .products-details_color-section .w_select-box {
    width: 25%;    
  }
}
/* line 769, ../../scss/app/branding/_new-brand.scss */
.products-details_color-section .w_select-box .select-box_selected span:before {
  content: "Qty ";
}

/* line 778, ../../scss/app/branding/_new-brand.scss */
.products-details_color ul {
  margin: 0 0;
  max-width: 15.625rem;
}
/* line 782, ../../scss/app/branding/_new-brand.scss */
.products-details_color ul li {
  width: 2rem;
  height: 2rem;
  float: left;
  position: relative;
  cursor: pointer;
  font-size: 0.75rem;
  line-height: 0.9375rem;
  padding: 0.3125rem 0;
  text-align: center;
  border: 0.0625rem solid rgb(116, 118, 118) !important;
  margin-right: .5rem;
}

.products-details_color ul li.active{
  position: relative;
    display: inline-block;
    text-align: center;
    line-height: 1rem;
    -webkit-box-shadow: rgb(255, 255, 255) 0px 0px 0px 0.125rem inset;
    box-shadow: rgb(255, 255, 255) 0px 0px 0px 0.125rem inset;
    border: 0.0625rem solid rgb(0,0,0) !important;
}
/* line 794, ../../scss/app/branding/_new-brand.scss */
.products-details_color ul li.active:after {
  border: 0.0625rem solid rgb(0,0,0) !important;
    position: absolute;
    content: '';
    width: 1.625rem;
    height: 1.625rem;
    border-radius: 1rem;
    left: .125rem;
    top: .125rem;
}
/* line 806, ../../scss/app/branding/_new-brand.scss */
.products-details_color ul li[class^='text-white'] {
  color: #000;
}
/* line 810, ../../scss/app/branding/_new-brand.scss */
.products-details_color ul li.bg-black {
  color: #fff;
}
/* line 814, ../../scss/app/branding/_new-brand.scss */
.products-details_color ul li.bg-white:after {
  color: #333;
}
/* line 818, ../../scss/app/branding/_new-brand.scss */
.products-details_color ul li[data-disable-purchase] {
  cursor: default;
}

/*Begin Checkout Styles*/
/* line 827, ../../scss/app/branding/_new-brand.scss */
.page-cart-title {
  font-size: 2.25rem !important;
  color: #cd040b !important;
  font-weight: bold;
}

@media only screen and (min-width: 1025px) {
  /* line 833, ../../scss/app/branding/_new-brand.scss */
  .begincheckout .page-cart-title, .checkout .page-cart-title {
    padding-left: 0;
  }
}

/* line 839, ../../scss/app/branding/_new-brand.scss */
.userid-label {
  margin-top: 0;
}

/* line 843, ../../scss/app/branding/_new-brand.scss */
.no-signin p {
  height: 6.125rem;
  font-size: 0.875rem;
}

/* line 848, ../../scss/app/branding/_new-brand.scss */
.guest-checkout_login {
  font-size: 0.875rem;
}

@media only screen and (min-width: 60.625rem) {
  /* line 852, ../../scss/app/branding/_new-brand.scss */
  .w_start-checkout {
    padding-left: 0;
    margin-left: 0;
    margin-right: 0;
  }
}
/* line 859, ../../scss/app/branding/_new-brand.scss */
.w_start-checkout li:first-child {
  font-weight: bold;
}

@media only screen and (min-width: 60.625rem) {
  /* line 864, ../../scss/app/branding/_new-brand.scss */
  .checkout-back-cart {
    padding-left: 0;
    margin-left: 0;
  }
}

/*Order Confirmation page*/
/* line 871, ../../scss/app/branding/_new-brand.scss */
.order-print {
  margin: 0 0;
  width: 30%;
}

/*Checkout Page Styles*/
/* line 878, ../../scss/app/branding/_new-brand.scss */
.checkout-step_price .ico-placeholder {
  background-color:transparent !important;
  float: left;
  /*display: table-cell;
  vertical-align: middle;*/
  min-width: 5rem !important;
}
@media only screen and (min-width: 768px) and (orientation: landscape) {
  /* line 878, ../../scss/app/branding/_new-brand.scss */
  .checkout-step_price .ico-placeholder {
    float: none !important;
    display: table-cell;
    vertical-align: middle;
  }
}
/* line 892, ../../scss/app/branding/_new-brand.scss */
.checkout-step_price .policy-details {
  padding-bottom: 0 !important;
}
/* line 896, ../../scss/app/branding/_new-brand.scss */
.checkout-step_price .checkout-step_shipping-items {
  font-size: 1rem;
  padding: 0.5625rem 0.5625rem;
  float: none;
  display: table-cell;
  vertical-align: bottom;
}
@media only screen and (min-width: 60.625rem) {
  /* line 896, ../../scss/app/branding/_new-brand.scss */
  .checkout-step_price .checkout-step_shipping-items {
    width: 28%;
  }
}
@media only screen and (min-width: 1025px) {
  /* line 908, ../../scss/app/branding/_new-brand.scss */
  .checkout-step_price .cart-box_product-name-desktop {
    width: 64% !important;
  }
}
/* line 914, ../../scss/app/branding/_new-brand.scss */
.checkout-step_price .checkout-step_shipping-available-desktop, .checkout-step_price .checkout-step_price-policy {
  /*float: none !important;*/
  clear: both;
  /*display: table;*/
  width: 100% !important;
}
/* line 921, ../../scss/app/branding/_new-brand.scss */
.checkout-step_price .checkout-step_shipping-available {
  width: 100% !important;
}
/* line 925, ../../scss/app/branding/_new-brand.scss */
.checkout-step_price .policy-details {
  float: left;
}
@media only screen and (min-width: 768px) and (orientation: landscape) {
  /* line 925, ../../scss/app/branding/_new-brand.scss */
  .checkout-step_price .policy-details {
    float: none !important;
    display: table-cell;
    vertical-align: middle;
    padding-top: 0 !important;
  }
}

/* line 939, ../../scss/app/branding/_new-brand.scss */
.checkout-step_content input {
  border-radius: 0;
}

/* line 945, ../../scss/app/branding/_new-brand.scss */
.checkout-step_order-table .checkout-step_order-column-3 {
  width: 15%;
}

/* line 949, ../../scss/app/branding/_new-brand.scss */
.checkout-step_cc-info div:nth-child(2n) div:nth-child(2n) table tr:nth-child(2) td select {
  width: 70%;
}

/* line 953, ../../scss/app/branding/_new-brand.scss */
.checkout-back-cart a {
  font-weight: bold;
}

/* Page Loader Styles */
/* line 957, ../../scss/app/branding/_new-brand.scss */
.loader {
  display: none;
  /* &:after, &:before {
      display: none;
  }*/
}
/* line 962, ../../scss/app/branding/_new-brand.scss */
.loader.m_page-loader {
  position: fixed;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  text-align: center;
  margin: 0px auto;
  z-index: 900;
  margin-top: -1.1875rem;
}
/* line 977, ../../scss/app/branding/_new-brand.scss */
.loader.active {
  display: block;
}

/* line 982, ../../scss/app/branding/_new-brand.scss */
.icon-price-tag {
  width: 1.875rem;
  height: 1.875rem;
  text-align: center;
  color: #fff !important;
  background: #cd040b;
  vertical-align: middle;
}
/* line 990, ../../scss/app/branding/_new-brand.scss */
.icon-price-tag::before {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  display: inline-block !important;
  font-size: 1.25rem;
  line-height: 30px !important; /* fix for cart page promotion text alignment*/
}

/* line 1001, ../../scss/app/branding/_new-brand.scss */
.tabs_tab {
  font-size: 1rem;
}
/* line 1004, ../../scss/app/branding/_new-brand.scss */
.tabs_tab.active {
  color: inherit;
}

/* line 1011, ../../scss/app/branding/_new-brand.scss */
.icon-delivery.m_icon-delivery:before {
  font-size: 2.5rem !important;
  color: #4b4b4b;
}

/* line 1019, ../../scss/app/branding/_new-brand.scss */
.tpgrid_product-colors .product-color{
  width: 1.625rem;
  height: 1.625rem;
  border: 0.0625rem solid rgb(116, 118, 118);
  margin-right:.5rem;
}


.tpgrid_product-colors .product-color.active {
  position: relative;
  display: inline-block;
  text-align: center;
  line-height: 1rem;
  box-shadow: rgb(255, 255, 255) 0px 0px 0px 0.125rem inset;
  border: 0.0625rem solid rgb(0,0,0);
}
/* line 1037, ../../scss/app/branding/_new-brand.scss */
.tpgrid_product-colors .product-color.active:after {
  border: 0.0625rem solid rgb(0,0,0);
    position: absolute;
    content: '';
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 1rem;
    left: .125rem;
    top: .125rem;

}

/* line 1043, ../../scss/app/branding/_new-brand.scss */
.validate-coupon-icon {
  display: none;
}
/* line 1046, ../../scss/app/branding/_new-brand.scss */
.validate-coupon-icon.success {
  display: inline-block;
}
/* line 1048, ../../scss/app/branding/_new-brand.scss */
.validate-coupon-icon.success:after {
  content: '\e65e';
  font-family: "VZIcons";
  color: #0c7810;
  font-size: 0.75rem;
}
/* line 1056, ../../scss/app/branding/_new-brand.scss */
.validate-coupon-icon.fail {
  display: inline-block;
}
/* line 1058, ../../scss/app/branding/_new-brand.scss */
.validate-coupon-icon.fail:after {
  content: '\e65b';
  font-family: "VZIcons";
  color: #CD040B;
  font-size: 0.75rem;
}
@media only screen and (min-width:768px){
	   .w_toolbar.m_toolbar{ position: static;}
	   }
@media only screen and (max-width: 768px) {
  /* line 1, ../../scss/app/widgets/_carttable.scss */
  .view-cart_table {
    width: 100%;
    clear: both;
  }
}
@media (max-width: 768px) {
  /* line 34, ../../scss/app/components/_viewcart.scss */
  .view-cart_free-ship {
    width: 100%;
    clear: both;
    height: 4.375rem;
    float: none;
  }
}
@media (max-width: 768px) {
  /* line 18, ../../scss/app/components/_viewcart.scss */
  .view-cart_right {
    width: 100%;
    clear: both !important;
  }
}
/*fix for focus removal*/
*:focus{ 
 outline:none; 
 outline-offset:0px; 
 }
 
input[type=checkbox]+label:active::before,input[type=checkbox]:focus+label::before,input[type=radio]+label:active::before,input[type=radio]:focus+label::before
{
	outline:none;
	outline-offset:0;
}


.vzicon.gift_card_icon{ 
cursor:pointer;
}
.m_gift-card::before{
font-size: 18px;
  line-height: 15px;
  cursor:pointer;
}


/*Re-Skin CSS Start*/
.toolbar_items > li.active {
    border-bottom: 0.25rem solid #333;
}
h1, .h1, h3, .h3, h4, .h4, .sale_text, .plus:after, .minus:after, .checkout-step_cc-info td span, .filter-category li a:hover, .checkout-step-showing-items.minus, .checkout-step_discount-code.minus, .select-box_options li:before, .products-details_heading, .hero-banner_title, .toolbar_support .toolbar_shipping-item, .pagination_item.m_first > a::before, .pagination_item.m_last > a::before, .pagination_item.m_prev > a::before, .pagination_item.m_next > a::before, .pagination_item[disabled].m_prev > a::before, .pagination_item[disabled].m_next > a::before, .w_list-control .m_accordion-summary::before, .w_list-control .m_accordion-summary:hover::before, .button.cta-text::before, .button.link-text::before, a.button.cta-text::before, a.button.link-text::before, .select-box_selected:after {
    color: #000;
}
.pagination_item.m_next:hover > a::before, .checkout-step_discount-code.plus:after, .checkout-step_discount-code.minus:after, .checkout-step_form .checkout-step_shipping-day .checkout-step_shipping-day-free, .checkout-step_form .checkout-step_shipping-address .checkout-step_shipping-day-free, .checkout-step_form .checkout-step_card-choose .checkout-step_shipping-day-free, .pagination_item.m_prev:hover > a::before, .pagination_item.m_first:hover > a::before, .pagination_item.m_last:hover > a::before, .button.cta-text:hover:not(.no-animate)::before, .button.link-text:hover:not(.no-animate)::before, a.button.cta-text:hover:not(.no-animate)::before, a.button.link-text:hover:not(.no-animate)::before, .fancy-options_item.selected::before, .fancy-options_item.active::before {
    color: #000;
}
input[type=checkbox]:checked + label::before, input[type=radio]:checked + label::before, input[type=checkbox]:checked:hover + label::before, input[type=radio]:checked:hover + label::before {
    color: #000;
}
.m_toolbar-chat, .m_toolbar-call, .tpgrid_item .tpgrid_product-name a, .page-cart-title, .view-cart_subtotal-value, .text-brand-1 {
    color: #000 !important;
}
.icon-price-tag {
    color: #fff !important;
}
.button, a.button, input.button, input.button:focus:not([readonly]) {
    background-color: #333;
    color: #fff;
}
.button:hover, a.button:hover, input.button:hover{
    background-color: #444444;
    color: #fff;
}
.button.secondary, a.button.secondary, input.button.secondary, input.button.secondary:focus:not([readonly ]) {
    background-color: #767676;
    color: #fff;
}
.button.secondary:hover, a.button.secondary:hover, input.button.secondary:hover {
    background-color: #9f9f9f;
    color: #fff;
}
.button.cta-text, .button.link-text, a.button.cta-text, a.button.link-text {
    color: #000;
    background-color: transparent;
}
.tabs_tab.active {
    border-bottom-color: #cd040b;
    border-top-width: 0;
    border-left-width: 0;
    border-right-width: 0;
}



.tpgrid_new-item .vzicon, .tpgrid_item .vzicon, .tpgrid_top-rated .vzicon, .product_promo-section .vzicon, .icon-price-tag {
    background: #000;
}

.view-cart_product-text a{ color: #000;font-weight: normal; text-decoration: underline;}
.store-icon-nav li:hover span { color: #000;font-weight: bold; text-decoration: none;}

/*One moment loader*/
 @-webkit-keyframes spin {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
    @keyframes spin {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
    .spin-loader-icon{
        border-radius: 50%;
        width: 50px;
        height: 50px;
        border: 0.25rem solid black;
        border-top-color: transparent;
        -webkit-animation: spin 1s infinite linear;
        animation: spin 1s infinite linear;
        display: inline-block;
        vertical-align: middle;
    }
.m_full-page-loader {
    background: #fff;
    max-width: 450px;
    padding: 106px 10px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    margin: auto;
    height: 260px;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    display: none;
}
.m_full-page-loader .loader-text{ 
    margin-left: 10px; 
    display: inline-block; 
    width: 200px;
    text-align: left;
    vertical-align: middle;
    line-height: 23px;
}
.value.faux-select, select.value {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}
@media(max-width: 460px){
    .m_full-page-loader{
        margin: auto 10px;
    }
}
.breadcrumb ul{
  list-style: none;
}
.breadcrumb li{
  display: inline;
}
.categoryList:focus{
  display: inherit;
}
.store-icon-nav li .shelf-icon{ padding: 10px; }
.checkout-btn.button { background-position: 86% center; }

select, input.faux-select, .faux-select {
  background-image: url(../../img/accessories/media/images-accessories/select-down.svg) !important;
  background-size: 14px;
    background-position: 92% center !important;
}
/* select[data-fancy-open]{ */
    /* background-image: url(../../images/select-up.svg); */
/* } */


/*Re-Skin CSS End*/

.headClass{
     display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    color: black;
    padding-right: 14500px;

}
.error-msg{
  color:red!important;
}
input[type=radio]:checked + label::before{
  color:#333!important;
}

.checkout-step_form select#expyear{
  bottom: 3px!important;
}
.page-cart-title.begin-checkout {
  font-size: 4.4375rem;
  color: #3d4146;
}

.shadow {
  box-shadow: 0.1875rem 0.1875rem 0.75rem rgba(0,0,0,.25);
}
.border-all {
  border-width: 1px!important;
  border-style: solid!important;
  border-color: #959595;
}
.billingError{
  padding: 3.375rem .5625rem .5625rem 0!important;
}
.spinner-loader::after,.spin-loader {
  border-radius: 50%;
  width: 1.75rem;
  height: 1.75rem;
  background-image: url("data:image/svg+xml,%3Csvg stroke='%23000000' viewBox='0 0 18 18' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m9 16.9c-4.3 0-7.9-3.5-7.9-7.9s3.6-7.9 7.9-7.9v-1.1c-5 0-9 4-9 9s4 9 9 9 9-4 9-9h-1.1c0 4.3-3.6 7.9-7.9 7.9z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: cover;
  border-top-color: transparent;
  border-radius: 50%;
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  display:block;
}
.spin-loader{margin:0 auto;}
.spinner-loader::after{
content: '';
position: absolute;
display: block;
}

.nested-overlay,.page-overlay.absolute,.sr-only {
  position: absolute
}

.page-overlay.active {
  z-index: 700;
  opacity: 1;
  -moz-transition: opacity .3s 0s,z-index 0s 0s;
  -o-transition: opacity .3s 0s,z-index 0s 0s;
  -webkit-transition: opacity .3s,z-index 0s;
  -webkit-transition-delay: 0s,0s;
  transition: opacity .3s 0s,z-index 0s 0s
}

.page-overlay.active:before {
  content: '';
  position: absolute;
  z-index: 400;
  background: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7)
}

.page-overlay.active.invisible {
  z-index: 500;
  opacity: 0
}

.page-overlay.active.show-top-bar {
  z-index: 500
}

.nested-overlay {
  z-index: 1
}
.section-overlay {
  position: absolute;
  top: 0rem;
  left: 0rem;
  background-color:hsla(0,0%,100%,.7);
  width: 100%;
  height: 100%;
  z-index: 820;
}

.section-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  background-color:hsla(0,0%,100%,.7);
  width: 100%;
  height: 100%;
  z-index: 820;
}
[data-lq-modal="checkAvailability"] .section-overlay{
  position:fixed;
}

.section-loader {
  height: 100%;
  text-align: center;
  width: 100% !important;
  position: relative;
  z-index: 200;
}
  /* line 282, ../../scss/app/loopQualification.scss */
  .section-loader img {
      width: 2.1875rem;
  }
  .section-loader {
    height: 100%;
    text-align: center;
    width: 100% !important;
    position: relative;
    z-index: 200;
}

.row {
  width: auto;
  max-width: 90rem;
  margin: 0 auto;
}
.vert-middle {
  vertical-align: middle;
} 

[class*=flex-grid-]>.vert-middle {
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center
}

.margin-left-zero,.margin-pull-left-zero,.margin-vert-zero {
  margin-left: 0!important
}

.margin-pull-right-zero,.margin-right-zero,.margin-vert-zero {
  margin-right: 0!important
}

.main.fluid-grid {
  max-width: none
}

.fluid-grid>.row,.row.fluid-grid {
  max-width: none
}
.column,.columns {
  padding-left: .5625rem;
  padding-right: .5625rem;
  width: 100%;
  float: left
}
.padding-left-zero,.padding-vert-zero {
  padding-left: 0!important
}

.padding-right-zero,.padding-vert-zero {
  padding-right: 0!important
}

@media only screen and (max-width:767px) {
  .section-overlay-column {
      vertical-align: initial;
      top: 45%;
      margin-top: -72px;
      display: block;
      position: absolute;
  }
}
#dvMkPmtNewProfile > div > .margin-top-small {
  margin-bottom: 1.125rem;
}

.margin-horiz-small,.margin-top-small {
  margin-top: 1.125rem!important
}
.text-medium{font-size: .75rem !important;}

.loader-messgae{
  display: inline-block;
  vertical-align: middle;
  text-align: center;
}

.slick-prev:before,.slick-next:before{
  color: #000 !important;
  font-family: vzicons !important;
    font-size: 40px !important;
    content:''!important;
}
.slick-prev,.slick-next{
    background-size:cover;
    background-position:center;
    background-repeat:no-repeat;
    height:100%;
    cursor:pointer;
    width:27px !important;
    height:27px !important;
    z-index:101;
}
.slick-next{
  background-image:url(data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPScxLjEnIGlkPSdFYmVuZV8xJyB4bWxucz0naHR0cDovL3d3dy53My5vcmc%20vMjAwMC9zdmcnIHhtbG5zOnhsaW5rPSdodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rJyB4PS%20cwcHgnIHk9JzBweCcgdmlld0JveD0nMCAwIDE4IDE4JyBzdHlsZT0nZW5hYmxlLWJhY2tncm91b%20mQ6bmV3IDAgMCAxOCAxODsnIHhtbDpzcGFjZT0ncHJlc2VydmUnPjxwb2x5Z29uIHBvaW50cz0n%20OC42NzksMC4xNjUgNy44ODQsMC45NiAxNS4zNiw4LjQzOCAwLDguNDM4IDAsOS41NjIgMTUuMzY%20sOS41NjIgNy44ODQsMTcuMDQgOC42NzksMTcuODM1IDE3LjUxNSw5ICcvPjwvc3ZnPg%3D%3D) !important;
}
.slick-prev{
  background-image:url(data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPScxLjEnIGlkPSdFYmVuZV8xJyB4bWxucz0naHR0cDovL3d3dy53My5vcmc%20vMjAwMC9zdmcnIHhtbG5zOnhsaW5rPSdodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rJyB4PS%20cwcHgnIHk9JzBweCcgdmlld0JveD0nMCAwIDE4IDE4JyBzdHlsZT0nZW5hYmxlLWJhY2tncm91b%20mQ6bmV3IDAgMCAxOCAxODsnIHhtbDpzcGFjZT0ncHJlc2VydmUnPjxwb2x5Z29uIHBvaW50cz0n%20OS4zMjEsMTcuODM1IDEwLjExNiwxNy4wNCAyLjY0LDkuNTYyIDE4LDkuNTYyIDE4LDguNDM4IDI%20uNjQsOC40MzggMTAuMTE2LDAuOTYgOS4zMjEsMC4xNjUgMC40ODUsOSAnLz48L3N2Zz4%3D) !important;
}
.slick-dots li{height:1rem;}
.slick-dots li button:before {
  font-family: 'slick';
  font-size: 15px !important;
  width:1.5rem !important;
  height:.0625rem !important;
  content:'' !important;
  opacity:1 !important;
  background: #000 !important;
  bottom: 0 !important;
  top: auto !important;
} 
.slick-dots li button
{
  padding:0 !important;
  width:1.5rem !important;
  height:1rem !important;
}
@media only screen and (min-width:768px) and (orientation: landscape){
.slick-dots li button
{
  height:.0625rem !important;
  position: relative;
}
.banner .slick-dots{
  top: 495px !important;
}
.featured-accessories-inner .slick-slider .slick-dots{
  top: 508px !important;
}
.slick-dots li button:focus{
  outline: #333 dashed !important;
  outline-width: thin !important;  
}
.slick-dots li.slick-active button{
  height:.25rem !important;
}
.banner .slick-next:focus {
  outline: #333 dashed !important;
  outline-width: thin !important;
}
.banner .slick-prev:focus {
  outline: #333 dashed !important;
  outline-width: thin !important;
}
.slick-prev:focus {
  outline: #333 dashed !important;
  outline-width: thin !important;
}
.slick-next:focus {
  outline: #333 dashed !important;
  outline-width: thin !important;
}
}
.slick-dots li.slick-active button:before{
  height:.25rem !important;
}

.slick-list{
  height: 480px;
  margin: auto
}
.banner .slick-list{
  height: 480px;
  margin: auto;
  z-index:100;
}
.banner .slick-slide, .store-front-inner, .featured-accessories-inner .slick-slider, .featured-accessories-inner h3{
  padding:0 4rem;
}
.banner .slick-dots{
  bottom: -32px !important;

}
.featured-accessories-inner .slick-slider .slick-dots{left:0rem;}
.banner .slick-prev, .banner .slick-next{top:57%}
.banner .slick-slide img{
  margin:0 auto;
}
.banner .slick-prev {
  left: 0 !important;
}
.banner .slick-next {
  right: 0  !important;
}
.slick-prev {
  left: -35px !important;
}
.slick-next {
  right: -15px  !important;
}
.hide_div {
  display: none;
  
}
.show_div:hover .hide_div {
  display: block; 
}
.transparent:hover{
  background-color: transparent !important;
}
.transparent{
  height: 470px;
  background-color: #f6f6f6;
}
.productdetails .slick-list{
  height: 10%;
  margin: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
}
.productdetails .slick-prev {
  left: -35px !important;
}
.productdetails .slick-next {
  right: 10px  !important;
}

.productdetailsImgs .slick-list{
  height: 350px;
  width: 450px;
  margin:0 auto;
}
.productdetails .slick-slide {
  width: 50px !important;
  display: inline-block !important;
  float: none;
}
.productdetails .slick-arrow, .slick-prev{
  display: block !important;
}
.productdetails .slick-arrow, .slick-next{
  display: block !important;
}

.showCart{
  display: none;
}

.mainShowCart:hover .showCart{
  display: block;
}
.mainShowCart:focus-within .showCart{
  display: block;
}
.featured-accessories .slick-list{height:500px;}
.featured-accessories .slick-list .tpgrid_item{margin:0;border:1px solid #ccc;padding-bottom:20px;height:31rem !important;}
.featured-accessories .slick-list .tpgrid_detail-box{width: 100% !important;height: 100% !important;box-shadow: none;}
.featured-accessories .featured-accessories-items{width:100% !important}
.featured-accessories .slick-slider .slick-prev{left:0 !important}.featured-accessories .slick-slider .slick-next{right:0 !important}
.featured-accessories .slick-list .tpgrid_product{width:95% !important}
.featured-accessories .tpgrid_item .tpgrid_product-name{margin-bottom:.75rem !important}
.featured-accessories .tpgrid_detail-box .tpgrid_box{bottom:1rem;left:1.75rem;}
.featured-accessories .slick-list .tpgrid_item .tpgrid_placeholder{width:70% !important;}
.featured-accessories .slick-list .tpgrid_item .tpgrid_top-rated.discountTag div{margin-right:-5px !important;top:15px !important;}

.accessories-result .tpgrid_item,.w_others .tpgrid_item{margin:0 -1px -1px 0;border:1px solid #ccc;width:25.075% !important;height:33.75rem !important;z-index:599;}
.accessories-result .tpgrid_item .tpgrid_top-rated,.w_others .tpgrid_top-rated{
  /* padding-top:.25rem;
  top:-14px  !important; */
  left:0px  !important;
}
.accessories-result .tpgrid_item .tpgrid_product-name,.w_others .tpgrid_product-name{padding-right:1.25rem;}
.accessories-result .tpgrid_box .button,.w_others .tpgrid_box .button{margin-left:.75rem;}
.accessories-result .tpgrid_detail-box,.w_others .tpgrid_detail-box{box-shadow:none;width:100%;height:100%;top:0px;}
.accessories-result .tpgrid_item .tpgrid_product,.w_others .tpgrid_item .tpgrid_product{padding:1.25rem 0 0 1.5rem;}
.w_products-description .main{border:none !important;}
.w_others .tpgrid_item{height:32rem !important;}
.products-overview_lists ul{list-style-type: disc;}
.products-details_color ul li{border-radius:100%;}
.products-details_color ul li.active:after{font-weight:normal;font-size:1rem;bottom:28%;}
.accessories-result .w_promo-section{background:#000;}
.accessories-result .w_promo-section h5, .accessories-result .w_promo-section p, .accessories-result .w_promo-section a.button.cta-text, .accessories-result .w_promo-section a.button.cta-text::before{color:#fff ;}.accessories-result .w_promo-section p{margin:0;}
.w_filter-mobile .right a{color:#000;font-weight:normal !important;text-decoration: underline;}
.w_promo-section main .bg-grey-5{background:#000 !important;}.w_promo-section main .bg-grey-5 p{color:#fff ;}.w_promo-section main .bg-grey-5 p.text-small>*{font-size:.875rem !important;}.w_promo-section main .bg-grey-5 p ul{margin-bottom:.5rem}.w_promo-section main .bg-grey-5 p ul li{padding-bottom:.5rem;}


.w_others .tpgrid_detail-box .button,.accessories-result .tpgrid_detail-box .button,.featured-accessories .button, .w_products-details .products-details_cart .button, .modal_footer button, .w_products-details .products-details_cart .button, .checkout-btn.button,.w_checkout-steps .button, .w_checkout-steps a.button{
  width: auto !important;
  padding: .75rem 2.5rem !important;
  background-color: #000 !important;
  text-align: center !important;
  border-radius:6.25rem;
}
.button.secondary, a.button.secondary, .w_products-details .products-details_cart a.button.secondary,.w_products-details .products-details_cart .button.secondary{
  width: auto !important;
  padding: .75rem 2.5rem !important;
  background-color: #fff !important;
  border:1px solid #000 !important;
  color:#000 !important;
  text-align: center !important;
  border-radius:6.25rem;}

.w_products-details .products-details_cart .button{padding: .75rem 4rem !important;}
.w_cart-box .cart-box_placeholder .button{padding: .75rem 1.875rem !important;background-color:#000;border-radius:6.25rem;}
.w_cart-box .cart-box_placeholder .button[lid~="checkout"]{padding: .75rem 2.5rem!important;}
.cart-box_sale .icon-price-tag{background:transparent !important;color:#000 !important;}
.cart-box_sale .sale_text{padding-left:0 !important;}
.styles_overlay__CLSq-{z-index:10000 !important;}
.styles_closeButton__20ID4{cursor: pointer;}

.w_others .tpgrid_detail-box .button::before,.accessories-result .tpgrid_detail-box .button::before,.featured-accessories .button::before,.w_products-details .products-details_cart .button::before,.button.secondary::before, a.button.secondary::before,.modal_footer button::before,.w_cart-box .cart-box_placeholder .button::before, .checkout-btn.button::before{
  display: none !important;
  transition: none !important;
}

.tpgrid_item .tpgrid_top-rated.discountTag div{left: 9px;background: #000; color: #fff;position: relative;top:25px !important;margin-right:1.125rem;line-height:2.25rem;padding-left: 1rem;}
.slick-prev, .slick-next{top:57%;}
.products-list_carousel .carousel_item .row .columns.text-center div{margin:0 auto !important;}
.w_toolbar .add-filter{margin-right:2rem;margin-top:-.5rem;float: right;font-size:.875rem;}
.added-filter{margin-left:11.5rem;margin-top:-.5rem;float:left;font-size:.875rem !important;}
@media only screen and (min-width: 1024px){
.accessories-result .w_promo-section h5{font-size:2rem;padding-top:1rem;}
}

@media only screen and (max-width: 1024px){
  .w_toolbar.m_toolbar {padding: 0 2rem;}
  .featured-accessories .slick-list .tpgrid_item{height:28rem !important}
  .featured-accessories .slick-list{height:450px;}
  .main{border:none !important;}
  .w_toolbar .toolbar_cart{margin-left:1rem !important;}
  .toolbar_support .toolbar_shipping-item{padding-right:1rem !important;}
  }

@media only screen and (min-width: 639px) and (max-width: 767px){
  .added-filter {
    left:16% !important;
  }
}
@media only screen and (min-width: 768px) and (max-width: 768px){
  .added-filter{margin-left:-3px !important; left: 10%;}
}
@media only screen and (min-width: 769px) and (max-width: 1018px){
  .added-filter {
   margin-left:-2rem !important;
  }
}
@media only screen and (min-width: 1019px) and (max-width: 1209px){
  .added-filter {
    margin-left: 5.5rem !important;
  }
}
@media only screen and (min-width: 1024px) and (max-width: 1024px){
  .added-filter{margin-left:0.5rem !important;left:5rem;}
}
@media only screen and (min-width: 1366px) and (max-width: 1366px){
  .added-filter{margin-left:none !important;}
}

@media only screen and (max-width: 768px){
  .w_toolbar.m_toolbar { padding: 0 1rem; }
  .featured-accessories .slick-list .tpgrid_item{height:25rem !important;}
  .featured-accessories .slick-list{height:400px}
  .featured-accessories .button{padding:.75rem 2rem !important}  
  .banner .slick-slide, .store-front-inner, .featured-accessories-inner .slick-slider, .featured-accessories-inner h3 {padding:0 2rem; }
  .featured-accessories .slick-list .tpgrid_product{padding: 1.25rem 0 0 0.5rem;}
  .featured-accessories .tpgrid_detail-box .tpgrid_box{left:1rem;}
  .featured-accessories .slick-list .tpgrid_product .discountTag{left:-9px !important;}
  .accessories-result .tpgrid_item{width:33.4375% !important;}
}

@media (max-width: 638px) {
    
  .banner .slick-slide, .store-front-inner, .store-front-inner h3 {padding:0 1rem;}
  .featured-accessories-inner .slick-slider,.featured-accessories-inner .slick-slider, .featured-accessories-inner h3{padding:0 2rem;}
  .banner .slick-slide h1{font-size:2rem;}
  .banner .slick-list{height: 500px;}
  .w_toolbar.m_toolbar{height:3rem}
  .toolbar_filter {margin-top: 0.5rem;}
  .w_toolbar.m_toolbar {padding: 0;}
  .toolbar_items > li{height:40px !important;}
  .toolbar_items .toolbar_home-icon{padding-top:0;}
  .toolbar_items .toolbar_link .w_select-box .select-box_selected:after{top:5px;}  
  .featured-accessories .slick-list .tpgrid_product{padding: 1.25rem 0 0 0.9375rem; margin-top: 0rem; left: 0%;}  
  .featured-accessories .slick-list{height:480px}
  .featured-accessories .slick-list .tpgrid_item{height:30rem !important;}
  .featured-accessories .slick-list .tpgrid_product .discountTag{left:-15px !important;}
  .featured-accessories .slick-list .tpgrid_item .tpgrid_placeholder img{height:auto !important;}
  .featured-accessories .slick-list .tpgrid_item .tpgrid_placeholder{margin-top:0 !important;width:55%;height:12rem;}
  .featured-accessories .slick-list .tpgrid_item .tpgrid_top-rated.discountTag div{top:20px !important;}
  .accessories-result .tpgrid_item,.w_others .tpgrid_item{margin:0 0 -1px 0;border-left:0;border-right:0;width:100% !important;height:auto !important;}
  .accessories-result .tpgrid_item .tpgrid_top-rated.discountTag,.w_others .tpgrid_item .tpgrid_top-rated.discountTag{
    /* top:-14px; */
    left:0px  !important;
  }
  .accessories-result .tpgrid_item .tpgrid_product-name,.w_others .tpgrid_item .tpgrid_product-name{padding:1rem 0 0 0;}
  .accessories-result .tpgrid_item .tpgrid_placeholder,.w_others .tpgrid_item .tpgrid_placeholder{margin-top:.75rem !important;}
  .accessories-result .tpgrid_detail-box,.w_others .tpgrid_detail-box{display:none !important;}
  .products-list_carousel .w_carousel .slick-slider .slick-list{width:100% !important}
  .w_products-description .m_tabholder .w_tabs{overflow-x: scroll;min-height:2.25rem;;}
  .w_products-description .m_tabholder{padding-top:1rem;margin-top:2.5rem;border-top:1px solid #e4e5e3;}
  .w_products-description .m_tabholder .w_tabs .tabs_tab{display:inline;}
  .w_others.others-product-title{font-size:1.5rem;}
  .w_others .w_tpgrid{border-left:1px solid #ccc;border-right:1px solid #ccc;}
  .others-product-title{font-size:1.5rem;}  
  .products-list_breadcrumb{ margin: 1rem 0 0 1rem; }
  .view-cart_noitem-CTA{padding-left:1rem;}
  .checkout-step_block, .w_checkout-steps{border-left:none !important;border-right:none !important;border-color:#fff !important;}
  .checkout-step_price .checkout-step_shipping-items{display:inline-block;height:3rem;border:none;}
  .checkout-step_return-shipping,.checkout-step_shipping-order.p-50,.checkout-step_return.p-50{width:100% !important;}
  .page-cart-title.begin-checkout{line-height: 1;}
}
@media (max-width: 480px) {  
  .banner .slick-slide .carousel_item .tiny-10{float:left !important;}
  .banner .slick-slide img{padding-left:2rem;}
  .banner .slick-dots{bottom:-25px !important;}
  .banner .slick-slide .textlarge+br{line-height:1px !important;}
}

@media (min-width: 480px) {    
  .portal {position: absolute;top: 40px;display: inline-block;}
}

@media (min-width: 450px) {    
    .featured-accessories .slick-list .tpgrid_item .tpgrid_placeholder{margin-top:0 !important;}
}

.discountTag{

  left: -15px !important;
  top: -21px;
}
.slideDeActive{
  border: 0.0625rem solid #bbb;
}

.slideActive {
  border: 0.0625rem solid #000;
}
.view-review-cart{
  padding: 1px !important;
  height: 0 !important;
  visibility: hidden;
  opacity: 0;
  transition: visibility 1s, opacity 0.5s ,height 1s linear;
}
.view-review-cart.active{
  height: 11.5625rem !important;
  visibility: visible;
  opacity: 1;
}

a.vzicon.close-faq{
  margin-left: 97% !important;
  margin-top:-2% !important;
  font-size:xx-large;
}

button.select-box_selected:focus,li.toolbar_home-icon.active:focus,button.select-box_selected.m_sort-prefix:focus {
  outline-style: dashed !important;
  outline-width: thin !important;
}
li #accessoriesList:focus, #sortButton > li:focus{
  outline-offset: -1px;
}
/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
  *:focus{ 
    outline: none !important; 
    outline-offset: 0px !important; 
  }
  button.select-box_selected:focus,li.toolbar_home-icon.active:focus,button.select-box_selected.m_sort-prefix:focus {
    outline-style: none !important;
    outline-width: 0px !important;
  }
}
.w_toolbar .toolbar_count-placeholder{top:0;}
.w_toolbar .toolbar_cart span{line-height:2;}
.select-box_selected{position: relative;overflow: hidden;}
.toolbar_filter .w_select-box{width:12rem;}
.toolbar_filter .w_select-box .select-box_selected::after{top:15px;}
.pagination_item.m_next,.pagination_item.m_prev{top:0;}
.product_promo-section .vzicon{background:#fff !important;}.product_promo-section .vzicon:before{color:#000;}
.products-list_carousel .carousel_pager-next,.products-list_carousel .carousel_pager-prev{background-color:transparent !important;}
.w_products-description{padding:0 1.5rem;}
.w_products-description .video-container iframe{max-width: 560px !important;width:100% !important;}
.pagination_item.m_prev>a,.pagination_item.m_next>a{width:1rem;height:1rem;min-width:auto;}
.products-list_carousel .carousel_pager-next,.products-list_carousel .carousel_pager-prev{width:2rem;height:2rem;margin-top:-9px;}
.w_promo-section main{border:none !important;}
.tpgrid_detail-box .button.outofstock{display:none !important;}
#FAQModalContent .w_list-control .m_accordion-summary.m_single{padding-bottom:.75rem;padding-top:.75rem;}
#FAQModalContent .w_list-control .m_accordion-summary.m_single::before{top:1.1875rem;}
#FAQModalContent .w_list-control .m_accordion-summary.open+.m_accordion-details{padding-bottom:1.5rem;}
.w_filter .filter-wrapper{width: 12rem;float: right; margin-right: 1.5rem;border-bottom:1px solid #000;}
.w_filter .filter-wrapper .m_accordion-details ul{max-height: 15rem; overflow-y: auto;}
/* .filter-wrapper{width: 12rem;float: left; margin-right: 1.5rem;border-bottom:1px solid #000;max-height: 15rem; overflow-y: auto;} */
.filter-wrapper {float:left !important;}
#filter-category {float: right !important; border-bottom: none !important;}
.w_filter #filter-category{padding-bottom:2rem;margin-top:0;}
.products-description_container{padding:1rem 2rem 0;clear:both;}
.w_checkout-steps > *{word-wrap: break-word;}
[data-modal="FAQmodal"] .w_list-control > li.m_single p{line-height:1.5;}
[data-modal="FAQmodal"] .w_list-control .m_accordion-summary.m_single {padding-bottom: 1rem; padding-top: 1rem;}
[data-modal="FAQmodal"] .w_list-control .m_accordion-summary.open+.m_accordion-details{padding-bottom:1.5rem;padding-top:0rem;}
[data-modal="FAQmodal"] .modal_content{max-height:65vh;}
[data-modal="FAQmodal"] .w_list-control .m_accordion-summary::before{right:0;}

.products-list_carousel .carousel_pager-next::before,.products-list_carousel .carousel_pager-prev::before,.toolbar_cart .icon-cart::before,.vzicon.icon-question-answer:before,.select-box_selected:after,.m_accordion-summary.plus::after, .m_accordion-summary.minus::after,.pagination_item.m_prev>a::before,.pagination_item.m_next>a::before,.icon-delivery.m_icon-delivery:before,.cart-box_icon .vzicon:before,.cart-box_button .icon-close::before,.modal_content .w_list-control .m_accordion-summary::before,.modal_content .w_list-control .m_accordion-summary.open:before,.tpgrid_item .vzicon.icon-price-tag::before, .tpgrid_top-rated .vzicon.icon-price-tag::before,.product_promo-section .vzicon::before, .button::before{content:'' !important;}

.button.cta-text::before, .button.link-text::before, a.button.cta-text::before, a.button.link-text::before{content: "\E631" !important;}

.vzicon-home{background: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2018%2015.87585%22%3E%3Ctitle%3Eicon%3C%2Ftitle%3E%3Cpath%20d%3D%22M18%2C9.00031%2C9%2C0%2C0%2C9.00031H1.57642l.79809-.798v7.67352H15.626V8.2027l.7976.79761ZM14.51%2C14.76H3.49023V7.08655L9%2C1.577l5.51%2C5.50982Z%22%2F%3E%3C%2Fsvg%3E') no-repeat left top;height:1.5rem;width:1.5rem;display:block;}

.toolbar_cart .icon-cart{background: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2018%2015.87585%22%3E%3Ctitle%3Eicon%3C%2Ftitle%3E%3Cpath%20d%3D%22M11.354%2013.875H.838v-8.99h1.845v1.45h.839v-1.45h5.15v1.45h.837v-1.45h1.845v8.99zM3.522%203.285c0-.794.641-1.439%201.433-1.447h2.283c.791.008%201.433.653%201.433%201.446v.763h-5.15v-.763zm7.14.762H9.51v-.763A2.285%202.285%200%200%200%207.24%201H4.954a2.285%202.285%200%200%200-2.272%202.284v.763H0v10.667h12.192V4.047h-1.53z%22%2F%3E%3C%2Fsvg%3E') no-repeat left top;height:1.5rem;width:1.5rem;display:inline-block;background-size: cover;margin-left:5px;}

.vzicon.icon-question-answer{background: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2018.3%2018.4%22%3E%3Ctitle%3Eicon%3C%2Ftitle%3E%3Cg%3E%3Cpath%20d%3D%22M9.1%2C0.2c-5%2C0-9%2C4-9%2C9s4%2C9%2C9%2C9s9-4%2C9-9S14.1%2C0.2%2C9.1%2C0.2z%20M9.1%2C17.1c-4.3%2C0-7.9-3.5-7.9-7.9s3.5-7.9%2C7.9-7.9S17%2C4.8%2C17%2C9.2S13.5%2C17.1%2C9.1%2C17.1z%22%2F%3E%3Crect%20x%3D%228.3%22%20y%3D%2212.1%22%20width%3D%221.6%22%20height%3D%221.5%22%2F%3E%3Cpolygon%20points%3D%226.3%2C7.7%206.3%2C7.7%206.3%2C7.7%22%2F%3E%3Cpath%20d%3D%22M9.2%2C4.8C8.4%2C4.8%2C7.7%2C5%2C7.1%2C5.6C6.6%2C6.1%2C6.3%2C6.9%2C6.3%2C7.7h1.4c0-1%2C0.4-1.8%2C1.5-1.8c0.6%2C0%2C1.2%2C0.5%2C1.2%2C1.3c0%2C0.5-0.1%2C0.8-0.6%2C1.2C9.3%2C8.9%2C8.9%2C9.2%2C8.7%2C9.7c-0.2%2C0.5-0.2%2C1-0.2%2C1.5h1.3c0-0.3%2C0-0.5%2C0.1-0.7c0.1-0.3%2C0.2-0.6%2C0.6-0.9c0.3-0.3%2C0.4-0.4%2C0.8-0.7c0.5-0.4%2C0.7-1%2C0.7-1.7c0-0.8-0.3-1.4-0.8-1.8C10.6%2C5%2C9.9%2C4.8%2C9.2%2C4.8z%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E') no-repeat left top;height:1.5rem;width:1.5rem;display:inline-block;}

.m_accordion-summary.plus,.modal_content .w_list-control .m_accordion-summary::before{background: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%209%209%22%3E%3Ctitle%3Eicon%3C%2Ftitle%3E%3Cg%3E%3Cpolygon%20points%3D%229%2C3.9%205.1%2C3.9%205.1%2C0%203.9%2C0%203.9%2C3.9%200%2C3.9%200%2C5.1%203.9%2C5.1%203.9%2C9%205.1%2C9%205.1%2C5.1%209%2C5.1%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E') no-repeat right top;background-position-y:50%;background-size: 1rem 1rem;}

.m_accordion-summary.minus,.modal_content .w_list-control .m_accordion-summary.open:before{background: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%209%209%22%3E%3Ctitle%3Eicon%3C%2Ftitle%3E%3Cg%3E%3Crect%20y%3D%223.9%22%20width%3D%229%22%20height%3D%221.1%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E') no-repeat right top;background-position-y:50%;background-size: 1rem 1rem;}

.tpgrid_item .vzicon.icon-price-tag:before, .tpgrid_top-rated .vzicon.icon-price-tag:before{background: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2018%2018%22%3E%3Ctitle%3Eicon%3C%2Ftitle%3E%3Cg%3E%3Cpath%20fill%3D%22%2523FFFFFF%22%20d%3D%22M10.5%2C0L0%2C10.5L7.5%2C18L18%2C7.5V0H10.5z%20M16.9%2C7l-9.4%2C9.4l-5.9-5.9L11%2C1.1h5.9V7z%22%20%2F%3E%3Ccircle%20fill%3D%22%2523FFFFFF%22%20cx%3D%2213.5%22%20cy%3D%224.5%22%20r%3D%221.1%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E') no-repeat right top;background-size:cover;width:1.25rem;height:1.25rem;top:20%;transform: none;}

.product_promo-section .vzicon::before{background: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2018%2018%22%3E%3Ctitle%3Eicon%3C%2Ftitle%3E%3Cg%3E%3Cpath%20%20d%3D%22M10.5%2C0L0%2C10.5L7.5%2C18L18%2C7.5V0H10.5z%20M16.9%2C7l-9.4%2C9.4l-5.9-5.9L11%2C1.1h5.9V7z%22%20%2F%3E%3Ccircle%20cx%3D%2213.5%22%20cy%3D%224.5%22%20r%3D%221.1%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E') no-repeat right top;background-size:cover;width:1.25rem;height:1.25rem;top:20%;transform: none;}

.select-box_selected::after{background: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2010%2012%22%3E%3Ctitle%3Eicon%3C%2Ftitle%3E%3Cg%3E%3Cpolygon%20points%3D%223.2%2C11.4%202.5%2C10.7%207.1%2C6%202.5%2C1.4%203.2%2C0.6%208.5%2C6%20%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E') no-repeat right top;z-index:auto;position:absolute;background-size: cover;height:1.1rem;width:.5rem;right:0;top:5px;-moz-transform: rotate(90deg);-webkit-transform: rotate(90deg);-o-transform: rotate(90deg);-ms-transform: rotate(90deg);transform: rotate(90deg);}

.pagination_item.m_prev>a,.products-list_carousel .carousel_pager-prev{background: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2018%2018%22%3E%3Ctitle%3Eicon%3C%2Ftitle%3E%3Cg%3E%3Cpolygon%20points%3D%229.1%2C18%209.9%2C17.2%202.2%2C9.5%2018%2C9.5%2018%2C8.4%202.2%2C8.4%209.9%2C0.8%209.1%2C0%200%2C9%20%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E') no-repeat left top;background-size: cover;}

.pagination_item.m_next>a,.products-list_carousel .carousel_pager-next{background: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2018%2018%22%3E%3Ctitle%3Eicon%3C%2Ftitle%3E%3Cg%3E%3Cpolygon%20points%3D%228.679%2C0.165%207.884%2C0.96%2015.36%2C8.438%200%2C8.438%200%2C9.562%2015.36%2C9.562%207.884%2C17.04%208.679%2C17.835%2017.515%2C9%20%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E') no-repeat left top;background-size: cover;}

.icon-delivery.m_icon-delivery, .cart-box_icon {background: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2018%2018%22%3E%3Ctitle%3Eicon%3C%2Ftitle%3E%3Cg%3E%3Cpath%20d%3D%22M12.375%2C2.25H0V13.5h1.125c0%2C1.243%2C1.007%2C2.25%2C2.25%2C2.25s2.25-1.007%2C2.25-2.25h6.75c0%2C1.243%2C1.007%2C2.25%2C2.25%2C2.25s2.25-1.007%2C2.25-2.25H18V9L12.375%2C2.25z%20M3.375%2C14.625c-0.62%2C0-1.125-0.505-1.125-1.125c0-0.62%2C0.505-1.125%2C1.125-1.125S4.5%2C12.88%2C4.5%2C13.5C4.5%2C14.12%2C3.995%2C14.625%2C3.375%2C14.625z%20M14.625%2C14.625c-0.62%2C0-1.125-0.505-1.125-1.125c0-0.62%2C0.505-1.125%2C1.125-1.125S15.75%2C12.88%2C15.75%2C13.5C15.75%2C14.12%2C15.245%2C14.625%2C14.625%2C14.625z%20M16.875%2C12.375h-0.312c-0.39-0.67-1.107-1.125-1.938-1.125s-1.548%2C0.455-1.938%2C1.125H5.313c-0.39-0.67-1.107-1.125-1.938-1.125s-1.548%2C0.455-1.938%2C1.125H1.125v-9h10.723l5.027%2C6.032V12.375z%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E') no-repeat center center;background-size: 2.5rem 2.5rem;}

.cart-box_icon {background-color: #D8DADA;}

.view-cart_return-icon {background: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2018%2018.2%22%3E%3Ctitle%3Eicon%3C%2Ftitle%3E%3Cg%3E%3Cpath%20d%3D%22M12.9%2C4.7V3.1c0-1.6-1.3-2.9-2.9-2.9H8c-1.6%2C0-2.9%2C1.3-2.9%2C2.9v1.6H2.2v13.5h13.5V4.7H12.9z%20M6.1%2C3.1c0-1%2C0.8-1.9%2C1.9-1.9H10c1%2C0%2C1.9%2C0.8%2C1.9%2C1.9v1.6H6.1V3.1z%20M14.6%2C17.1H3.4V5.8h1.7v1.3h1V5.8h5.7v1.3h1V5.8h1.7V17.1z%22%2F%3E%3Cpath%20d%3D%22M10.2%2C10.1H7.1l1.3-1.3L7.6%2C8.1l-2.6%2C2.6l2.6%2C2.6l0.8-0.8l-1.3-1.3h3.1c0.9%2C0%2C1.7%2C0.8%2C1.7%2C1.7c0%2C0.9-0.8%2C1.7-1.7%2C1.7H8.7v1.1h1.5c1.6%2C0%2C2.8-1.3%2C2.8-2.8S11.8%2C10.1%2C10.2%2C10.1z%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E') no-repeat center center;background-size: 2.5rem 2.5rem;}

.cart-box_button .icon-close{background: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2018.4%2018.3%22%3E%3Ctitle%3Eicon%3C%2Ftitle%3E%3Cg%3E%3Cpath%20d%3D%22M9.2%2C0.1c-5%2C0-9%2C4-9%2C9s4%2C9%2C9%2C9s9-4%2C9-9S14.2%2C0.1%2C9.2%2C0.1z%20M9.2%2C17c-4.3%2C0-7.9-3.5-7.9-7.9s3.5-7.9%2C7.9-7.9s7.9%2C3.5%2C7.9%2C7.9%20S13.6%2C17%2C9.2%2C17z%22%2F%3E%3Cpolygon%20points%3D%2212%2C5.5%209.2%2C8.3%206.4%2C5.5%205.6%2C6.3%208.4%2C9.1%205.6%2C11.9%206.4%2C12.7%209.2%2C9.9%2012%2C12.7%2012.8%2C11.9%2010%2C9.1%2012.8%2C6.3%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E') no-repeat right center;background-size:cover;width: 1.5rem;height:1.5rem;}

/* for vcc - accressories - vmvo 6955 tickets*/
#redeem_popup > div:first-child{
  width: 615px;
  border-radius: 5px ;
}
.redeem_popup_modal input{
  border: .0925rem solid #000000 ;
  background-color: #fff ;
  margin-bottom: 4px;
  font-weight: 500;
  padding-left: 32px;
}
.redeem_popup_modal button{
  border-radius: 25px !important;
  background-color: #000 !important;
}
.redeem_popup_title{
  margin: 0;
  line-height: normal;
  font-size: 32px ;
}
.redeem_popup_modal_input_error{
  border: .125rem solid #bc7a29 !important;
  background-color: #FFECE0 !important ;
}
.redeem_input_div{
  position: relative;
}
.redeem_error_icon{
  position: absolute ;
  width: 25px !important;
  height: 25px;
  right: 15px;
  top: 16px;
 }
 .redeem_dollar_symbol{
  position: absolute;
  top: 16px;
  left: 20px;
 }
@media (max-width: 767px) {
  
#redeem_popup > div:first-child{
  border-radius: 0 !important;
}
  .redeem_popup_modal.tiny-padding-all-large{
    padding: 0px !important;
  }
  .redeem_popup_modal .butttonDiv{
    float: none !important;
    margin-bottom: 0 !important;
  }
  .redeem_popup_modal .button{
    text-align: center !important;
  }
  #redeem_popup{
    padding: 0px !important;
  }
  .redeem_popup_modal{
    position: relative !important;
    height: 96vh !important;
  }
  .redeem_popup_modal > div:last-child{
    position: absolute !important;
    bottom: 0 !important;
  }

} 

.redeemDollars {
  font-size: 24px;
  font-family: Brandfont, Arial, sans-serif;
  font-weight: bold;
}
.VZEditLink {
  color: #000;
  text-decoration: underline;
  font-weight: normal;
  margin-left: 6px;
}
div.ReedeemSec .visacardRedeemSec button.changeBtn {
  width: 110px !important;
}
div.ReedeemSec .visacardRedeemSec button.redeemBtn {
  width: 147px !important;
}
div.ReedeemSec .visacardRedeemSec button.changeBtn, div.ReedeemSec .visacardRedeemSec button.redeemBtn {
  min-height: 44px;
  height: 44px;
  line-height: normal;
  padding: 10px !important;
}
div.visacardRedeemSec {
  width: 77%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 767px){
  div.ReedeemSec {
    margin-left: 0 !important;
    padding-left: 15px !important;
  }
  div.ReedeemSec button.secondary {
  margin-top: 20px;
  padding: 10px 25px !important;
    letter-spacing: 1px;
    line-height: normal;
  }
  p.redeemTitle {
    margin-bottom: 5px !important;
  }
  div.visacardRedeemSec {
    width: 100%;
    flex-wrap: wrap;
  }
}
div.ReedeemSec .visacardRedeemSec button.disabledRedeemBtn {
  color: rgba(51, 51, 51, .5) !important;
  cursor: not-allowed;
  pointer-events: none;
  border: 1px solid rgba(51, 51, 51, .5) !important;
}
.tile-outer-box {
  height: 86px;
  width: 296px;
  display: flex;
  justify-content: center;
  background: #f6f6f6;
  border-radius: 6px;
  padding: 10px;
  box-sizing: border-box;
  align-items: center;
  margin-bottom: 10px;
}
@media screen and (max-width: 768px) {
  .tile-outer-box {
    width: 328px;
    height: 86px;
  }
}
.tile-inner-box {
  width: 278px;
  height: 55px;
  display: flex;
  justify-content: space-around;
  gap: 10px;
}
@media screen and (max-width: 768px) {
  .tile-inner-box {
    width: 263px;
    height: 55px;
  }
}
.tile-content-box {
  width: 221px;
  height: 54px;
  font-weight: 400px;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.5px;
}
.tile-image {
  width: 36px;
  height: auto;
}
@media screen and (max-width: 768px) {
  .tile-content-box {
    width: 221px;
    height: 54px;
  }
  .apply-visacard-popup .button{
    text-align: center !important;
  }
  .apply-visacard-popup .butttonDiv{
    float: none !important;
    margin-bottom: 0 !important;
  }
  .apply-visacard-popup .btn-continue{
    float: left !important;
    margin-bottom: 0 !important;
  }
  .apply-visacard-popup .vvc-para{
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.5px;
    text-align: left;
    } 
    .second-popup {
    width:  auto;
    height: 96vh;
    border-radius: 8px;
    overflow-y: hidden;
    }
    .apply-vvc-content-alignment{
      display: flex;
      justify-content: center;
    }
    .apply-vvc-content-font{
      font-family: 'Verizon-NHG-eDS' !important;
    }
}
.vvc-popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}
.vvc-popup-content {
  height: auto;
  width: 105px;
  display: flex;
  align-items: center;
}
@media screen and (min-width: 768px) {
  .scroller {
    overflow-y: scroll;
    position: relative;
    margin-top:5vh;
    margin-left: 1.5vh;
  }
  .scroller-secondPopup {
    overflow-y: scroll;
    position: relative;
    margin-top:5vh;
    margin-left: 1.5vh;
  }
  

  .apply-visacard-popup{
    width: 560px; 
    height: 80vh;
  }
  .second-popup {
    width: 560px;
    height:336px;
  }
} 
@media screen and (max-width: 768px) {
  .scroller {
    overflow-y: hidden; 
  }
}
.scroller::-webkit-scrollbar {
  width: 4px;
  height: 708px;
}
.scroller::-webkit-scrollbar-track {
  background: #d8dada;
  height: 668px;
}
.scroller::-webkit-scrollbar-thumb {
  background: #6f7171;
  height: 708px;
}
.apply-visacard-popup button{
  border-radius: 25px !important;
  background-color: #000 !important;
}
.scroller-secondPopup::-webkit-scrollbar {
  width: 4px;
  height: 240px;
}
.scroller-secondPopup::-webkit-scrollbar-track {
  background: #d8dada;
  height: 240px;
}
.scroller-secondPopup::-webkit-scrollbar-thumb {
  background: #6f7171;
  height: 200px;
}

.accessories-anchor-disable {
  color: rgba(51, 51, 51, .5) !important;
  cursor: not-allowed;
  pointer-events: none;
}

/* roundup charity */
.roundup_wrap{
  font-weight: bold;
  padding: 0 1.25rem 1.25rem;
  border-bottom: 1px solid #cccccc !important;
}

/* #roundup_Modal_div{
  max-height: 80vh;
  overflow-y: scroll;
} 
#roundup_Modal_div::-webkit-scrollbar {
  width: 4px;
}
#roundup_Modal_div::-webkit-scrollbar-track {
  background: #d8dada;
}
#roundup_Modal_div::-webkit-scrollbar-thumb {
  background: #6f7171;
} */

.roundup_summary_extras{
  height: 3.1875rem;
}
.roundup_switch_div{
  display: flex;
  position: relative;
  align-items: center;
}
.roundup_switch_content_div{
  width: 70%;
}
.roundup_add_donation_btn{
  width: auto !important;
  border-radius: 24px;
  text-align: center !important;
  background-color: #ffffff;
  cursor: pointer;
  padding: .55rem 1.6rem .55rem 1.6rem !important;
}
.roundup_switch{
  position: absolute;
  right: 0;
}
.roundup_edit_btn{ 
  cursor: pointer;
  text-decoration: underline !important;
}
.roundup_switch_content{
  color: #6f7171 !important;
}
.roundup_tile_div{
  display: flex;
  align-items: center;
  gap: 15px;
  position: relative;
  margin-bottom: 12px;
  width: 100%;
  border: 1px solid #6f7171 ;
  border-radius: 4px ;
  cursor: pointer;
}
.roundup_tile_div:hover {
  border: 2px solid #6f7171 !important;
}
.roundup_tile_div.active{
  border: 2px solid #000000  !important;
}
.roundup_tile_errortile{
  width: 100%;
  border-radius: 4px ;
  background-color: #FFECE0;
  display: flex;
  align-items: center;
  gap: 10px;
}
.roundup_tile_error_icon{
width: 15px !important;
height: 15px;
}

.roundup_tile_image{
  width: auto !important;
}
.roundup_edit_btn_div{
  display: flex;
  gap: 10px;
}
.roundup_modal_title{
  font-size: 32px;
  font-weight: 300;
  line-height: 36px;
  letter-spacing: 0.32px;
}
.roundup_modal_btn_div{
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap:10px;
}
.roundup_modal_btn_div > *{
  width: 100%;
  border-radius: 24px;
  text-align: center !important;
  padding: .725rem 1.2rem .725rem 1.2rem !important;
  background-color: #ffffff;
  cursor: pointer;
}
.roundup_modal_btn_div > :first-child{
  background-color: #000000;
  color: #FFFFFF;
}
#roundup_modal > div:first-child{
  width: 40%;
  border-radius: 8px ;
  padding: 2rem;
}
#roundup_modal .modal_close_button{
  margin-top: -5% !important;
}

@media (max-width: 767px) {
  #roundup_modal{
    padding: 0px;
  }
  #roundup_modal > div:first-child{
    width: 100%;
    border-radius: 0px ;
    padding: 15px;
    min-height: 60rem;
  }
  .roundup_wrap{
    border-bottom: 0px !important;
  }
  .roundup_charity_wrap{
    background-color: #ffffff;
  }
  .roundup_switch_div{
    display: grid;
  }
  .roundup_switch_content_div{
    width: 90%;
  }
  .roundup_switch{
    position: relative;
  }
  .roundup_modal_btn_div{
    flex-direction: column !important;
    justify-content: center;
    gap: 10px;
  }
  #roundup_modal .modal_close_button{
    margin-left: 95% !important;
    margin-top: -4% !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 1030px) {
  #roundup_modal > div:first-child{
    width: 55%;
  }
  #roundup_modal .modal_close_button{
    margin-left: 96% !important;
    margin-top: -3% !important;
  }
}