/* test */
/* next button designs */
input.button {
    /* background-image: url(../../images/button-arrow.png); */
    background-position: 85% center;
    background-size: 13px 18px;
    background-color: #333333;
    color: #fff;
    background-repeat: no-repeat;
  }
  
  @media only screen and (max-width: 320px){
  
  .checkout-btn.button {
      background-position: 88% center;
      background-repeat: no-repeat;
  }
  
  }
  @media only screen and (min-width: 568px){
  
  .checkout-btn.button {
      background-position: 94% center;
      background-repeat: no-repeat;
  }
  
  }
  @media only screen and (max-width: 600px) {
    input.button {
      background-position: 88% center;
      background-repeat: no-repeat;    
    }
  }
  @media only screen and (min-width: 601px) and (max-width: 637px) {
    input.button {
      background-position: 93% center;
      background-repeat: no-repeat;  
    }
  }
  input.button:focus:not([readonly]) {
    background-color: #111010;
  }
  input.button.secondary,  input.button.secondary:focus:not([readonly ]){
    background-color: #333333;
  }
  /* next button designs ends */
  /*fix for next carousel*/
  /*.move_next1 {
      -webkit-transform: translate3d(-206px, 0, 0);
      -ms-transform: translate3d(-206px, 0, 0);
      transform: translate3d(0, 0, 0);
  }*/
  
  .view-cart_quantity {
      width: 50%;
  }
  
  .checkout-step_price .cart-box_product .cart-box_product-name-desktop {
    float: left;margin-left:10px;
  }
  
  
  .product-details_limited {
    padding-bottom: 1.6875rem;
    clear: both;
  }
  
  .product-details_limited {
    padding-bottom: 1.6875rem;
    clear: both;
  }	
      
  .w_filter-mobile {
    padding: 1.2rem 0.9375rem 1.0rem 0.9375rem;
    position: relative;
    height: 3.125rem;
    color: #000;
  } 
  
  .page-overlay.active{z-index:6000;}
  .w_modal.active{z-index:7000 !important;}
  
  /* IE footer issue */
  /* .w_footer_desktop { clear: both;} 
  
  } */
  /* guest checkout background color */
  .start-checkout_guest {
    background: #f2f2f2;
  }
  /* signin checkout background color */
  .start-checkout_signin > li {
      background-color: #FFFFFF; 
  }
  
  /* Print Header and other anchor link url's */
  
  @media print {	
      a:link:after, a:visited:after {
      content: "";
  }
  }
  
  @media screen and (max-width: 638px) {
    .toolbar_items > li.active {
      border-bottom: none;
  }
  } 
  
  body {
      position: static !important;
  }
  
  #scbresnavmenucont p {
      line-height: 18px !important;
  }
  
  #scbsearchsupportinput{
  margin-top: 0px !important;
  margin-left: 0px !important;
  height: auto;
  }
  
  /* line 546, ../../scss/app/widgets/_toolbar.scss */
    .newheader_devices {
      max-height: 6.5rem !important;
      height: 3.5rem !important;
      padding: 0 !important;
    }
    /* line 551, ../../scss/app/widgets/_toolbar.scss */
    .newheader_devices .w_toolbar {
      /*padding:0 !important;*/
      margin-top: 0 !important;
    }
  
   .mediumcpicker {
      border: 1px solid #959595 !important;
      display: block;
      float: left;
      height: 3em;
      margin: -.1em 0 0 -.1em;
      width: 3em;
      cursor: pointer;
  }
  
  li.active-check {
       display: block;
       background-position: center;
      
  }
  
  .bg-mint {
      background-color: #98FF98 !important; 
  }
  .bg-nickel {
      background-color:  #d4cbbc !important; 
  }
  .bg-bronze {
      background-color: #4c4b49 !important; 
  }
  .bg-brass {
      background-color: #dec891 !important; 
  }
  .bg-chalk {
      background-color: #FFFFFF !important; 
  }
  .bg-charcoal  {
      background-color: #666666 !important; 
  }
  .bg-slate  {
      background-color: #5e8292 !important; 
  } 
  .bg-lilac {
      background-color: #7F7EAD !important;
  }
  
  .products-details_color ul li.bg-chalk:after {
    color: #5e8292;
  }
  .tpgrid_product-colors .product-color.active.bg-chalk::after {
      color: #5e8292;
  }
  
  @media screen and (min-width: 638px) {
    .products-list_carousel .carousel_pager-item {
    height: 2.8125rem;
     width: 2.5rem !important;
    }
  }
  /* carosuel issues fix end */
  
  .button:focus:not(.no-focus-effect), a.button:focus:not(.no-focus-effect) {
     box-shadow: none !important;
  }
  .checkout-step_form .POMsg {
      margin-left: 10px;
      position: relative;
      top: 0px;
      left: -10px;
      font-size: 0.8125rem;
      color: #a80309;
      white-space: nowrap;
      top: -1.0rem;
      font-weight:normal;
      }
      
  .checkout-step_cc-info tr:nth-child(3n) td:nth-child(4n) table td:nth-child(2) {
      padding-left: 1.25rem;
      width: 35% !important;
      float: left;
  }
  
  .checkout-step_cc-info tr:nth-child(3n) td:nth-child(4n) table td:nth-child(2) select {
      width: 100%;
  }
  
  .checkout-step_cc-info tr:nth-child(3n) td:nth-child(4n) table td:nth-child(1) {
      float: left;
      overflow: hidden;
  }
  
  #dvPOError_ExpDt {
      white-space: normal;
  }
  
  .products-details_color ul li{
      margin-bottom:0.3125rem;
  }
  
  @media only screen and (min-width: 769px) and (orientation: landscape) {
    /* line 14, ../../scss/app/widgets/_tpgrid.scss */
    .accessories-result .tpgrid_item {
      height: 33.75rem !important;
    }
  }
  
  /* @media only screen and (min-width: 769px) and (orientation: landscape) {
  .tpgrid_item{
      height: 29.125rem !important;
  }
  } */
  
  .checkout-step_form .checkout-step_shipping-day > p:first-child {
      margin-bottom: 0.0rem;
  }
  
  @media screen and (max-width: 638px) {
    .main {
          padding-top: 0;
          border-left:0 !important;
  }
  } 
  
  .promo-section_image {
    width: 8.125rem;
    height: 8.125rem;
    /* border-radius: ;
    background: ;  */
  }
 
  
  .move_prev2 {
    -webkit-transform: translate3d(-210px, 0, 0);
    -ms-transform: translate3d(-210px, 0, 0);
    transform: translate3d(-210px, 0, 0);
      /*  @media only screen and (max-width:639px){
  -webkit-transform: translate3d(-130px,0,0);
       -ms-transform: translate3d(-130px,0,0);
       transform: translate3d(-130px,0,0);
          }*/
  }
  
  .checkout-step_form #state {
      margin-bottom: 24px !important;
  }
  #state.state_error{
  border: 1px solid #cd040b;
  }
  
  #ddlPObpInfocopCardType.type_error{
  border: 1px solid #cd040b;
  }
   
   .mobile-menu {
      text-align: left !important;
  }
  
  .w_footer_desktop {
    position: relative;
    z-index:0 !important;
  }
  
  .w_pagination{
      margin:0rem !important;
      padding-top:0.8rem !important;
      padding-bottom:0.5rem !important
  }
  
  
  #navContainer {height:0;}
  @media only screen and (max-width: 638px) {
    .toolbar_items > li {
      height: 45px;
      line-height: 2rem;
    }
  }
  
  .view-cart_note {
    margin-top:0rem !important; 
    padding-bottom:6px !important; 
  }
  
  /*:focus { outline: none;}*/
  /* CSS to fix header issues */
  @media only screen and (max-width: 767px){
  .w_header_desktop {
  
  z-index: 900;
  background: #fff;
  }
  
  .w_toolbar.toolbar_fixed {
  display: none;
  }
  #scbresnavmenu li {
  background: #fff;
  }
  }
  @media only screen and (min-width:768px){
  .w_header_devices {
  display: none;
  }
  }
  @media only screen and (max-width:767px){
  #scbsmbnavmenu li {
  background: #fff;
  }
  }
  @media only screen and (max-width:767px){
  #scbsmbnavmenu  {
  background: #fff;
  }
  }
  
  @media only screen and (max-width:767px){
  #scbresnavmenu {
  background: #fff ;
  }
  }
  
  
  @media only screen and (max-width: 767px){
   .w_header_desktop {
  /* height: 61px !important;
  min-height: 61px;    responsive header fix for SMB*/    
  background: #fff;
  } 
  }
  
  .page.active { clear: both;}
  
  @media only screen and (max-width:767px){
      .page {
      margin-top: 0;
      }
      .w_top-bar{
      position: relative;
      top: 0;
      }
      }
  
  /* @media only screen and (max-width: 768px) {
  [data-toolbar] { display: none !important;}
  }
   */
   
  
  @media (min-width: 320px) and (max-width: 638px) {
    /* line 5, ../../scss/app/components/_footer.scss */
    .footer_desktop {
      display: block !important;
    }
  }
  
  @media screen and (max-width: 858px){
  .cart-box_tablet-place-order button {
      display: none !important;
  }
  }
  
  .device-ios.device-mobile select, .device-ios.device-tablet select { font-size: 16px;}
  
  .toolbar_fixed { z-index: -1;}
  
  .w_top-bar {     
      height: auto !important;
      min-height: initial;
      border-bottom: 0;
  }
  
  .hero-banner_sort .w_toolbar {
      background-color: inherit;
  }
  
  .padding-top-50 { padding-top : 50px !important;}
  
  .page { height: auto !important;}
  
  .begincheckout .w_toolbar { display: none;}
  @media screen and (max-width: 767px) {
  
  #ddlPObpInfocopCardType {
      width: 100% !important;
  }}
  
  .checkout-step_cc-info tr:nth-child(2n) td:nth-child(2) select {
    width: 70% !important;
  }
  
  .bg-gray-teal{
  background: -webkit-linear-gradient(46deg, gray 50%, teal 0%);
  background: -moz-linear-gradient(46deg, gray 50%, teal 0%);
  background: -ms-linear-gradient(46deg, gray 50%, teal 0%);
  background: linear-gradient(46deg, gray 50%, teal 0%);
  
  }
  
  .view-cart_subtotal {
    float: left;
    font-size: 0.875rem;
    font-weight: bold;
    width: 60%;
  }
  
  
  .start-checkout_signin.w_list-control .m_accordion-details.signin-frame{
  background-color: #fff !important;
  }
  
  /* //billpay Styles */
  .checkout-step_cc-info tr:nth-child(4n) td:nth-child(4n) table td:nth-child(1) ,.checkout-step_cc-info tr:nth-child(4n) td:nth-child(4n) table td:nth-child(2) {
    width: 40%;
    display: inline-block;
    padding-right: 10px;
  }
  .vzicon.gift_card_icon::before{
    font-size: 18px;
    line-height: 15px;
  }
  .tooltipGCCard{
  left:26px;
  top:0px;
  }
  
  
  /*Re-Skin CSS Start*/
  .toolbar_items > li.active {
      border-bottom: 0.25rem solid #000;
  }
  h1, .h1, h3, .h3, h4, .h4, .sale_text, .plus:after, .minus:after, .checkout-step_cc-info td span, .filter-category li a:hover, .checkout-step-showing-items.minus, .checkout-step_discount-code.minus, .select-box_options li:before, .products-details_heading, .hero-banner_title, .toolbar_support .toolbar_shipping-item, .pagination_item.m_first > a::before, .pagination_item.m_last > a::before, .pagination_item.m_prev > a::before, .pagination_item.m_next > a::before, .pagination_item[disabled].m_prev > a::before, .pagination_item[disabled].m_next > a::before, .w_list-control .m_accordion-summary::before, .w_list-control .m_accordion-summary:hover::before, .button.cta-text::before, .button.link-text::before, a.button.cta-text::before, a.button.link-text::before, .select-box_selected:after {
      color: #000;
  }
  .pagination_item.m_next:hover > a::before, .checkout-step_discount-code.plus:after, .checkout-step_discount-code.minus:after, .checkout-step_form .checkout-step_shipping-day .checkout-step_shipping-day-free, .checkout-step_form .checkout-step_shipping-address .checkout-step_shipping-day-free, .checkout-step_form .checkout-step_card-choose .checkout-step_shipping-day-free, .pagination_item.m_prev:hover > a::before, .pagination_item.m_first:hover > a::before, .pagination_item.m_last:hover > a::before, .button.cta-text:hover:not(.no-animate)::before, .button.link-text:hover:not(.no-animate)::before, a.button.cta-text:hover:not(.no-animate)::before, a.button.link-text:hover:not(.no-animate)::before, .fancy-options_item.selected::before, .fancy-options_item.active::before {
      color: #000;
  }
  input[type=checkbox]:checked + label::before, input[type=radio]:checked + label::before, input[type=checkbox]:checked:hover + label::before, input[type=radio]:checked:hover + label::before {
      color: #000;
  }
  .m_toolbar-chat, .m_toolbar-call, .tpgrid_item .tpgrid_product-name a, .page-cart-title, .view-cart_subtotal-value, .text-brand-1 {
      color: #000 !important;
  }
  .icon-price-tag {
      color: #fff !important;
  }
  .button, a.button, input.button, input.button:focus:not([readonly]) {
      background-color: #333;
      color: #fff;
  }
  .button:hover, a.button:hover, input.button:hover{
      background-color: #444444;
      color: #fff;
  }
  .button.secondary, a.button.secondary, input.button.secondary, input.button.secondary:focus:not([readonly ]) {
      background-color: #767676;
      color: #fff;
  }
  .button.secondary:hover, a.button.secondary:hover, input.button.secondary:hover {
      background-color: #9f9f9f;
      color: #fff;
  }
  .button.cta-text, .button.link-text, a.button.cta-text, a.button.link-text {
      color: #000;
      background-color: transparent;
  }
  .tabs_tab.active {
      border-bottom-color: #cd040b;
      border-top-width: 0;
      border-left-width: 0;
      border-right-width: 0;
  }
  
  
  
  .tpgrid_new-item .vzicon, .tpgrid_item .vzicon, .tpgrid_top-rated .vzicon, .product_promo-section .vzicon, .icon-price-tag {
      background: #000;
  }
  .view-cart_product-text a{ color: #000; }
  .store-icon-nav li:hover span { color: #000; }
  
  /*One moment loader*/
   @-webkit-keyframes spin {
        0% {
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
        }
        100% {
          -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
        }
      }
      @keyframes spin {
        0% {
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
        }
        100% {
          -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
        }
      }
      .spin-loader-icon{
          border-radius: 50%;
          width: 50px;
          height: 50px;
          border: 0.25rem solid black;
          border-top-color: transparent;
          -webkit-animation: spin 1s infinite linear;
          animation: spin 1s infinite linear;
          display: inline-block;
          vertical-align: middle;
      }
  .m_full-page-loader {
      background: #fff;
      max-width: 450px;
      padding: 106px 10px;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 9999;
      margin: auto;
      height: 260px;
      font-size: 20px;
      font-weight: 600;
      text-align: center;
      display: none;
  }
  .m_full-page-loader .loader-text{ 
      margin-left: 10px; 
      display: inline-block; 
      width: 200px;
      text-align: left;
      vertical-align: middle;
      line-height: 23px;
  }
  .value.faux-select, select.value {
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%);
  }
  @media(max-width: 460px){
      .m_full-page-loader{
          margin: auto 10px;
      }
  }
  
  input.button.secondary:hover {
      /* background-image: url(../../images/button-arrow.png); */
      background-position: 85% center;
      background-size: 13px 18px;
      background-color: #9f9f9f;
      color: #fff;
      background-repeat: no-repeat;
  }
  
  .store-icon-nav li .shelf-icon{ padding: 10px; }
  .checkout-btn.button { background-position: 86% center; }
  
  .value.faux-select, select.value {
      /* -webkit-filter: grayscale(100%); */
      filter: grayscale(100%);
  }
  
  .value.faux-select, select.value {
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%);
  }
  
  /*Re-Skin CSS End*/
  
  
  .w_cart-box {
      
  /* z-index: 1 !important; */
  
      }
  .hero-carousel .w_carousel {
    
    z-index: 0 !important;   /* new header issue */
  }
  .hero-carousel .m_carousel_pager-nav-icons {
   
    z-index: 0 !important;   /* new header issue */
  }
  .checkout-step_form select#expyear{ 
      bottom:26px;
       }