/* .App {
  text-align: center;
} */
/* test */
.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
/* .imgSize {
  width: 129.16px;
  height: 129.16px;
} */


.img-cont  {
  -webkit-transition: all 300ms ease-in 0s;
  -moz-transition: all 300ms ease-in 0s;
  -ms-transition:all 300ms ease-in 0s;
  transition: all 300ms ease-in 0s;
}
.img-cont:focus{
  outline: none !important;
  transform: scale(1.45);
  cursor: zoom-out;
  
}